import styled from 'styled-components';

const MyCoursesContainer = styled.div`
  width: 100vw;
`;

const MyCoursesTitle = styled.h2`
  font-size: var(--font-largest);
  margin-top: 16px;
`;

const Ongoing = styled.div``;

const MyCoursesSubTitle = styled.h3`
  font-size: var(--font-large);
  margin-top: 10px;
`;

const OngoingCardsContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  margin-top: 10px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 930px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 768px) {
    & > div:not(:last-child) {
      margin-right: 10px;
    }
  }

  & > div {
    margin: 6px 2px;
  }
`;

const Favorites = styled.div`
  margin-top: 30px;
`;

const FavoritesCardsContainer = styled.div`
  margin-top: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 930px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & > div {
    margin: 1px;
  }
`;

export {
  MyCoursesContainer,
  MyCoursesTitle,
  Ongoing,
  MyCoursesSubTitle,
  OngoingCardsContainer,
  Favorites,
  FavoritesCardsContainer,
};

import styled from 'styled-components';

const CertificatesContainer = styled.div`
  width: 100vw;
`;

const CertificatesTableContainer = styled.div`
  overflow-x: auto;
`;

const CertificatesTable = styled.table`
  width: 100%;
  margin-top: 15px;

  border: solid 2px #e9ebee;
  border-radius: 3px;

  thead {
    th {
      font-size: var(--font-medium);
      text-align: left;
      padding: 10px 15px;
      font-weight: bold;
      border-bottom: solid 1px #e9ebee;
    }
  }

  tbody {
    tr {
      &:not(:last-child) {
        td {
          border-bottom: solid 1px #e9ebee;
        }
      }

      td {
        font-size: var(--font-large);
        text-align: left;
        padding: 10px 15px;
        color: var(--default-dark-gray);
        font-size: var(--font-medium);

        &.course-name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: var(--font-large);

          .course-image-container {
            margin-right: 15px;
            height: 50px;
            width: 80px;
            background-color: var(--medium-gray);

            display: flex;
            justify-content: center;

            img {
              width: auto;
              height: 50px;
            }
          }

          .course-info {
            .course-category {
              font-size: var(--font-small);
              color: var(--default-purple);
              font-weight: bold;
            }

            .course-title {
              font-weight: bold;
              color: var(--default-font-color);
            }
          }
        }

        &.download-certificate {
          button {
            border: none;
            outline: none;
            background-color: var(--default-purple);
            color: white;
            font-weight: bold;
            padding: 10px 15px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            & > span:first-child {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
`;

const CertificatesTableRow = styled.tr``;

const CertificatesTitle = styled.h2`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  font-size: var(--font-largest);
`;

export {
  CertificatesContainer,
  CertificatesTable,
  CertificatesTableRow,
  CertificatesTitle,
  CertificatesTableContainer
};

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { decodeToken } from 'react-jwt'

import { useUsersnapApi } from '../../context/UsersnapContext'
import Routes from '../routes'
import { ContentContainer } from './style'

const Content: React.FC = () => {
  const usersnap = useUsersnapApi()
  const history = useHistory()
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized && !!usersnap) {
      const userFarmarcasId = localStorage.getItem('ead/user/id')
      const userToken = localStorage.getItem('core/auth/token')
      const userInfo = { userId: userFarmarcasId, email: '' }

      if (!!userToken) {
        const decodedToken: { email: string } | null = decodeToken(userToken)

        userInfo['email'] = decodedToken?.email ?? '';
      }

      usersnap?.init({ user: userInfo, locale: 'pt' });

      setInitialized(true);
    }
  }, [usersnap, history, isInitialized])

  return (
    <ContentContainer>
      <Routes />
    </ContentContainer>
  )
}

export default Content

import styled from 'styled-components';


const BadgeTargetGroup = styled.span`
    margin-left: 5px;
    background-color: #E5E8EB;
    color: white;
    padding: 2px 4px 2px 4px;
    text-align: center;
    border-radius: 8px;
`;

const CourseCardContainer = styled.div`
  a {
    text-decoration: none;
    .course-card {
      width: 300px;
      height: 370px;
      border-radius: 5px;
      border: solid 1px #d3d8dd;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      .course-img-container {
        background-color: #061938;
        height: 175px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden;

        display: flex;
        justify-content: center;

        img {
          width: auto;
          height: 175px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      .course-overview {
        flex-grow: 1;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .top-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .category {
            font-size: 14px;
            font-weight: bold;
            color: var(--default-purple);
          }

          .icon-add-to-list {
            border: none;
            outline: none;
            width: 24px;
            height: 24px;
            background-color: var(--medium-gray);
            border-radius: 5px;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;
          }

          .icon-add-to-list.added-to-list {
            background-color: var(--default-purple);

            svg {
              color: #fff !important;
            }
          }
        }

        .title {
          width: 100%;
          color: var(--default-font-color);
          font-size: 16px;
          font-weight: bold;
        }

        .category,
        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .target_group,
        .author,
        .duration {
          color: #5b6981;
          font-size: 14px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          span {
            color: #5b6981;
            font-size: var(--font-small);
          }
        }

        .deadline {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          span {
            color: #5b6981;
            font-size: var(--font-small);
          }
        }
      }

      .course-finished {
        display: flex;
        align-items: center;

        .course-finished-text {
          font-size: var(--font-medium);
          font-weight: bold;
          color: var(--default-purple);
          margin-left: 5px;
        }
      }

      .is-new-badge {
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 12px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #00b8d9;
        border-radius: 15px;
        width: 100px;
        height: 30px;
        color: white;
      }

      .soon-tag {
        height: 150px;
        width: 150px;
        overflow: hidden;
        position: absolute;
        left: 148px;
        user-select: none;

        span {
          transform: rotate(45deg);
          position: absolute;
          display: block;
          width: 150px;
          top: 28px;
          text-align: center;
          left: 30px;
          background-color: var(--default-tag-color);
          color: white;
          text-transform: uppercase;
          font-size: 12px;
          padding: 1px 3px;
        }

        &::after {
          position: absolute;
          z-index: -1;
          content: '';
          display: block;
        }

        &::before {
          position: absolute;
          z-index: -1;
          content: '';
          display: block;
        }
      }

      .ribbon-top-right {
        top: 0px;
        right: -22px;

        &::before {
          top: 0;
          left: 0;
        }

        &::after {
          bottom: 0;
          right: 0;
        }
      }
    }

    .course-card.added-to-list {
      border: solid 1px var(--default-purple);
    }
  }
`;

export {
  CourseCardContainer,
  BadgeTargetGroup,
};

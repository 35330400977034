import React, { useState, useCallback, useEffect } from 'react';

import { BsSearch } from 'react-icons/bs';
import { VscChromeClose } from 'react-icons/vsc';
import useDebounce from '../../../../hooks/useDebounce';
import { Container, SearchResults, SearchResultItemWrapper } from './styles';

import { searchVideoLibrary } from '../../../../services/content';
import { useHistory } from 'react-router-dom';
import Lesson from '../../../../models/lesson';
import  HTMLReactParser  from 'html-react-parser';

const SearchResultItem = ({ itemData }: any) => {
  const history = useHistory();

  const { thumbnail, title, description, content_id } = itemData || {};

  const goToCourse = (id: string) => {
    history.push(`/video-library/${id}`);
  };

  return (
    <SearchResultItemWrapper
      hasThumb={!!thumbnail}
      onClick={() => goToCourse(content_id)}
    >
      {!!thumbnail && (
        <div className="resultThumb">
          <img src={thumbnail} alt={title} />
        </div>
      )}
      <div className="data">
        <p className="name">{title}</p>
        <p className="description">{HTMLReactParser(description)}</p>
      </div>
    </SearchResultItemWrapper>
  );
};

const SearchInputLibrary = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Lesson[]>([]);
  const debouncedSearchParam = useDebounce(searchParam);
  const [isSearching, setSearching] = useState<boolean>(false);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value);
  };

  const searchRequest = useCallback(async () => {
    if (debouncedSearchParam !== '') {
      setSearching(true);
      await searchVideoLibrary(debouncedSearchParam).then(response =>
        setSearchResults(response),
      );
    }

    setSearching(false);
  }, [debouncedSearchParam]);

  useEffect(() => {
    if (debouncedSearchParam !== '') {
      searchRequest();
    }
  }, [debouncedSearchParam]);

  const toggleInput = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (!expanded) {
      setSearchParam('');
      setSearchResults([]);
      setSearching(false);
    }
  }, [expanded]);

  return (
    <Container onClick={toggleInput} expanded={expanded}>
      <input
        type="text"
        value={searchParam}
        onChange={handleChangeSearch}
        placeholder="Buscar"
        required={true}
        style={{ width: '100%' }}
      />
      <button onClick={toggleInput}>
        {!expanded ? <BsSearch /> : <VscChromeClose />}
      </button>
      {expanded && debouncedSearchParam !== '' && (
        <SearchResults>
          <>
            {searchResults.length >= 1 ? (
              <>
                {searchResults.map((result: any) => (
                  <SearchResultItem itemData={result} />
                ))}
              </>
            ) : !isSearching && searchResults.length === 0 ? (
              <li className="noResults">
                Nenhum resultado para a busca "{searchParam}"
              </li>
            ) : (
              <li className="noResults">Buscando por "{searchParam}"</li>
            )}
          </>
        </SearchResults>
      )}
    </Container>
  );
};

export default SearchInputLibrary;

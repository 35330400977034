import CourseFromApi from '../../models/from-api/course';
import Course from '../../models/course';
import CourseUserFromApi from '../../models/from-api/course-user';
import convertToModule from './convert-module';
import ModuleUserFromApi from '../../models/from-api/module-user';
import Module from '../../models/module';
import ModuleFromApi from '../../models/from-api/module';

const getModuleUser = (
  module: ModuleFromApi,
  courseUser?: CourseUserFromApi,
): ModuleUserFromApi => {
  if (courseUser && courseUser.courses && courseUser.courses.length) {
    const moduleUser = courseUser.courses.find(
      modUser => modUser.course_id === module.course_id,
    );
    if (moduleUser) {
      return moduleUser;
    }
  }

  return {} as ModuleUserFromApi;
};

const convertToCourse = async (
  course: CourseFromApi,
  courseUser?: CourseUserFromApi,
): Promise<Course> => {
  return {
    id: course.trail_id,
    starsQuantity: course.average,
    thumbnailUrl: course.thumbnail,
    title: course.name,
    description: course.description,
    addedToList: course.book_mark,
    alreadyStarted: !!courseUser && !!courseUser.start_at,
    alreadyFinished:
      (!!courseUser && !!courseUser.finish_at) || !!course.finish_at,
    userRate: course.user_rating,
    isActive: course.is_active,
    duration: +course.duration,
    authorName: course.author ? course.author.name : '',
    categoryId: course.category ? course.category.id : '',
    categoryName: course.category
      ? course.category.title
      : course.category_name
      ? course.category_name
      : '',
    modules:
      course.courses && course.courses.length
        ? await Promise.all(
            course.courses
              .sort((courseA, courseB) =>
                courseA.position > courseB.position ? 1 : -1,
              )
              .map((module, index) =>
                convertToModule(
                  module,
                  course.trail_id,
                  index === 0,
                  index === course.courses.length - 1,
                  getModuleUser(module, courseUser),
                ),
              ),
          )
        : ([] as Module[]),
    referenceUrl: course.video_reference,
    finishDate:
      courseUser && courseUser.finish_at
        ? new Date(courseUser.finish_at)
        : new Date(),
    info: course.info,
    isNew:
      course.created_at &&
      (new Date().getTime() - new Date(course.created_at).getTime()) /
        (1000 * 3600 * 24) <
        30,
    timeRemaining: course.time_remaining ? +course.time_remaining : undefined,
    progress: course.progress
      ? +course.progress > 1
        ? +course.progress / 100
        : +course.progress
      : undefined,
    previewData: course.video_preview
      ? {
          contentView: course.video_preview.content_view,
          started: !!course.video_preview.start_at,
          finished: !!course.video_preview.finish_at,
        }
      : { contentView: 0, started: false, finished: false },
    banner: course && course.info && course.info.banner,
    deadline: course.deadline,
    target_group: course.trail_target_group,
    created_at: course.created_at,
  };
};

export default convertToCourse;

import React from 'react';
import ContentLoader from 'react-content-loader';
import { CourseSkeletonContainer } from './styles';

const CourseSkeleton: React.FC = () => {
  return (
    <CourseSkeletonContainer>
      <div className="hd-skeleton">
        <ContentLoader width={1300} height={865} backgroundColor="#e3e3e3">
          <rect width="800" height="25" x="15" y="10" />
          <rect width="800" height="50" x="15" y="42" />
          <rect width="800" height="30" x="15" y="100" />
          <rect width="800" height="450" x="15" y="135" />
          <rect width="450" height="120" x="825" y="10" />
          <rect width="450" height="705" x="825" y="135" />
          <rect width="800" height="30" x="15" y="600" />
          <rect width="800" height="200" x="15" y="640" />
        </ContentLoader>
      </div>
      <div className="semi-hd-skeleton">
        <ContentLoader width={1200} height={865} backgroundColor="#e3e3e3">
          <rect width="700" height="25" x="5" y="10" />
          <rect width="700" height="50" x="5" y="42" />
          <rect width="700" height="30" x="5" y="100" />
          <rect width="700" height="394" x="5" y="135" />
          <rect width="450" height="120" x="715" y="10" />
          <rect width="450" height="705" x="715" y="135" />
          <rect width="700" height="30" x="5" y="540" />
          <rect width="700" height="260" x="5" y="580" />
        </ContentLoader>
      </div>
      <div className="medium-skeleton">
        <ContentLoader width={700} height={1050} backgroundColor="#e3e3e3">
          <rect width="700" height="25" x="5" y="10" />
          <rect width="700" height="50" x="5" y="42" />
          <rect width="700" height="30" x="5" y="100" />
          <rect width="700" height="394" x="5" y="135" />
          <rect width="450" height="250" x="130" y="790" />
          <rect width="700" height="30" x="5" y="540" />
          <rect width="700" height="200" x="5" y="580" />
        </ContentLoader>
      </div>
      <div className="tablet-skeleton">
        <ContentLoader width={550} height={945} backgroundColor="#e3e3e3">
          <rect width="550" height="25" x="5" y="10" />
          <rect width="550" height="50" x="5" y="42" />
          <rect width="550" height="30" x="5" y="100" />
          <rect width="550" height="300" x="5" y="135" />
          <rect width="450" height="250" x="50" y="680" />
          <rect width="550" height="30" x="5" y="440" />
          <rect width="550" height="200" x="5" y="475" />
        </ContentLoader>
      </div>
      <div className="mobile-skeleton">
        <ContentLoader width={350} height={845} backgroundColor="#e3e3e3">
          <rect width="550" height="25" x="0" y="10" />
          <rect width="550" height="50" x="0" y="42" />
          <rect width="550" height="30" x="0" y="100" />
          <rect width="350" height="200" x="0" y="135" />
          <rect width="450" height="250" x="0" y="580" />
          <rect width="550" height="30" x="0" y="340" />
          <rect width="550" height="200" x="0" y="375" />
        </ContentLoader>
      </div>
    </CourseSkeletonContainer>
  );
};

export default CourseSkeleton;
import styled from 'styled-components';

const PlayerSambaVideosContainer = styled.div`
  width: 100%;

  & iframe {
    width: 100%;
    height: 495px;

    @media (max-width: 1200px) {
      height: 380px;
    }

    @media (max-width: 960px) {
      height: 260px;
    }

    @media (max-width: 768px) {
      height: 260px;
    }

    @media (max-width: 550px) {
      height: 165px;
    }
  }
`;

export { PlayerSambaVideosContainer };

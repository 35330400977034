import React from 'react';
import * as Style from './styled';

interface DraggableTextProps {
  textObject: any;
}

const DraggableText = ({ textObject }: DraggableTextProps) => {
  return (
    <Style.DraggableTextWrapper
      dangerouslySetInnerHTML={{ __html: textObject?.content }}
      className="ql-editor"
      textObject={textObject}
    />
  );
};

export default DraggableText;

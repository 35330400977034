import styled from 'styled-components';

export const DraggableTextWrapper = styled.div<{
  textObject: {
    color: string;
    top: string;
    left: string;
    isFullWidth: boolean;
  };
}>`
  max-width: 100%;
  max-height: 100%;
  height: fit-content;

  overflow-wrap: break-word;

  position: absolute;

  top: ${({ textObject }) => (textObject.top ? textObject.top : 'unset')};
  left: ${({ textObject }) => (textObject.left ? textObject.left : 'unset')};
  width: ${({ textObject }) =>
    textObject.isFullWidth ? '100% !important' : 'unset'};
  text-align: ${({ textObject }) =>
    textObject.isFullWidth ? 'center !important' : 'initial !important'};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  ul,
  li {
    color: ${({ textObject }) =>
      textObject.color ? textObject.color : 'unset'};
  }
`;

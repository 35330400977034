import styled from 'styled-components';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const ModalBackDrop = styled.div`
  @keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0.5);
    }
    100% {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  position: fixed;
  width: 100%;
  height: 100%;
  animation: 0.3s forwards fadeIn;

  z-index: -1;
`;

export { ModalContainer, ModalBackDrop };

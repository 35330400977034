import convertToAnnotation from '../../helpers/converters/convert-annotation';
import httpClient from '../../http-client';
import Annotation from '../../models/annotation';
import AnnotationFromApi from '../../models/from-api/annotation';

const getAnnotations = async (courseId: string, lessonId: string) => {
  const annotations = (
    await httpClient.get<AnnotationFromApi[]>(
      `/annotations/${courseId}/${lessonId}`,
    )
  ).data;

  let convertedAnnotations = [] as Annotation[];
  if (annotations && annotations.length) {
    convertedAnnotations = annotations.map(convertToAnnotation);
  }

  return convertedAnnotations;
};

const createAnnotation = async (
  courseId: string,
  lessonId: string,
  title: string,
  description: string,
) => {
  await httpClient.post('/annotations', {
    trail_id: courseId,
    content_id: lessonId,
    title: title,
    description: description,
  });
};

const updateAnnotation = async (
  annotationId: string,
  title: string,
  description: string,
) => {
  await httpClient.put(`/annotations/${annotationId}`, {
    title: title,
    description: description,
  });
};

const deleteAnnotation = async (annotationId: string) => {
  await httpClient.delete(`/annotations/${annotationId}`);
};

export { getAnnotations, createAnnotation, updateAnnotation, deleteAnnotation };

import React from 'react';
import DraggableImage from '../DraggableImage';
import DraggableText from '../DraggableText';
import * as Style from './styled';
import 'react-quill/dist/quill.snow.css';

const Container = ({ certificateObject }: any) => {
  return (
    <Style.ContainerWrapper
      id={`pdf-container-${certificateObject?.certificate_template_id}`}
      style={{
        width: `${certificateObject?.width + 'px'}`,
        height: `${certificateObject?.height + 'px'}`,
      }}
    >
      <Style.ContainerBox
        style={{
          width: `${certificateObject?.width + 'px'}`,
          height: `${certificateObject?.height + 'px'}`,
          backgroundImage: `url(${certificateObject?.preview?.src})`,
        }}
      >
        {certificateObject?.imagesArray?.map((i: any, index: number) => (
          <DraggableImage key={index} imageObject={i} />
        ))}

        {certificateObject?.text_object_props_array?.map(
          (i: any, index: number) => (
            <DraggableText key={index} textObject={i} />
          ),
        )}
      </Style.ContainerBox>
    </Style.ContainerWrapper>
  );
};

export default Container;

import React, { useEffect } from 'react';
import usePostHog from '../../hooks/usePosthog';
import AvailableCourses from './components/AvailableCourses';
import { CoursesContainer } from './style';

const Courses: React.FC = () => {
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('Capacitação - Cursos');
  }, [posthog]);

  return (
    <CoursesContainer>
      <div className="content">
        <AvailableCourses />
      </div>
    </CoursesContainer>
  );
};

export default Courses;

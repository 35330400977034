import React, { useCallback, useEffect, useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CourseCardWithoutImage from '../../../../components/CourseCardWithoutImage';
import CourseCardWithoutImageSkeleton from '../../../../components/CourseCardWithoutImageSkeleton';
import GlobalContext from '../../../../GlobalContext';
import Course from '../../../../models/course';
import { getOngoingCourses } from '../../../../services/course';
import {
  OngoingCardsContainer,
  OngoingCoursesContainer,
  OngoingCoursesSubTitle,
  ViewMoreButton,
  ViewMoreButtonContainer,
} from './style';
import Slider from 'react-slick';
import { OngoingCoursesSliderSettings } from '../../../../config/slider';

const OngoingCourses: React.FC = () => {
  const history = useHistory();
  const { isMobile } = useContext(GlobalContext);

  const [ongoingCourses, setOngoingCourses] = useState([] as Course[]);
  const [shouldShowOngoingSkeleton, setShouldShowOngoingSkeleton] =
    useState(true);
  const [shouldShowViewMoreButton, setShouldShowViewMoreButton] =
    useState(false);

  const refreshOngoingCourses = useCallback(async () => {
    const ongoingFilters = {
      limit: 4,
    };

    const localOngoingCourses = await getOngoingCourses(ongoingFilters);

    if (localOngoingCourses && localOngoingCourses.length) {
      setOngoingCourses(localOngoingCourses);
    } else {
      setShouldShowOngoingSkeleton(false);
    }
  }, []);

  const handleCoursesRender = useCallback(() => {
    const insideRender =
      ongoingCourses && ongoingCourses.length ? (
        ongoingCourses
          .slice(0, 4)
          .map((course, index) => (
            <CourseCardWithoutImage key={index} course={course} />
          ))
      ) : shouldShowOngoingSkeleton ? (
        Array(4)
          .fill('')
          .map((item, index) => <CourseCardWithoutImageSkeleton key={index} />)
      ) : (
        <></>
      );

    if (isMobile && ongoingCourses.length > 1) {
      return <Slider {...OngoingCoursesSliderSettings}>{insideRender}</Slider>;
    }

    return <OngoingCardsContainer>{insideRender}</OngoingCardsContainer>;
  }, [isMobile, ongoingCourses, shouldShowOngoingSkeleton]);

  useEffect(() => {
    (async () => {
      await refreshOngoingCourses();
    })();
  }, [refreshOngoingCourses]);

  useEffect(() => {
    (async () => {
      setShouldShowViewMoreButton(
        !!(
          await getOngoingCourses({
            limit: 1,
            offset: 4,
          })
        ).length,
      );
    })();
  }, []);

  return shouldShowOngoingSkeleton || ongoingCourses.length ? (
    <OngoingCoursesContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <OngoingCoursesSubTitle>Em andamento...</OngoingCoursesSubTitle>

        {isMobile && shouldShowViewMoreButton && (
          <ViewMoreButton
            className="mobile"
            onClick={e => history.push(`/my-courses`)}
          >
            Ver todos
          </ViewMoreButton>
        )}
      </div>

      {handleCoursesRender()}

      {!isMobile && shouldShowViewMoreButton && (
        <ViewMoreButtonContainer>
          <ViewMoreButton onClick={e => history.push(`/my-courses`)}>
            Ver todos
          </ViewMoreButton>
        </ViewMoreButtonContainer>
      )}
    </OngoingCoursesContainer>
  ) : (
    <></>
  );
};

export default OngoingCourses;

import React from 'react';

interface IGlobalContext {
  isMobile: boolean;
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
}

export default React.createContext<IGlobalContext>({
  isMobile: false,
  showSidebar: false,
  setShowSidebar: () => {},
});

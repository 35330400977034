import styled from 'styled-components';

const CourseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & .banner-button {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
  }
`;

const PlayerContainer = styled.div`
  width: 100%;
  height: 495px;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    height: 380px;
  }

  @media (max-width: 960px) {
    height: 260px;
  }

  @media (max-width: 768px) {
    height: 260px;
  }

  @media (max-width: 550px) {
    height: 165px;
  }
`;

const CourseTabsGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px var(--border-gray);
`;

const CourseTabsGroupHeaders = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px var(--border-gray);
`;

const CourseTabHeaderGroupContainer = styled.button`
  font-weight: 600;
  font-size: 14px;
  width: auto;
  min-width: 80px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #344563;
  background: #F0F3F6;
  padding: 5px;
  border-top-left-radius: 8px 8px;
  border-top-right-radius: 8px 8px;
  border-bottom: solid 3px transparent;

  &.selected,
  &:hover {
    background-color: #7324F2;
    color: #FFFFFF;
    border-bottom: solid 3px #7324F2;
    
  }

  @media (max-width: 640px) {
    font-size: 12px;
    max-width: 125px;
  }

  @media (max-width: 420px) {
    min-width: 50px;
    max-width: auto;
    font-size: 10px;
  }
`;


const CourseTabsGroupContent = styled.div`
  min-height: 80px;
`;

const CourseTabsAndRating = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CourseTabsHeaders = styled.div`
  flex-grow: 1;
  
  display: flex;
  gap: 4px;
  border-bottom: solid 3px #7324F2;
`;

const CourseTabsContent = styled.div`
  min-height: 80px;
  margin-top: 20px;
`;

const CourseTabHeaderContainer = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  cursor: pointer;
  padding: 5px;

  &.selected,
  &:hover {
    border-bottom: solid 3px var(--default-purple);
  }
`;

const PlayerIconContainer = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayerIcon = styled.span`
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    border-top: 15px solid #fff;
    width: 100%;
    transform: rotate(45deg);
  }
`;

const CourseOrLessonTitle = styled.h4`
  font-size: 1.2em;
`;

const CourseBanner = styled.img`
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
`;

const StartCourseContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background: #fff;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
`;

const StartCourseButton = styled.button`
  border: none;
  background: var(--default-purple);
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  outline: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 5px;
  opacity: 0.9;
  transition: all 0.3s;
  width: 100%;

  &:hover {
    opacity: 1;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export {
  CourseContentContainer,
  PlayerContainer,
  CourseTabsAndRating,
  CourseTabsHeaders,
  CourseTabsContent,
  CourseTabHeaderContainer,
  PlayerIconContainer,
  PlayerIcon,
  CourseOrLessonTitle,
  CourseBanner,
  StartCourseContainer,
  StartCourseButton,
  CourseTabsGroup,
  CourseTabsGroupHeaders,
  CourseTabsGroupContent,
  CourseTabHeaderGroupContainer
};

import React, { useState, useEffect, useRef } from 'react';
import { useContext } from 'react';
import CourseContext from '../../Context';
import { BlockedCertContainer, CourseCertificateContainer } from './styles';
import { RiErrorWarningLine } from 'react-icons/ri';
import { ReactComponent as CertLinedIcon } from '../../../../assets/icons/certificate-lined.svg';
import CertColoredIcon from '../../../../assets/icons/certificate-color.png';
import { AiOutlineDownload } from 'react-icons/ai';
import {
  createCertificate,
  getCertificateById,
} from '../../../../services/certificate';
import Container from '../../../../pdf/templates/Container';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { finishCourse, sendCourseCertificateMail, sendCourseFinishMail } from '../../../../services/course';
import imgUrlToDataURL from '../../../../helpers/converters/imgUrlToDataURL';
import { ICertificateTemplateParsed } from '../../../../models/from-api/certificates';
import { toast } from 'react-toastify';
import { useUsersnapApi } from '../../../../context/UsersnapContext';

const USERSNAP_PROJECT_API_KEY = process.env.REACT_APP_USERSNAP_COURSE_PROJECT_KEY;

const CourseCertificate: React.FC = () => {
  const printRef = useRef(null);
  const usersnap = useUsersnapApi();

  const { course, userIsApproved } =  useContext(CourseContext);

  const [certificateObject, setCertificateObject] = useState<any>({});

  const handleSendFinishMail = async () => {
    if (course && course.id) {
      const typeOfMailSent = localStorage.getItem(
        `ead/sent_mail/${course.id}`,
      );

      try {
        if (!typeOfMailSent || typeOfMailSent === 'FINISH') {
          if(!!userIsApproved) {
            await sendCourseCertificateMail(course.id, certificateObject)
          } else {
            await sendCourseFinishMail(course.id);
          }
        }

        localStorage.setItem(
          `ead/sent_mail/${course.id}`,
          userIsApproved ? 'CERT' : 'FINISH',
        );
      } catch (error) {}
    }
  };

  useEffect(() => {
    if(!!certificateObject) {
      const allLessons = (course.modules || [])
        .map(mod => mod.lessons || [])
        .flat()
        .filter(les => les.type !== 'CERTIFICATE');

      if (!allLessons.length) {
        return undefined;
      }

      const finishedAllLessons = allLessons.every(les => les.alreadyFinished);

      if (finishedAllLessons) {
        handleSendFinishMail();
      }
    }
  }, [userIsApproved, course, certificateObject]);


  useEffect(() => {
    usersnap.on("beforeSubmit", (event: any) => {
      event.api.setValue('custom', {
        "course": course.title,
      });
    });
    
    usersnap.logEvent("us_call_course_end");
  }, [usersnap, course]);

  const getCertificateByIdFunction = async () => {
    if(userIsApproved && !course.alreadyFinished){
      // marks course as finished and creates certificate
      await Promise.all([finishCourse(course.id), createCertificate(course.id)]);
    }

    let certificate;

    try {
      certificate = await getCertificateById(
        course?.id,
      );
    } catch (e: any) {
      toast.error(e.response.data.message);
    }

    // transforms all imgs into dataURL/Blob.
    if(certificate){
      const { parsedReplacedData } = Object.assign({}, certificate);

      const { preview, imagesArray } = parsedReplacedData as ICertificateTemplateParsed;
      parsedReplacedData.preview.src = await imgUrlToDataURL(preview.src);
      parsedReplacedData.imagesArray = await Promise.all(imagesArray.map(async img => {
        img.src = await imgUrlToDataURL(img.src);
        return img;
      }))

      setCertificateObject({parsedReplacedData});
    }

  };

  useEffect(() => {
    if(course){
      getCertificateByIdFunction();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);


  const [verifyCourse] = useState(
    course.id === '876af501-7e8d-4395-ade7-4e0deb18ee36',
  );

  const printPDF = () => {
    const domElement = document.getElementById(`pdf-container-${certificateObject?.parsedReplacedData?.certificate_template_id}`);
    html2canvas(domElement!, {
      onclone: document => {
        document.getElementById('print')!.style.visibility = 'hidden';
      },
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'px', [
        certificateObject?.parsedReplacedData?.width / 2,
        certificateObject?.parsedReplacedData?.height / 2,
      ]);
      try {
        pdf.addImage(
          imgData,
          'SVG',
          0,
          0,
          certificateObject?.parsedReplacedData?.width / 2,
          certificateObject?.parsedReplacedData?.height / 2,
        );
        pdf.save(`certificado-${course.title}-${new Date().toLocaleDateString('pt-BR')}.pdf`);
      } catch (e: any) {
        if(!certificateObject.parsedReplacedData){
          toast.error("Curso não possui template de certificado!");
          return;
        }

        toast.error("Erro inesperado ao baixar certificado!");
      }
    });
  };

  return (
    <CourseCertificateContainer>
      <h3>Certificado de conclusão</h3>

      <Container certificateObject={certificateObject?.parsedReplacedData} />

      <div className="cert-content">
        {userIsApproved === undefined ? (
          <></>
        ) : userIsApproved ? (
          <>
            <h4>Parabéns!</h4>
            <p>
              Você finalizou todos os módulos do curso{' '}
              <strong>{course.title}</strong>
            </p>
            {verifyCourse ? (
              <span>
                <b>Seu certificado estará disponível em breve...</b>
              </span>
            ) : (
              <span>
                Verifique abaixo seu certificado e faça download do arquivo
              </span>
            )}
            <img src={CertColoredIcon} alt="colored certificate" />
            <button
              disabled={verifyCourse || !Boolean(certificateObject.parsedReplacedData)}
              type="button"
              onClick={printPDF}
              id="print"
              ref={printRef}
            >
              <span>Baixar certificado</span>
              <AiOutlineDownload size={22} />
            </button>
          </>
        ) : (
          <>
            <h4>Estamos quase lá!</h4>
            <p>
              Você finalizou todos os módulos do curso{' '}
              <strong>{course.title}</strong>
            </p>
            <BlockedCertContainer>
              <RiErrorWarningLine size={22} />
              <span>Certificado bloqueado.</span>
            </BlockedCertContainer>
            <p>
              Para desbloquear o certificado, refaça nas questões erradas para
              atingir <strong>os 80% de acertos da meta de aprendizado.</strong>
            </p>
            <CertLinedIcon />
          </>
        )}
      </div>
    </CourseCertificateContainer>
  );
};

export default CourseCertificate;

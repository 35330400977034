import React from 'react';

interface ICourseProgressContext {
  courseProgress: number;
  setCourseProgress: (progress: number) => void;
  courseHasPreview: boolean;
  setCourseHasPreview: (hasPreview: boolean) => void;
}

const CourseProgressContext = React.createContext<ICourseProgressContext>({
  courseProgress: 0,
  setCourseProgress: (progress: number) => {},
  courseHasPreview: false,
  setCourseHasPreview: (hasPreview: boolean) => {},
});

export default CourseProgressContext;

import styled from 'styled-components';

const HeaderMobileContainer = styled.div`
  height: 60px;

  .sidebar {
    padding-top: 60px;
  }

  &.with-header {
    height: 120px;

    & .section {
      top: 60px;
    }
  }
`;

const HeaderContainer = styled.div`
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 60px;
  background: white;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
`;

const SidebarContainer = styled.div`
  @keyframes slideinFromLeft {
    from {
      left: -100%;
    }

    to {
      left: 0;
    }
  }

  @keyframes slideoutFromLeft {
    from {
      left: 0;
    }

    to {
      left: -100%;
    }
  }

  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;

  &.enter {
    animation: slideinFromLeft 0.5s;
  }

  &.leave {
    animation: slideoutFromLeft 0.5s;
    left: -100%;
  }

  .backdrop {
    background-color: rgba(0, 0, 0, 0.01);
    transition: background-color 0.3s;
  }

  &.enter .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
`;

const SidebarItems = styled.div`
  width: 320px;
  height: 100vh;
  background: #212631;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: stretch;
  filter: drop-shadow(4px 0 12px rgba(0, 0, 0, 0.25));
`;

const SidebarItem = styled.button`
  background: none;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #7a869a;
  border: none;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid #2e3342;
  transition: all 0.3s;

  &:hover,
  &.active {
    color: #fff;
  }
`;

const SidebarItemExternal = styled.a`
  background: none;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #7a869a;
  border: none;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid #2e3342;
  transition: all 0.3s;
  text-decoration: none;
`;

const OpenCloseSidebarButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: none;
  outline: none;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  &:active {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const HeaderLogo = styled.img`
  max-height: 30px;
`;

const HeaderDivider = styled.span`
  flex-grow: 1;
`;

const OptionsButton = OpenCloseSidebarButton;

const OptionsContextMenuWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;

  .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }
`;

const OptionsContextMenu = styled.div`
  position: fixed;
  top: 64px;
  right: 8px;
  width: 280px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  filter: drop-shadow(1px 4px 6px rgba(0, 0, 0, 0.25));
  z-index: 9;
  padding: 12px 0;

  ::before {
    content: '';
    transform: rotate(-45deg) translateY(-50%);
    width: 20px;
    height: 20px;
    background: white;
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 9;
    border-top: solid 1px #efefef;
    border-right: solid 1px #efefef;
  }
`;

const OptionsContextMenuItemLabel = styled.span`
  padding: 8px 16px;
  font-size: 12px;
  color: #7a869a;
  border-radius: 5px;
`;

const OptionsContextMenuItemDivisor = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e5e5e5;
  margin-bottom: 8px;
`;

const OptionsContextMenuItem = styled.button`
  color: #061938;
  font-weight: 600;
  padding: 16px;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  gap: 8px;

  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Divider = styled.hr``;

const SectionHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: var(--default-purple);

  width: 100vw;
  max-height: 60px;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 8px 16px;
  filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.35));
`;

const UserGreetings = styled.p`
  color: white;
  font-size: 14px;
`;

const GoBackButton = styled.button`
  color: white;
  width: 100px;
  height: 40px;
  text-decoration: none;
  background-color: #540cc6;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const SearchContainer = styled.div``;

const ProgressBarContainer = styled.div`
  flex-grow: 1;
`;

const ShowModulesSidebarButton = styled.button`
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #540cc6;
  border: none;
  outline: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export {
  HeaderMobileContainer,
  HeaderContainer,
  SidebarContainer,
  SidebarItems,
  OpenCloseSidebarButton,
  HeaderLogo,
  HeaderDivider,
  OptionsButton,
  OptionsContextMenuWrapper,
  OptionsContextMenu,
  OptionsContextMenuItemLabel,
  OptionsContextMenuItem,
  Divider,
  SidebarItem,
  SectionHeaderContainer,
  UserGreetings,
  GoBackButton,
  SearchContainer,
  ProgressBarContainer,
  ShowModulesSidebarButton,
  OptionsContextMenuItemDivisor,
  SidebarItemExternal,
};

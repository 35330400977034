import styled from 'styled-components';

const CourseModulesMobileContainer = styled.div`
  @keyframes slideinFromRight {
    from {
      right: -100%;
    }

    to {
      right: 0;
    }
  }

  @keyframes slideoutFromRight {
    from {
      right: 0;
    }

    to {
      right: -100%;
    }
  }

  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;

  &.enter {
    right: 0;
    animation: slideinFromRight 0.5s;
  }

  &.leave {
    right: -100%;
    animation: slideoutFromRight 0.5s;
  }

  .backdrop {
    background-color: rgba(0, 0, 0, 0.01);
    transition: background-color 0.3s;
  }

  &.enter .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
`;

const CourseModulesMobileContentWrapper = styled.div`
  background: #272c39;
  width: 260px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-left: solid 1px #2e3342;
`;

const CourseModulesMobileCloseButton = styled.button`
  position: absolute;
  top: 16px;
  transform: translateX(-120%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #272c39;
  color: white;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const UserGreetings = styled.div`
  padding: 22px 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const CourseModulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const CourseModuleContainer = styled.div`
  background: #212631;
  border-bottom: solid 1px #2e3342;
  font-size: 16px;
  cursor: pointer;
`;

const CourseModuleTab = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 22px 4px;
  color: #7a869a;

  &.opened {
    background: var(--default-purple);
    color: white;
  }

  .icon-container {
    display: flex;
  }
`;

const CourseModuleContent = styled.div``;

export {
  CourseModulesMobileContainer,
  CourseModulesMobileContentWrapper,
  CourseModulesMobileCloseButton,
  UserGreetings,
  CourseModuleContainer,
  CourseModuleTab,
  CourseModuleContent,
  CourseModulesContainer,
};

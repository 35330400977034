import styled from 'styled-components';

const ModuleLessonsMobileContainer = styled.div``;

const ModuleLessonContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 4px;
  color: #7a869a;
  transition: color 0.3s;

  &.selected,
  &:hover {
    color: white;
  }
`;

const LessonFluxContainer = styled.div`
  padding: 0 5px;
  min-height: 50px;
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LessonContent = styled.div`
  padding: 8px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  & .exam-errors {
    font-size: 12px;
  }
`;

const LessonFluxCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px;

  &.exam-error {
    background: #df0028;
    color: white;
  }

  &.unlocked {
    border-color: var(--default-purple);
  }

  &.locked {
    color: var(--medium-gray);
    border: solid 2px var(--medium-gray);
  }

  &.finished {
    background: var(--default-purple);
    border-color: var(--default-purple);
    color: white;
  }
`;

const LessonFluxUpperBar = styled.span`
  border: solid 1px;
  flex-grow: 1;
  border-color: var(--medium-gray);

  &.exam-error {
    border-color: #df0028;
  }

  &.unlocked {
    border-color: var(--default-purple);
  }

  &.locked {
    border-color: var(--medium-gray);
  }

  &.finished {
    border-color: var(--default-purple);
  }

  &.first {
    visibility: hidden;
  }
`;

const LessonFluxLowerBar = styled.span`
  border: solid 1px;
  flex-grow: 1;
  border-color: var(--medium-gray);

  &.exam-error {
    border-color: #df0028;
  }

  &.locked {
    border: solid 1px var(--medium-gray);
  }

  &.finished {
    border-color: var(--default-purple);
  }

  &.last {
    visibility: hidden;
  }
`;

export {
  ModuleLessonsMobileContainer,
  ModuleLessonContainer,
  LessonContent,
  LessonFluxContainer,
  LessonFluxLowerBar,
  LessonFluxUpperBar,
  LessonFluxCircle,
};

import React from 'react';
import ContentLoader from 'react-content-loader';

const CourseCardSkeleton: React.FC = () => {
  return (
    <ContentLoader width={300} height={350} backgroundColor="#e3e3e3">
      <rect width="300" height="175" ry="5" />
      <rect rx="5" ry="5" y="173" width="5" height="175" />
      <rect rx="5" ry="5" y="345" width="300" height="5" />
      <rect rx="5" ry="5" x="295" y="173" width="5" height="175" />

      <rect rx="5" ry="5" x="10" y="190" width="280" height="35" />
      <rect rx="5" ry="5" x="10" y="245" width="280" height="35" />
      <rect rx="5" ry="5" x="10" y="300" width="280" height="35" />
    </ContentLoader>
  );
};

export default CourseCardSkeleton;

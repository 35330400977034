import LessonFromApi from '../../models/from-api/lesson';
import LessonUserFromApi from '../../models/from-api/lesson-user';
import ModuleFromApi from '../../models/from-api/module';
import ModuleUserFromApi from '../../models/from-api/module-user';
import Lesson from '../../models/lesson';
import LessonCertificate from '../../models/lesson-certificate';
import LessonExam from '../../models/lesson-exam';
import Module from '../../models/module';
import convertToLesson from './convert-lesson';

const getContentUser = (
  content: LessonFromApi,
  moduleUser?: ModuleUserFromApi,
): LessonUserFromApi => {
  if (moduleUser && moduleUser.contents && moduleUser.contents.length) {
    const lessonUser = moduleUser.contents.find(
      modUser => modUser.content_id === content.content_id,
    );
    if (lessonUser) {
      return lessonUser;
    }
  }

  return {} as LessonUserFromApi;
};

const convertToModule = async (
  module: ModuleFromApi,
  courseId: string,
  isFirst: boolean,
  isLast: boolean,
  moduleUser?: ModuleUserFromApi,
): Promise<Module> => {
  return {
    id: module.course_id,
    number: module.position,
    description: module.description,
    position: module.position,
    title: module.title,
    alreadyStarted: !!moduleUser && !!moduleUser.start_at,
    alreadyFinished: !!moduleUser && !!moduleUser.finish_at,
    lessons: (module.contents && module.contents.length
      ? await Promise.all(
          module.contents
            .sort((contentA, contentB) =>
              contentA.position > contentB.position ? 1 : -1,
            )
            .map((content, index) =>
              convertToLesson(
                courseId,
                module.course_id,
                content,
                index === 0,
                index === module.contents.length - 1,
                getContentUser(content, moduleUser),
              ),
            ),
        )
      : ([] as unknown)) as (Lesson | LessonExam | LessonCertificate)[],
    isFirstOfCourse: isFirst,
    isLastOfCourse: isLast,
  };
};

export default convertToModule;

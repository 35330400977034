import React, { useEffect, useRef } from 'react';
import { PlayerSambaVideosContainer } from './styles';

declare let SambaPlayer: any;

export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface PlayerSambaVideosProps {
  contentReference: string;
  controlsToEnable?: string[];
  resume?: number;
  getEventListeners?: (eventListener: PlayerEventListener) => void;
}

const PlayerSambaVideos: React.FC<PlayerSambaVideosProps> = ({
  contentReference,
  controlsToEnable,
  resume,
  getEventListeners,
}) => {
  const playerContainer = useRef<HTMLDivElement>(null);

  const configurePlayer = () => {
    if (contentReference) {
      const playerBaseUrl = process.env.REACT_APP_PLAYER_BASE_URL || '';
      if (playerBaseUrl) {
        const contentReferenceWithoutBaseUrl = contentReference.replace(
          playerBaseUrl,
          '',
        );
        if (contentReferenceWithoutBaseUrl) {
          const splittedReference = contentReferenceWithoutBaseUrl.split('/');
          if (splittedReference && splittedReference.length > 1) {
            const playerHash = splittedReference[0];
            const midiaId = splittedReference[1];

            SambaPlayer.clearAllMediaEvents();

            new SambaPlayer(playerContainer.current, {
              ph: playerHash,
              m: midiaId,
              playerParams: {
                enableControls: controlsToEnable,
                enableShare: false,
                resume: resume || false,
                keyboardShortcuts: false,
              },
              events: {
                '*': getEventListeners,
              },
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (playerContainer && playerContainer.current) {
      if (Array.from(playerContainer.current.children).length > 0) {
        playerContainer.current.firstChild?.remove();
      }

      configurePlayer();
    }
  }, [playerContainer, contentReference]);

  return (
    <PlayerSambaVideosContainer
      ref={playerContainer}
    ></PlayerSambaVideosContainer>
  );
};

export default PlayerSambaVideos;

import checkIfContentsIsLocked from '../../helpers/check-contents-locked';
import convertToCourse from '../../helpers/converters/convert-course';
import includeExamAndCertificateInLessons from '../../helpers/include-exam-certificate';
import httpClient from '../../http-client';
import Course from '../../models/course';
import CourseFromApi from '../../models/from-api/course';
import Lesson from '../../models/lesson';
import LessonExam from '../../models/lesson-exam';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CetificateProps } from '../../pdf/generateCertificate';

const getCourses = async (filters?: object): Promise<Course[]> => {
  let courses = (
    await httpClient.get('/trails', { params: { is_active: true, ...filters } })
  ).data as CourseFromApi[];

  let convertedCourses = [] as Course[];
  if (courses && courses.length) {
    convertedCourses = await Promise.all(
      courses.map(course => convertToCourse(course)),
    );
  }

  return convertedCourses;
};

const getOngoingCourses = async (filters?: object) => {
  let courses = (
    await httpClient.get<CourseFromApi[]>('/trail-users/me/in-progress', {
      params: filters,
    })
  ).data;

  return await Promise.all(courses.map(course => convertToCourse(course)));
};

const getCourse = async (courseId: string) => {
  let course = (await httpClient.get(`/trails/${courseId}`)).data;

  if (course && course.trail && course.user) {
    const { user } = course;
    const filteredUnavailableCourses = user.courses.filter(
      (course: Course) => !!course,
    );

    course = {
      ...course.trail,
      user: { ...user, courses: filteredUnavailableCourses },
    };
  }

  const convertedCourse = await convertToCourse(course, course.user);

  if (convertedCourse && convertedCourse.id) {
    const allLessons = await includeExamAndCertificateInLessons(
      convertedCourse.modules.map(mod => mod.lessons || []).flat(),
      convertedCourse.alreadyFinished,
    );

    for (let module of convertedCourse.modules) {
      const allLessonsFromModule = allLessons.filter(
        lesson =>
          lesson.type !== 'CERTIFICATE' &&
          (lesson as Lesson | LessonExam).moduleId === module.id,
      );

      const lessonsFromModule = (
        (allLessonsFromModule.filter(
          lesson => lesson.type === 'AUDIO' || lesson.type === 'VIDEO',
        ) as Lesson[]) || []
      ).sort((l1, l2) => (l1.position > l2.position ? 1 : -1));
      const examsFromModule = allLessonsFromModule.filter(
        lesson => lesson.type === 'EXAM',
      ) as LessonExam[];

      const lessonsToBeIncluded = lessonsFromModule as (Lesson | LessonExam)[];
      const examsAndWhereToPutThem = [] as {
        index: number;
        exam: LessonExam;
      }[];
      let index = 1;
      for (let lesson of lessonsFromModule) {
        if (lesson && lesson.exam && lesson.exam.id) {
          const exam = examsFromModule.find(
            exam => exam.lessonId === lesson.id,
          )!;
          examsAndWhereToPutThem.push({ index, exam });
        }

        index++;
      }

      let numberOfExamsIncluded = 0;
      for (let examAndIndex of examsAndWhereToPutThem) {
        lessonsToBeIncluded.splice(
          examAndIndex.index + numberOfExamsIncluded,
          0,
          examAndIndex.exam,
        );
        numberOfExamsIncluded++;
      }

      module.lessons = lessonsToBeIncluded;
    }

    const lastModule =
      convertedCourse.modules[convertedCourse.modules.length - 1];
    const certificateLesson = allLessons.find(
      lesson => lesson.type === 'CERTIFICATE',
    )!;
    lastModule.lessons.push(certificateLesson);

    checkIfContentsIsLocked(convertedCourse);
  }

  return convertedCourse;
};

const startCourse = async (courseId: string) => {
  return (
    await httpClient.post(`/trail-users`, {
      trail_id: courseId,
    })
  ).data;
};

const finishCourse = async (courseId: string) => {
  return (
    await httpClient.put(`/trail-users/finish`, {
      trail_id: courseId,
    })
  ).data;
};

const startModule = async (courseId: string, moduleId: string) => {
  return (
    await httpClient.post(`/trail-course-users`, {
      trail_id: courseId,
      course_id: moduleId,
    })
  ).data;
};

const finishModule = async (courseId: string, moduleId: string) => {
  return (
    await httpClient.put(`/trail-course-users/finish`, {
      trail_id: courseId,
      course_id: moduleId,
    })
  ).data;
};

const startLesson = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
) => {
  return (
    await httpClient.post(`/trail-course-content-users`, {
      trail_id: courseId,
      course_id: moduleId,
      content_id: lessonId,
    })
  ).data;
};

const startPreview = async (courseId: string) => {
  return (
    await httpClient.post(`/trail-course-content-users`, {
      trail_id: courseId,
      is_preview: true,
      content_id: null,
      course_id: null,
    })
  ).data;
};

const finishLesson = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
) => {
  return (
    await httpClient.put(`/trail-course-content-users/finish`, {
      trail_id: courseId,
      course_id: moduleId,
      content_id: lessonId,
    })
  ).data;
};

const finishPreview = async (courseId: string) => {
  return (
    await httpClient.put(`/trail-course-content-users/finish`, {
      trail_id: courseId,
      course_id: null,
      content_id: null,
    })
  ).data;
};

const updateLessonProgress = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
  content_view: number | null,
) => {
  return (
    await httpClient.patch(`/trail-course-content-users/content-view`, {
      trail_id: courseId,
      course_id: moduleId,
      content_id: lessonId,
      content_view,
    })
  ).data;
};

const updatePreviewProgress = async (
  courseId: string,
  content_view: number | null,
) => {
  return (
    await httpClient.patch(`/trail-course-content-users/content-view`, {
      trail_id: courseId,
      course_id: null,
      content_id: null,
      content_view,
      is_preview: true,
    })
  ).data;
};

const searchCourses = async (param: string) => {
  return (await httpClient.get(`/trails?is_active=true&search=${param}`)).data;
};

const toogleBookmark = async (courseId: string, addToList: boolean) => {
  if (addToList) {
    await httpClient.post(`/book-marks`, {
      trail_id: courseId,
    });
  } else {
    await httpClient.delete(`/book-marks/${courseId}`);
  }
};

const sendCourseFinishMail = async (courseId: string ) => {
  await httpClient.post(`/trail-users/certificate-mail/${courseId}`);
}

const sendCourseCertificateMail = async (
  courseId: string,
  props: CetificateProps,
) => {
    const domElement = document.getElementById(
      `pdf-container-${props.parsedReplacedData.certificate_template_id}`,
    );

    html2canvas(domElement!, {
      onclone: document => {
        document.getElementById('print')!.style.visibility = 'hidden';
      },
    }).then(async canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'px', [
        parseInt(props.parsedReplacedData.width) / 2,
        parseInt(props.parsedReplacedData.height) / 2,
      ]);
        pdf.addImage(
          imgData,
          'SVG',
          0,
          0,
          parseInt(props.parsedReplacedData.width) / 2,
          parseInt(props.parsedReplacedData.height) / 2,
        );

      const generatedCertificate: File = new File(
        [pdf.output('blob') as BlobPart],
        'Certificado',
        { type: 'pdf', lastModified: new Date().getTime() },
      );

      const formData = new FormData();
      formData.append('file', generatedCertificate);

      await httpClient.post(
        `/trail-users/certificate-mail/${courseId}`,
        formData,
      );
    });
};

export {
  getCourses,
  getCourse,
  startCourse,
  finishCourse,
  startModule,
  finishModule,
  startLesson,
  finishLesson,
  getOngoingCourses,
  updateLessonProgress,
  startPreview,
  updatePreviewProgress,
  finishPreview,
  searchCourses,
  toogleBookmark,
  sendCourseFinishMail,
  sendCourseCertificateMail,
};

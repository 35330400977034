import React from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import Home from '../../pages/Home';
import Courses from '../../pages/Courses';
import NewCourse from '../../pages/Course';
import Login from '../../pages/Login';
import Annotations from '../../pages/Annotations';
import Certificates from '../../pages/Certificates';
import MyCourses from '../../pages/MyCourses';
import VideoLibrary from '../../pages/VideoLibrary';
import VideoContent from '../../pages/VideoLibrary/components/VideoContent';
import { isExpired } from 'react-jwt';

const ProtectedRoute: React.FC<RouteProps> = ({component, ...rest}) => {
  const token = localStorage.getItem('ead/api/token');
  const isAuthenticated = !!token && !isExpired(token)

  const routeComponent = (props: any) => (
    isAuthenticated
        ? React.createElement(component!, props)
        : <Redirect to={{pathname: '/'}}/>
  );
  return <Route {...rest} render={routeComponent}/>;
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />

      <ProtectedRoute path="/home" exact component={Home} />

      <ProtectedRoute path="/my-courses" exact component={MyCourses} />

      <ProtectedRoute path="/certificates" component={Certificates} />

      <ProtectedRoute path="/annotations" exact component={Annotations} />

      <ProtectedRoute path="/video-library" exact component={VideoLibrary} />
      <ProtectedRoute path="/video-library/:contentId" exact component={VideoContent} />

      <Route>
        <ProtectedRoute path="/courses" exact component={Courses} />
        <Route>
          <ProtectedRoute path="/course/:courseId" exact component={NewCourse} />

          <ProtectedRoute
            path="/course/:courseId/module/:moduleId/lesson/:lessonId"
            exact
            component={NewCourse}
          />
          <ProtectedRoute
            path="/course/:courseId/lesson/:lessonId"
            exact
            component={NewCourse}
          />
          <ProtectedRoute
            path="/course/:courseId/module/:moduleId/lesson/:lessonId/exam/:examId"
            exact
            component={NewCourse}
          />
        </Route>
      </Route>
    </Switch>
  );
};

export default Routes;


const _isDevelopment: boolean = !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development';
const _isProduction: boolean = process.env.REACT_APP_ENV === 'production';

export function isDev(): boolean
{
    return _isDevelopment;
}

export function isProduction(): boolean 
{
  return _isProduction;
}
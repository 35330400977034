import styled from 'styled-components';

const CourseInstructionsContainer = styled.div`
  width: 560px;
  min-height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--default-font-color);
  background: #fff;
  border-radius: 8px;
  padding: 16px;

  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 0;
    border-radius: 0;
    overflow-y: scroll !important;
    min-height: -webkit-fill-available;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;

  & button {
    border: none;
    cursor: pointer;
    background: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  button.cancel {
    padding-left: 0;
    color: var(--default-font-color);
  }

  button.next {
    background: var(--default-purple);
    color: white;

    path {
      fill: white;
    }

    &:disabled {
      background: #7324f280 !important;
    }
  }
`;

const Header = styled.div`
  padding: 16px;

  .header-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      color: var(--default-title-color);
    }

    .btn-close {
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      color: #7a869a;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: var(--darker-gray);
      }
    }
  }
`;

const SubHeader = styled.div`
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: var(--default-dark-gray);
  margin-top: 16px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const CourseInstructionsContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  flex-grow: 1;

  .between {
    display: grid;
    width: 100%;
    justify-items: center;
    gap: 16px;
  }

  .content-block {
    align-self: flex-end;
  }
  img {
    align-self: self-end;
  }

  p {
    font-size: 20px;
    text-align: center;
    color: var(--default-font-color);

    & > * {
      font-size: inherit;
    }
  }

  ul.last {
    align-self: flex-end;
    margin-bottom: 0;
  }

  ul.last li {
    text-align: left;
  }

  ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    li {
      text-align: center;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 16px;

      .icon {
        display: block;
      }
      .label {
        font-size: 20px;
      }
    }
    @media screen and (min-width: 770px) {
      &:not(ul.last) {
        li {
          justify-content: center;
        }
      }
    }
  }

  .agree-container {
    width: 100%;
    text-align: left;

    label {
      font-size: var(--font-small);
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      color: var(--darker-gray);
      cursor: pointer;
    }

    & input[type='checkbox'] {
      color: var(--default-purple);
      width: 16px;
      height: 16px;
      cursor: pointer;
      outline: none;
      border-radius: solid 1px var(--default-dark-gray);
    }
  }
`;

export {
  CourseInstructionsContainer,
  FooterContainer,
  Header,
  CourseInstructionsContent,
  SubHeader,
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineUser,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { secondsToTimeText } from '../../helpers/time';
import ICourse from '../../models/course';
import Lesson from '../../models/lesson';
import Module from '../../models/module';
import CourseContent from './Components/CourseContent';
import { CourseModules } from './Components/CourseModules';
import CourseContext from './Context';
import {
  BookmarkButton,
  CourseAuthor,
  CourseAuthorDurationAndBookmarkContainer,
  CourseCategory,
  CourseContainer,
  CourseContentContainer,
  CourseDeadLine,
  CourseDuration,
  CourseTargetGroup,
  CourseInfoAndProgress,
  CourseLowerContent,
  CourseProgress,
  CourseProgressBarContainer,
  CourseProgressContainer,
  CourseTitle,
  CourseTitleAndCategory,
  MobileAuthorAndDurationContainer,
  StyleOfDurationTargetGpAuthor,
} from './styles';
import {
  getCourse as getCourseService,
  toogleBookmark as toogleBookmarkService,
  startCourse as startCourseService,
  startModule as startModuleService,
  startLesson as startLessonService,
  finishModule as finishModuleService,
  finishLesson as finishLessonService,
  finishCourse as finishCourseService,
  startPreview as startPreviewService,
  finishPreview as finishPreviewService,
  updateLessonProgress as updateLessonProgressService,
  updatePreviewProgress as updatePreviewProgressService,
  sendCourseFinishMail,
} from '../../services/course/index';
import getErrorMessage from '../../helpers/get-error-message';
import { toast } from 'react-toastify';
import CourseSkeleton from '../Courses/Skeleton';
import LessonExam from '../../models/lesson-exam';
import LessonCertificate from '../../models/lesson-certificate';
import LessonType from '../../models/lesson-type';
import CourseExam from './Components/CourseExam';
import CourseCertificate from './Components/CourseCertificate';
import { hideRawModal, showInRawModal } from '../../helpers/rawModal';
import CourseInstructions from './Components/CourseInstructions/alt';
import { useContext } from 'react';
import CourseProgressContext from '../../context/CourseProgressContext';
import CourseModulesMobile from './Components/CourseModulesMobile';
import GlobalContext from '../../GlobalContext';
import { FaCrosshairs } from 'react-icons/fa';
import setTargetGroup from '../../helpers/converters/convert-target-group';
import { nonWhiteSpace } from 'html2canvas/dist/types/css/syntax/parser';

export interface CourseExtendedWindow extends Window {
  actualCourseId: string;
  actualModuleId: string;
  actualLessonId: string;
  actualExamId: string;
}

declare let window: CourseExtendedWindow;

interface CourseParams {
  courseId: string;
  moduleId: string;
  lessonId: string;
  examId: string;
}

const Course: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useContext(GlobalContext);
  const { courseId, moduleId, lessonId, examId } = useParams<CourseParams>();
  const { setCourseProgress, setCourseHasPreview } = useContext(
    CourseProgressContext,
  );

  const [course, setCourse] = useState({} as ICourse);
  const [selectedLessonModule, setSelectedLessonModule] = useState(
    {} as Module,
  );
  const [selectedLesson, setSelectedLesson] = useState(
    {} as Lesson | LessonExam | LessonCertificate,
  );

  const isWatchingAnyLesson = location.pathname.match(
    /course\/.[^/]+\/module\/.[^/]+\/lesson\/.[^/]+/,
  );

  const checkIfIsCertificate = () => {
    const isCertificate = !!new URLSearchParams(window.location.search).get(
      'isCertificate',
    );
    return isCertificate;
  };

  const pushLessonUrl = (
    type: LessonType,
    courseId: string,
    moduleId?: string,
    lessonId?: string,
    examId?: string,
  ) => {
    const baseUrl = `/course/${courseId}`;
    if (type === 'EXAM') {
      history.push(
        `${baseUrl}/module/${moduleId}/lesson/${lessonId}/exam/${examId}`,
      );
    } else if (type === 'CERTIFICATE') {
      history.push(`${baseUrl}?isCertificate=true`);
    } else {
      history.push(`${baseUrl}/module/${moduleId}/lesson/${lessonId}`);
    }
  };

  const findActualLesson = (
    lessons: (Lesson | LessonExam | LessonCertificate)[],
  ): Lesson | LessonExam | LessonCertificate => {
    let foundLesson = {} as any;
    if (examId) {
      foundLesson = lessons.find(
        lesson => (lesson as LessonExam).id === examId,
      );
    } else if (checkIfIsCertificate()) {
      foundLesson = lessons.find(
        lesson => (lesson as LessonCertificate).type === 'CERTIFICATE',
      );
    } else {
      foundLesson = lessons.find(lesson => (lesson as Lesson).id === lessonId);
    }

    return foundLesson;
  };

  const getCourse = async () => {
    const localCourse = await getCourseService(courseId);
    setCourse(localCourse);
  };

  const toogleBookmark = async () => {
    try {
      await toogleBookmarkService(courseId, !course.addedToList);
      course.addedToList = !course.addedToList;
      setCourse({ ...course });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(
        `Erro ao ${
          course.addedToList ? 'remover dos' : 'adicionar aos'
        } favoritos. ${errorMessage}`,
      );
    }
  };

  const changeLesson = () => {
    if (moduleId) {
      const localSelectedLessonModule = course.modules.find(
        module => module.id === moduleId,
      );
      if (
        localSelectedLessonModule &&
        localSelectedLessonModule.lessons &&
        localSelectedLessonModule.lessons.length
      ) {
        const localSelectedLesson = findActualLesson(
          localSelectedLessonModule.lessons,
        );
        if (localSelectedLesson.isLocked) {
          history.go(-1);
        } else {
          if (localSelectedLesson) {
            setSelectedLessonModule(localSelectedLessonModule);
            setSelectedLesson(localSelectedLesson);
          }
        }
      }
    } else if (lessonId) {
      const allLessons = (course.modules || []).map(mod => mod.lessons).flat();
      const foundLesson = findActualLesson(allLessons) as Lesson;
      if (foundLesson) {
        if (foundLesson.isLocked) {
          history.go(-1);
        } else {
          pushLessonUrl(
            foundLesson.type,
            foundLesson.courseId,
            foundLesson.moduleId,
            foundLesson.id,
          );
        }
      }
    } else if (checkIfIsCertificate()) {
      const allLessons = (course.modules || []).map(mod => mod.lessons).flat();
      const localSelectedLesson = findActualLesson(allLessons);
      if (localSelectedLesson.isLocked) {
        history.go(-1);
      } else {
        if (localSelectedLesson) {
          setSelectedLessonModule({} as Module);
          setSelectedLesson(localSelectedLesson);
        }
      }
    } else if (course.alreadyStarted && !course.referenceUrl) {
      const allLessons = (course.modules || []).map(mod => mod.lessons).flat();
      const notFinishedLessons = allLessons.filter(les => !les.alreadyFinished);
      if (notFinishedLessons && notFinishedLessons.length) {
        const firstNotFinishedLesson = notFinishedLessons[0];
        if (firstNotFinishedLesson.type !== 'CERTIFICATE') {
          pushLessonUrl(
            firstNotFinishedLesson.type,
            course.id,
            (firstNotFinishedLesson as Lesson | LessonExam).moduleId,
            firstNotFinishedLesson.type === 'EXAM'
              ? (firstNotFinishedLesson as LessonExam).lessonId
              : (firstNotFinishedLesson as Lesson).id,
            firstNotFinishedLesson.type === 'EXAM'
              ? (firstNotFinishedLesson as LessonExam).id
              : undefined,
          );
        } else {
          history.push(`/course/${courseId}?isCertificate=true`);
        }
      } else {
        const firstLesson = allLessons[0];

        pushLessonUrl(
          firstLesson.type,
          course.id,
          (firstLesson as Lesson | LessonExam).moduleId,
          firstLesson.type === 'EXAM'
            ? (firstLesson as LessonExam).lessonId
            : (firstLesson as Lesson).id,
          firstLesson.type === 'EXAM'
            ? (firstLesson as LessonExam).id
            : undefined,
        );
      }
    }
  };

  const startCourse = async () => {
    showInRawModal(
      <CourseInstructions finishInstructions={confirmStartCourse} />,
    );
  };

  const confirmStartCourse = async () => {
    hideRawModal();

    if (!course.alreadyStarted) {
      try {
        await startCourseService(course.id);

        course.alreadyStarted = true;
        const firstLesson = ((course.modules || [])
          .map(module => module.lessons || [])
          .flat() || [])[0] as Lesson;
        firstLesson.isLocked = false;

        setCourse({ ...course });

        pushLessonUrl(
          firstLesson.type,
          firstLesson.courseId,
          firstLesson.moduleId,
          firstLesson.id,
        );
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar o curso. ' + errorMessage);
      }
    }
  };

  const startLesson = async (isPreview?: boolean) => {
    if (!!isPreview && !course.previewData) {
      await startPreviewService(courseId);
      return;
    }

    const localSelectedLesson = selectedLesson as Lesson;

    if (!selectedLessonModule.alreadyStarted) {
      try {
        selectedLessonModule.alreadyStarted = true;
        await startModuleService(
          localSelectedLesson.courseId,
          localSelectedLesson.moduleId,
        );
      } catch (error) {
        selectedLessonModule.alreadyStarted = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar o módulo. ' + errorMessage);
      }
    }

    if (!localSelectedLesson.alreadyStarted) {
      try {
        localSelectedLesson.alreadyStarted = true;
        await startLessonService(
          localSelectedLesson.courseId,
          localSelectedLesson.moduleId,
          localSelectedLesson.id,
        );
      } catch (error) {
        localSelectedLesson.alreadyStarted = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar a aula. ' + errorMessage);
      }
    }

    setCourse({ ...course });
  };

  const finishLesson = async (
    justModuleAndLesson?: boolean,
    isPreview?: boolean,
  ) => {
    if (!!isPreview && !(selectedLesson as Lesson).id) {
      await finishPreviewService(courseId);
      return;
    }

    const localSelectedLesson = selectedLesson as Lesson;

    if (!justModuleAndLesson) {
      if (!localSelectedLesson.alreadyFinished) {
        try {
          localSelectedLesson.alreadyFinished = true;
          await finishLessonService(
            localSelectedLesson.courseId,
            localSelectedLesson.moduleId,
            localSelectedLesson.id,
          );

          enableNextLesson();
        } catch (error) {
          localSelectedLesson.alreadyFinished = false;
          const errorMessage = getErrorMessage(error);
          toast.error('Houve um erro ao finalizar a aula. ' + errorMessage);
        }
      }
    }

    if (
      selectedLessonModule &&
      !selectedLessonModule.alreadyFinished &&
      (selectedLessonModule.lessons || [])
        .filter(les => les.type !== 'CERTIFICATE')
        .every(lesson => (lesson as Lesson).alreadyFinished)
    ) {
      try {
        selectedLessonModule.alreadyFinished = true;
        await finishModuleService(
          localSelectedLesson.courseId || courseId,
          localSelectedLesson.moduleId,
        );
      } catch (error) {
        selectedLessonModule.alreadyFinished = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao finalizar o módulo. ' + errorMessage);
      }
    }

    if (
      !course.alreadyFinished &&
      course.modules.every(module => module.alreadyFinished)
    ) {
      try {
        course.alreadyFinished = true;
        await finishCourseService(localSelectedLesson.courseId);
      } catch (error) {
        course.alreadyFinished = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao finalizar o curso. ' + errorMessage);
      }
    }

    setCourse({ ...course });
  };
  const getNextLesson = useCallback(
    (actualLesson?: Lesson | LessonExam | LessonCertificate) => {
      const allLessons =
        ((course.modules || []).map(module => module.lessons) || []).flat() ||
        [];

      let localSelectedLesson = {} as Lesson | LessonExam | LessonCertificate;
      if (!actualLesson) {
        localSelectedLesson = findActualLesson(allLessons);
      } else {
        localSelectedLesson = actualLesson;
      }

      const indexOfSelectedLesson = allLessons.indexOf(localSelectedLesson!);
      return allLessons[
        indexOfSelectedLesson === allLessons.length - 1
          ? 0
          : indexOfSelectedLesson + 1
      ];
    },
    [selectedLesson],
  );

  const enableNextLesson = () => {
    let nextLesson = undefined as
      | Lesson
      | LessonExam
      | LessonCertificate
      | undefined;
    while (
      !nextLesson ||
      !nextLesson.isLocked ||
      nextLesson.type === 'CERTIFICATE'
    ) {
      nextLesson = getNextLesson(nextLesson);

      if (nextLesson.type === 'CERTIFICATE') {
        break;
      }
    }
    nextLesson.isLocked = false;
  };

  const goToNextLesson = () => {
    const nextLesson = getNextLesson();
    if (nextLesson.type === 'EXAM') {
      const lesson = nextLesson as LessonExam;
      pushLessonUrl(
        lesson.type,
        lesson.courseId,
        lesson.moduleId,
        lesson.lessonId,
        lesson.id,
      );
    } else if (nextLesson.type === 'CERTIFICATE') {
      pushLessonUrl(nextLesson.type, courseId);
    } else {
      const lesson = nextLesson as Lesson;
      pushLessonUrl(lesson.type, lesson.courseId, lesson.moduleId, lesson.id);
    }
  };

  const updateContentProgress = useCallback(
    async (time: number | null, isPreview?: boolean) => {
      if (!isPreview) {
        await updateLessonProgressService(
          courseId,
          selectedLessonModule.id,
          (selectedLesson as Lesson).id,
          time,
        );

        return;
      }

      await updatePreviewProgressService(courseId, time);
    },
    [courseId, (selectedLesson as Lesson).id, selectedLessonModule.id],
  );

  useEffect(() => {
    if (
      course &&
      course.id &&
      selectedLesson.type !== 'CERTIFICATE' &&
      (!moduleId ||
        (selectedLesson as Lesson).moduleId !== moduleId ||
        !lessonId ||
        (selectedLesson as Lesson).id !== lessonId)
    ) {
      changeLesson();
    }
  }, [location.pathname, location.search, course]);

  useEffect(() => {
    getCourse();
  }, [courseId]);

  useEffect(() => {
    if (selectedLesson.type === 'EXAM') {
      const lesson = selectedLesson as LessonExam;
      window.actualCourseId = lesson.courseId;
      window.actualModuleId = lesson.moduleId;
      window.actualLessonId = lesson.lessonId;
      window.actualExamId = lesson.id;
    } else if (selectedLesson.type === 'CERTIFICATE') {
      window.actualCourseId = courseId;
      window.actualModuleId = '';
      window.actualLessonId = '';
      window.actualExamId = '';
    } else {
      const lesson = selectedLesson as Lesson;
      window.actualCourseId = lesson.courseId;
      window.actualModuleId = lesson.moduleId;
      window.actualLessonId = lesson.id;
    }
  }, [selectedLesson]);

  const courseProgress = useMemo(() => {
    if (course && course.modules && course.modules.length) {
      const allLessons = course.modules
        .map(module =>
          (module.lessons || []).filter(les => les.type !== 'CERTIFICATE'),
        )
        .flat();
      const completedLessons = allLessons.filter(
        lesson => lesson.alreadyFinished,
      );
      const progress = (completedLessons.length * 100) / allLessons.length;

      setCourseProgress(progress);
      return progress;
    }

    setCourseProgress(0);
    return 0;
  }, [course]);

  const userIsApproved = useMemo(() => {
    const allLessons = (course.modules || [])
      .map(mod => mod.lessons || [])
      .flat()
      .filter(les => les.type !== 'CERTIFICATE');

    if (!allLessons.length) {
      return undefined;
    }

    const finishedAllLessons = allLessons.every(les => les.alreadyFinished);

    const allExams = allLessons.filter(les => les.type === 'EXAM');
    const approvedOnAllExams = allExams.every(
      ex => (ex as LessonExam).isApproved,
    );

    return finishedAllLessons && approvedOnAllExams;
  }, [course]);

  useEffect(() => {
    setCourseHasPreview(!!course.referenceUrl);
  }, [course]);


  const shouldShowCourseHeader = useMemo(() => {
    return (
      selectedLesson.type === 'AUDIO' ||
      selectedLesson.type === 'VIDEO' ||
      !course.alreadyStarted
    );
  }, [selectedLesson.type, course.alreadyStarted]);

  return course && course.id ? (
    <CourseContext.Provider
      value={{
        course,
        setCourse,
        selectedLessonModule,
        selectedLesson,
        startCourse,
        startLesson,
        finishLesson,
        goToNextLesson,
        pushLessonUrl,
        enableNextLesson,
        userIsApproved,
        updateContentProgress,
      }}
    >
      <CourseContainer>
        <CourseContentContainer className="content">
          {shouldShowCourseHeader && (
            <>
              <CourseTitleAndCategory>
                <CourseCategory>{course.categoryName}</CourseCategory>
                <CourseTitle>{course.title}</CourseTitle>
              </CourseTitleAndCategory>
              <CourseInfoAndProgress>
                <CourseAuthorDurationAndBookmarkContainer>
                  {!isMobile ? (
                    <>
                      <StyleOfDurationTargetGpAuthor>
                      <CourseAuthor>
                        <AiOutlineUser
                          size={18}
                          color="var(--default-purple)"
                        />
                        <span>Autor: {course.authorName}</span>
                      </CourseAuthor>
                      <CourseDuration>
                        <AiOutlineClockCircle
                          size={18}
                          color="var(--default-purple)"
                        />
                        <span>
                          Duração: ~ {secondsToTimeText(course.duration)}
                        </span>
                      </CourseDuration>
                      {!!course.target_group && course.target_group.length > 0 ? (
                        <CourseTargetGroup>
                        <FaCrosshairs
                          size={18}
                          color="var(--default-purple)"/>
                        <span> Público Alvo: {setTargetGroup(course?.target_group)} </span>
                      </CourseTargetGroup>
                      ): null}
                      </StyleOfDurationTargetGpAuthor>
                    </>
                  ) : (
                    <MobileAuthorAndDurationContainer>
                      <CourseAuthor>
                        <AiOutlineUser
                          size={18}
                          color="var(--default-purple)"
                        />
                        <span>Autor: {course.authorName}</span>
                      </CourseAuthor>
                      <CourseDuration>
                        <AiOutlineClockCircle
                          size={18}
                          color="var(--default-purple)"
                        />
                        <span>
                          Duração: {secondsToTimeText(course.duration)}
                        </span>
                      </CourseDuration>
                      {!!course.target_group && course.target_group.length > 0 ? (
                        <CourseTargetGroup>
                        <FaCrosshairs
                          size={18}
                          color="var(--default-purple)"/>
                        <span> Público Alvo: {setTargetGroup(course?.target_group)} </span>
                      </CourseTargetGroup>
                      ): null}
                    </MobileAuthorAndDurationContainer>
                  )}
                  {!course.deadline ? (
                    <BookmarkButton
                      onClick={toogleBookmark}
                      className={course.addedToList ? 'added' : ''}
                    >
                      <BsBookmark size={16} />
                      <span>
                        {course.addedToList ? 'Remover dos Favoritos' : 'Favoritar'}
                      </span>
                    </BookmarkButton>
                  ) : (
                    <CourseDeadLine>
                      <AiOutlineCalendar 
                        className='AiOutlineCalendar'
                        color="var(--default-purple)"
                        size={16}
                      />
                      <span>
                        Disponível até{' '}
                        {new Intl.DateTimeFormat('pt-BR').format(
                          new Date(course.deadline),
                        )}
                      </span>
                    </CourseDeadLine>
                  )}
                </CourseAuthorDurationAndBookmarkContainer>
                {course.alreadyStarted && !isWatchingAnyLesson && (
                  <CourseProgressContainer>
                    <strong>Progresso:</strong>
                    <CourseProgressBarContainer>
                      <CourseProgress>
                        <span style={{ width: `${courseProgress}%` }}></span>
                      </CourseProgress>
                      <strong>{courseProgress.toFixed(0)}%</strong>
                    </CourseProgressBarContainer>
                  </CourseProgressContainer>
                )}
              </CourseInfoAndProgress>
            </>
          )}
          <CourseLowerContent>
            {selectedLesson.type === 'EXAM' ? (
              <CourseExam />
            ) : selectedLesson.type === 'CERTIFICATE' ? (
              <CourseCertificate />
            ) : (
              <CourseContent />
            )}
            {isMobile ? <CourseModulesMobile /> : <CourseModules />}
          </CourseLowerContent>
        </CourseContentContainer>
      </CourseContainer>
    </CourseContext.Provider>
  ) : (
    <CourseSkeleton />
  );
};

export default Course;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from '../../services/login';
import { toast } from 'react-toastify';
import usePostHog from '../../hooks/usePosthog';

interface UserFromResponse {
  cpf: string;
  email: string;
  info?: UserInfo;
  name: string;
  user_id: string;
  economic_group: number[];
}

interface UserInfo {
  user_farmarcas_id: number;
}

interface LoginResponse {
  api_token: string;
  user: UserFromResponse;
}

const Login: React.FC = () => {
  const history = useHistory();
  const posthog = usePostHog();

  useEffect(() => {
    (async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const token = localStorage.getItem('core/auth/token') || urlParams.get('token');

        Object.keys(localStorage).filter(key => key.toLowerCase().startsWith("ead/")).forEach(key => localStorage.removeItem(key));

        if (token) {
          try {
            const loginResponse = (await login(token)) as LoginResponse;
            localStorage.setItem(
              'ead/api/token',
              loginResponse.api_token,
            );
            localStorage.setItem(
              'ead/user/name',
              loginResponse.user.name,
            );
            localStorage.setItem(
              'ead/user/id',
              `${loginResponse.user?.info?.user_farmarcas_id}`,
            );

            toast.success(`Bem vindo ${loginResponse.user.name}!`);

            if (
              loginResponse.user?.info?.user_farmarcas_id
            ) {
              posthog?.identify(
                loginResponse.user?.info?.user_farmarcas_id?.toString(),
              );
            }
          } catch (error) {
            window.location.href = `${process.env.REACT_APP_LOGIN_FARMARCAS}?redirect_uri=${process.env.PUBLIC_URL}`;
          }

          history.replace(`/home`);
        } else {
          window.location.href = `${process.env.REACT_APP_LOGIN_FARMARCAS}?redirect_uri=${process.env.PUBLIC_URL}`;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            if (!localStorage.getItem('core/auth/token')) {
              window.location.href = `${process.env.REACT_APP_LOGIN_FARMARCAS}?redirect_uri=${process.env.PUBLIC_URL}`;
            }
          } else {
            toast.error('Erro ao autenticar.');
            
            setTimeout(() => window.location.href = process.env.REACT_APP_RADAR_HOME|| '', 3000,);
          }
        }
      }
    })();
  }, [history]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '70vh',
      }}
    >
      <h4>Autenticando...</h4>
    </div>
  );
};

export default Login;

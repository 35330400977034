import React, { useEffect, useState, useContext } from "react";

const USERSNAP_API_KEY = process.env.REACT_APP_USERSNAP_SPACE_KEY;

export const UsersnapContext = React.createContext(null);


type Props = {
  children: React.ReactNode;
};
export const UsersnapProvider: React.FC<Props> = ({  children }) => {
    const [usersnapApi, setUsersnapApi] = useState(null);

    useEffect(() => {
        let usersnapApi: any = undefined;
        
        (window as any).onUsersnapCXLoad = function(api: any) {
            usersnapApi = api;
            setUsersnapApi(api);
        }
        const script = document.createElement('script');
        script.defer = true;
        script.async = true;
        script.src = `https://widget.usersnap.com/global/load/${USERSNAP_API_KEY}?onload=onUsersnapCXLoad`;
        document.head.appendChild(script);

        return () => {
            if (usersnapApi) {
                usersnapApi.destroy();
            }
            script.remove();
        }
    }, []);


    return (
        <UsersnapContext.Provider value={usersnapApi}>
            {children}
        </UsersnapContext.Provider>
    )
}

export function useUsersnapApi(): any {
    return useContext(UsersnapContext)
}

import styled from 'styled-components';

export const VideoLibraryContainer = styled.div`

.video-library-title {
  font-size: 24px;
  color: var(--default-font-color);
  margin: 25px 0;

    @media (max-width: 640px) {
      font-size: 16px;
    }
  }

  .slick-slide > div {
    margin-right: 14px;
  }

  .content-card {
    width: 300px;
    margin: 0 auto;
  }

  .show-all.mobile {
    margin: 0;
    box-sizing: border-box;
    font-size: var(--font-medium);
    padding: 4px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px;
    background: none;
    color: var(--default-purple);
    font-weight: bold;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 640px) {
      padding: 0 15px;
    }
  }
`;

export const VideoLibraryTitle = styled.h2`
  font-size: 24px;
  margin: 30px 0;

  @media (max-width: 640px) {
    font-size: 16px;
    margin-right: 8px;
    width: auto;
  }
`;

export const VideoCard = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 930px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SeeAllButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  button {
    padding: 10px 15px;
    color: #fff;
    background-color: var(--default-purple);
    cursor: pointer;
    min-width: 170px;
    height: fit-content;
    outline: none;
    border: none;
    border-radius: 5px;
  }
`;

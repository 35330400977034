import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';

import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import 'react-toastify/dist/ReactToastify.css';
import { isProduction } from './helpers/environment';

if(!!process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    environment: process.env.ENVIROMENT,
  });
}

if(isProduction()) {
  ReactGA.initialize("G-3S9BDRZWKT");
}

ReactDOM.render(
  <React.StrictMode>
    <App />

    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </React.StrictMode>,
  document.getElementById('root'),
);

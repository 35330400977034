import React from 'react';
import {
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineUser,
} from 'react-icons/ai';
import { FaCrosshairs, FaPlayCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import setTargetGroup from '../../helpers/converters/convert-target-group';
import { formatTimeFromDB } from '../../helpers/formatTime';
import { secondsToTimeText } from '../../helpers/time';
import LessonFromApi from '../../models/from-api/lesson';
import { BadgeTargetGroup } from '../CourseCard/style';

import { ContentCardContainer, ContentCardInfo } from './style';

interface IContentCardProps {
  content: LessonFromApi;
}

const ContentCard: React.FC<IContentCardProps> = ({ content }) => {
  const styleGroup =
    content.group === 1
      ? 'group lives'
      : content.group === 2
      ? 'group trainning'
      : content.group === 3
      ? 'group knowledge'
      : '';
  const groupName =
    content.group === 1
      ? 'Live'
      : content.group === 2
      ? 'Treinamentos'
      : content.group === 3
      ? 'Pílulas de conhecimento'
      : '';

      const handleClick = (event:any)=>{
        localStorage.setItem('yPositionContent', event.pageY.toString())
      }

  return (
    <ContentCardContainer onClick={handleClick}>
      <Link to={`/video-library/${content.content_id}`}>
        <div className="content-card">
          <div className="content-img-container">
            {content.thumbnail ? (
              <img src={`${content.thumbnail}`} alt="content" />
            ) : (
              <FaPlayCircle size={200} color="#fff" />
            )}
          </div>
          <div className={styleGroup}>{groupName}</div>

          <ContentCardInfo>
            <div>
              <h4 className="category" style={{ marginBottom: '8px' }}>
                {content.categories && content.categories[0].title}
              </h4>

              <h3 className="title">{content.title}</h3>
            </div>

            <div className="author">
              <AiOutlineUser size={14} color="var(--default-purple)" />
              <span>Autor: {content.authors && content?.authors[0].name}</span>
            </div>

            {!!content.content_target_group &&
            content.content_target_group.length > 0 ? (
              <div className="target_group">
                <FaCrosshairs size={14} color="var(--default-purple)" />
                <span>
                  {' '}
                  Público Alvo:{' '}
                  {setTargetGroup([content?.content_target_group[0]])}
                  {content?.content_target_group.length > 1 ? (
                    <BadgeTargetGroup
                      title={String(
                        setTargetGroup(
                          content?.content_target_group.slice(
                            1,
                            content.content_target_group.length,
                          ),
                        ),
                      )}
                    >
                      +{content?.content_target_group.length - 1}
                    </BadgeTargetGroup>
                  ) : null}
                </span>
              </div>
            ) : null}

            <div className="duration">
              <AiOutlineClockCircle color={'var(--default-purple)'} size={14} />
              <span>Duração: ~ {secondsToTimeText(content.duration)} </span>
            </div>

            <div className="publish">
              <AiOutlineCalendar size={14} color="var(--default-purple)" />
              <span>
                Publicado em:
                {' ' + formatTimeFromDB(String(content.created_at))}
              </span>
            </div>

          </ContentCardInfo>
        </div>
      </Link>
    </ContentCardContainer>
  );
};

export default ContentCard;

import { VideoExtraMaterialContainer } from "./style";
import Lesson from "../../../../../models/lesson";
import { ExtraMaterialCard } from "./components/ExtraMaterialCard";

interface VideoExtraMaterialProps {
    content: Lesson
}

export const VideoExtraMaterial: React.FC<VideoExtraMaterialProps> = ({ content }) => {
	return (
		<VideoExtraMaterialContainer>
		{content?.extra_materials?.length ? (
			content.extra_materials?.map(em => (
			<ExtraMaterialCard key={em.id} extraMaterial={em} />
			))
		) : (
			<>Este video não possui materiais de apoio.</>
		)}
		</VideoExtraMaterialContainer>
	);
};
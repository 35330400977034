import React from 'react';
import { useState } from 'react';
import { AiOutlineEdit, AiOutlineRight } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';

import Annotation from '../../../../../../../../models/annotation';
import {
  AnnotationCardContainer,
  AnnotationDate,
  AnnotationDateAndDescriptionContainer,
  AnnotationDescription,
  AnnotationListMobileContainer,
  AnnotationListSearchContainer,
  AnnotationListSearchInput,
  AnnotationsList,
  AnnotationListHeader,
  AnnotationTitle,
  AnnotationTitleAndIconContainer,
  AnnotationViewContainer,
  AnnotationViewContent,
  AnnotationViewDescription,
  AnnotationViewFooter,
  AnnotationViewTitle,
} from './style';
import Search from '../../../../../../../../assets/search.png';
import $clamp from 'clamp-js';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { BsTrash } from 'react-icons/bs';
import { hideRawModal } from '../../../../../../../../helpers/rawModal';

interface AnnotationCardProps {
  annotation: Annotation;
  show: (annotation: Annotation) => void;
}

const AnnotationCard: React.FC<AnnotationCardProps> = ({
  annotation,
  show,
}) => {
  const annotationDescription = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (annotationDescription.current) {
      $clamp(annotationDescription.current, { clamp: 1 });
    }
  }, [annotationDescription]);

  return (
    <AnnotationCardContainer onClick={() => show(annotation)}>
      <AnnotationTitleAndIconContainer>
        <AnnotationTitle>{annotation.title}</AnnotationTitle>

        <AiOutlineRight color="#505F79" size={16} />
      </AnnotationTitleAndIconContainer>
      <AnnotationDateAndDescriptionContainer>
        <AnnotationDate>
          {annotation.date
            ? new Intl.DateTimeFormat('pt-BR').format(annotation.date)
            : ''}
        </AnnotationDate>
        <AnnotationDescription ref={annotationDescription}>
          {annotation.description}
        </AnnotationDescription>
      </AnnotationDateAndDescriptionContainer>
    </AnnotationCardContainer>
  );
};

interface AnnotationListMobileProps {
  annotations: Annotation[];
  deleteAnnotation: (annotationId: string) => Promise<void>;
  editAnnotation: (annotation: Annotation) => void;
  reloadAnnotations: () => Promise<Annotation[]>;
}

const AnnotationListMobile: React.FC<AnnotationListMobileProps> = ({
  annotations: initialAnnotations,
  editAnnotation,
  deleteAnnotation,
  reloadAnnotations,
}) => {
  const [showingAnnotation, setShowingAnnotation] = useState<
    Annotation | undefined
  >(undefined);
  const [search, setSearch] = useState('');
  const [annotations, setAnnotations] = useState([] as Annotation[]);

  const getAnnotations = async () => {
    const localAnnotations = await reloadAnnotations();
    setAnnotations(localAnnotations);
  };

  const editShowingAnnotation = async () => {
    if (showingAnnotation) {
      editAnnotation(showingAnnotation);
      hideRawModal();
    }
  };

  const removeShowingAnnotation = async () => {
    if (showingAnnotation) {
      try {
        await deleteAnnotation(showingAnnotation.id);
        await getAnnotations();
        setShowingAnnotation(undefined);
      } catch (error) {}
    }
  };

  const annotationsToShow = useMemo(() => {
    return (annotations || []).filter(
      ann => ann.title.includes(search) || ann.description.includes(search),
    );
  }, [search, annotations]);

  useEffect(() => {
    setAnnotations(initialAnnotations);
  }, []);

  return (
    <AnnotationListMobileContainer>
      <AnnotationListHeader>
        <nav>
          <button className="close-btn" onClick={hideRawModal}>
            <BsChevronLeft size={12} />
            <span>Voltar</span>
          </button>
        </nav>
      </AnnotationListHeader>
      {!showingAnnotation ? (
        <>
          <div className="label-box">
            <h3>Anotações</h3>
          </div>
          <AnnotationListSearchContainer>
            <div className="wrapper">
              <AnnotationListSearchInput
                placeholder="Pesquisar"
                type="search"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              {!search && <img src={Search} alt="search" />}
            </div>
          </AnnotationListSearchContainer>

          <AnnotationsList>
            {annotationsToShow && annotationsToShow.length ? (
              annotationsToShow.map(ann => (
                <AnnotationCard
                  key={ann.id}
                  annotation={ann}
                  show={setShowingAnnotation}
                />
              ))
            ) : (
              <></>
            )}
          </AnnotationsList>
        </>
      ) : (
        <AnnotationViewContainer>
          <AnnotationViewContent>
            <AnnotationViewTitle>{showingAnnotation.title}</AnnotationViewTitle>
            <AnnotationViewDescription>
              {showingAnnotation.description}
            </AnnotationViewDescription>
          </AnnotationViewContent>
          <AnnotationViewFooter>
            <button
              type="button"
              onClick={editShowingAnnotation}
              className="edit"
            >
              <AiOutlineEdit size={18} color="#97a0af" />
            </button>
            <button
              type="button"
              onClick={removeShowingAnnotation}
              className="remove"
            >
              <BsTrash size={18} color="#BB0022" />
            </button>
          </AnnotationViewFooter>
        </AnnotationViewContainer>
      )}
    </AnnotationListMobileContainer>
  );
};

export default AnnotationListMobile;

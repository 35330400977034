import styled from 'styled-components';

const CourseModulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CourseModuleContainer = styled.div`
  border: solid 1px var(--medium-gray);
  border-radius: 5px;
`;

const CourseModuleUpperContent = styled.div`
  background: var(--default-dark-gray);
  color: white;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  gap: 15px;

  &.opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CourseModuleLowerContent = styled.div`
  background: var(--gray-lighter);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const ModuleTitle = styled.span`
  flex-grow: 1;
`;

const ModuleDuration = styled.span`
  white-space: nowrap;
`;

const StartCourseContainer = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StartCourseButton = styled.button`
  border: none;
  background: var(--default-purple);
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  outline: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 5px;
  opacity: 0.9;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

export {
  CourseModulesContainer,
  CourseModuleContainer,
  CourseModuleLowerContent,
  CourseModuleUpperContent,
  ModuleTitle,
  ModuleDuration,
  StartCourseContainer,
  StartCourseButton,
};

import React from 'react';
import ContentLoader from 'react-content-loader';

interface ContentCardSkeletonProps {
  width: number;
  height: number;
}

const ContentCardSkeleton: React.FC<ContentCardSkeletonProps> = ({
  height,
  width,
}) => {
  return (
    <ContentLoader width={width} height={height} backgroundColor="#e3e3e3">
      <rect width={width} height={height} ry="5" />
    </ContentLoader>
  );
};

export default ContentCardSkeleton;

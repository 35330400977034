import React from 'react';
import * as Unicons from '@iconscout/react-unicons';

const getFileExtension = (filename: string) => {
  const regex = /\.[^/.]+$/;
  const match = filename.match(regex);

  return match ? match[0].toLowerCase() : '';
};

const getIconByFileType = (filename: string) => {
  const fileExtension = getFileExtension(filename);
  const iconStyle = { fontSize: 24, color: 'var(--default-purple)' };

  switch (fileExtension) {
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.svg':
    case '.jpe':
    case '.jif':
    case '.jfi':
    case '.gif':
      return React.createElement(Unicons.UilImages, { style: iconStyle });
    case '.doc':
    case '.docx':
    case '.xls':
    case '.xlsx':
    case '.pdf':
    case '.csv':
      return React.createElement(Unicons.UilFile, { style: iconStyle });
    case '.mp4':
    case '.avi':
    case '.mov':
    case '.wmv':
    case '.mkv':
      return React.createElement(Unicons.UilVideo, { style: iconStyle });
    case '.mp3':
    case '.wav':
    case '.aac':
    case '.flac':
    case '.ogg':
    case '.wma':
    case '.pcm':
    case '.wma':
      return React.createElement(Unicons.UilVolume, { style: iconStyle });
    case '.link':
      return React.createElement(Unicons.UilLink, { style: iconStyle });
    default:
      return React.createElement(Unicons.UilDownloadAlt, { style: iconStyle });
  }
};

export default getIconByFileType;

const secondsToTimeText = (seconds: number): string => {
  seconds = seconds || 0;
  const minutes = Math.floor(seconds / 60);

  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingMinutes > 0) {
      return `${hours}h ${remainingMinutes}min`;
    } else {
      return `${hours}h`;
    }
  } else {
    return `${minutes}min`;
  }
};

export { secondsToTimeText };

import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Content from './components/Content';
import { Container } from './components/Container';
import CourseProgressContext from './context/CourseProgressContext';
import { useState } from 'react';
import GlobalContext from './GlobalContext';
import HeaderMobile from './components/HeaderMobile';
import './slick.css';

import usePostHog from './hooks/usePosthog';
import PosthogContext from './context/PosthogContext';
import { UsersnapProvider } from './context/UsersnapContext';

function App() {
  const [courseProgress, setCourseProgress] = useState(0);
  const [courseHasPreview, setCourseHasPreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    const userFarmarcasId = localStorage.getItem('ead/user/id');

    if (userFarmarcasId) {
      posthog?.identify(userFarmarcasId);
    }
  }, [posthog]);

  useEffect(() => {
    const checkIfIsMobile = () => {
      const currentWidth = window.innerWidth;
      setIsMobile(currentWidth <= 930);
    };

    document.body.onresize = checkIfIsMobile;

    checkIfIsMobile();
  }, []);

  return (
    <Router basename="/capacitacao">
      <GlobalContext.Provider value={{ isMobile, showSidebar, setShowSidebar }}>
        <PosthogContext>
          <UsersnapProvider>
            <Container>
              <CourseProgressContext.Provider
                value={{
                  courseProgress,
                  setCourseProgress,
                  courseHasPreview,
                  setCourseHasPreview,
                }}
              >
                {!isMobile ? <Header /> : <HeaderMobile />}
                <Content />
                <div className="footer"></div>
              </CourseProgressContext.Provider>
            </Container>
          </UsersnapProvider>
        </PosthogContext>
      </GlobalContext.Provider>
    </Router>
  );
}

export default App;

import Lesson from '../models/lesson';
import LessonCertificate from '../models/lesson-certificate';
import LessonExam from '../models/lesson-exam';
import { CertificateData, getCertificateData } from '../services/certificate';

const includeExamAndCertificateInLessons = async (
  lessons: (Lesson | LessonExam | LessonCertificate)[],
  courseIsFinished?: boolean,
): Promise<(Lesson | LessonExam | LessonCertificate)[]> => {
  let index = 0;
  let indexesToIncludeExam = [];
  let indexToIncludeCertificate = lessons.length - 1;
  for (let lesson of lessons as Lesson[]) {
    if (lesson.exam && lesson.exam.id) {
      indexesToIncludeExam.push(index);
    }

    index++;
  }

  let numberOfExamsIncluded = 0;
  for (let index of indexesToIncludeExam) {
    const lesson = lessons[index + numberOfExamsIncluded] as Lesson;
    const exam: LessonExam = {
      id: lesson.exam!.id,
      numberOfQuestions: lesson.exam!.numberOfQuestions,
      title: 'Questionário obrigatório',
      type: 'EXAM',
      lessonId: lesson.id,
      courseId: lesson.courseId,
      moduleId: lesson.moduleId,
      isLocked: false,
      alreadyFinished: lesson.exam ? lesson.exam.alreadyFinished : false,
      isLastOfModule: lesson.isLastOfModule,
      isApproved: lesson.exam ? lesson.exam.approved : false,
      numberOfErrors: lesson.exam ? lesson.exam.numberOfErrors : 0,
    };
    lessons.splice(index + 1 + numberOfExamsIncluded, 0, exam);

    numberOfExamsIncluded++;
  }

  let certificateData = {} as CertificateData;
  if (courseIsFinished) {
    try {
      const firstLesson = lessons[0];
      certificateData = await getCertificateData(
        (firstLesson as Lesson).courseId,
      );
    } catch (error) {}
  }

  const certificate: LessonCertificate = {
    type: 'CERTIFICATE',
    title: 'Certificado de Conclusão',
    isLocked: false,
    alreadyFinished: !!(certificateData && certificateData.finish_at),
  };
  lessons.splice(indexToIncludeCertificate, 0, certificate);

  return lessons;
};

export default includeExamAndCertificateInLessons;

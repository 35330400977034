import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import {
  CourseTabContainer,
  CourseTabContent,
  CourseTabsMobileContainer,
  CourseTabHeader,
} from './style';

interface CourseTabProps {
  tab: { id: string; name: string; content: JSX.Element };
}

const CourseTab: React.FC<CourseTabProps> = ({ tab }) => {
  const [opened, setOpened] = useState(false);

  const toogleOpened = () => {
    setOpened(!opened);
  };

  return (
    <CourseTabContainer>
      <CourseTabHeader onClick={toogleOpened}>
        <span>{tab.name}</span>
        {opened ? <BsChevronUp size={16} /> : <BsChevronDown size={16} />}
      </CourseTabHeader>
      {opened && <CourseTabContent>{tab.content}</CourseTabContent>}
    </CourseTabContainer>
  );
};

interface CourseTabsMobileProps {
  tabHeader: { id: string; name: string; shouldShow: boolean }[];
  contentsWithTabId: { id: string; content: JSX.Element }[];
}

const CourseTabsMobile: React.FC<CourseTabsMobileProps> = ({
  tabHeader,
  contentsWithTabId,
}) => {
  const getTabContent = (tabHeaderId: string) => {
    const tabHeaderContent = contentsWithTabId.find(c => c.id === tabHeaderId)!;
    return tabHeaderContent.content;
  };

  return (
    <CourseTabsMobileContainer>
      {tabHeader && tabHeader.length ? (
        tabHeader
          .filter(tabH => tabH.shouldShow)
          .map(tabH => (
            <CourseTab
              key={tabH.id}
              tab={{
                id: tabH.id,
                name: tabH.name,
                content: getTabContent(tabH.id),
              }}
            />
          ))
      ) : (
        <></>
      )}
    </CourseTabsMobileContainer>
  );
};

export default CourseTabsMobile;

import styled from 'styled-components';

const CourseTabsMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -16px;
`;

const CourseTabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CourseTabHeader = styled.div`
  padding: 16px;
  background: #f0f3f6;
  border-radius: 2px;
  cursor: pointer;
  color: #505f79;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;



const CourseTabHeaderGroup = styled.div`
  padding: 16px;
  background: #f0f3f6;
  border-radius: 2px;
  cursor: pointer;
  color: #505f79;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

const CourseTabContent = styled.div`
  padding: 8px 16px;
  color: #8993a4;
`;

export {
  CourseTabsMobileContainer,
  CourseTabContainer,
  CourseTabHeader,
  CourseTabContent,
  CourseTabHeaderGroup
};

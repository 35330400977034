import React, { useContext, useMemo } from 'react';
import Lesson from '../../../../../../models/lesson';
import CourseContext from '../../../../Context';
import { LessonOverviewContainer } from './styles';
import LessonExtraMaterial from '../LessonExtraMaterial';
import  HTMLReactParser  from 'html-react-parser';

const LessonOverview: React.FC = () => {
  const { course, selectedLesson } = useContext(CourseContext);

  const actualSelectedLesson = useMemo(() => {
    return selectedLesson as Lesson;
  }, [selectedLesson]);

  return (
    <LessonOverviewContainer>
      <div>
        {HTMLReactParser(actualSelectedLesson.description || course.description)}
      </div>
      <div>
        <b>Materiais de apoio</b>
      </div>
      <div>
        <LessonExtraMaterial />
      </div>
    </LessonOverviewContainer>
  );
};

export default LessonOverview;

import React from 'react';
import { HeaderLinksContainer } from './style';
import { Link } from 'react-router-dom';
import { BsQuestionCircle } from 'react-icons/bs';
import { AiOutlineAppstore } from 'react-icons/ai';

const HeaderLinks: React.FC = () => {
  const logout = () => {
    Object.keys(localStorage).filter(key => key.toLowerCase().startsWith("ead/")).forEach(key => localStorage.removeItem(key));
    
    window.location.assign(`${process.env.REACT_APP_LOGIN_FARMARCAS}/logout`);
  };

  return (
    <HeaderLinksContainer>
      <Link to="/faq">
        <span>FAQ</span> <BsQuestionCircle size={20} />
      </Link>
      <Link to="/apps">
        <span>Aplicações</span> <AiOutlineAppstore size={20} />
      </Link>
      <Link to="/inovation">
        <span>Inovação</span> <span className="innovation-icon">I</span>
      </Link>
      <button onClick={logout}>Sair</button>
    </HeaderLinksContainer>
  );
};

export default HeaderLinks;

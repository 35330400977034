import React, { useContext, useMemo } from 'react';
import { AiOutlineCheck, AiOutlineLock } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { secondsToTimeText } from '../../../../../../helpers/time';
import Lesson from '../../../../../../models/lesson';
import LessonCertificate from '../../../../../../models/lesson-certificate';
import LessonExam from '../../../../../../models/lesson-exam';
import Module from '../../../../../../models/module';
import CourseContext from '../../../../Context';
import {
  LessonContent,
  LessonDuration,
  LessonFlux,
  LessonFluxCircle,
  LessonFluxLowerBar,
  LessonFluxUpperBar,
  LessonTitle,
  LessonTypeBlock,
  ModuleLessonContainer,
  ModuleLessonsContainer,
} from './styles';
import { ReactComponent as ClipIcon } from '../../../../../../assets/icons/clip.svg';
import { ReactComponent as ExamIcon } from '../../../../../../assets/icons/exam.svg';
import { ReactComponent as CapIcon } from '../../../../../../assets/icons/cap.svg';
import { RiErrorWarningLine } from 'react-icons/ri';

interface ModuleLessonsProps {
  module: Module;
}

interface ModuleLessonProps {
  lesson: Lesson | LessonExam | LessonCertificate;
  module: Module;
}

interface CourseParams {
  lessonId: string;
  moduleId: string;
}

const ModuleLesson: React.FC<ModuleLessonProps> = ({ lesson, module }) => {
  const { selectedLesson, pushLessonUrl, course, userIsApproved } =
    useContext(CourseContext);
  const { lessonId, moduleId } = useParams<CourseParams>();

  const goToLesson = () => {
    if (!lesson.isLocked) {
      if (lesson.type === 'EXAM') {
        const localLesson = lesson as LessonExam;
        pushLessonUrl(
          localLesson.type,
          localLesson.courseId,
          localLesson.moduleId,
          localLesson.lessonId,
          localLesson.id,
        );
      } else if (lesson.type === 'CERTIFICATE') {
        const localLesson = lesson as LessonCertificate;
        pushLessonUrl(localLesson.type, course.id);
      } else {
        const localLesson = lesson as Lesson;
        pushLessonUrl(
          localLesson.type,
          localLesson.courseId,
          localLesson.moduleId,
          localLesson.id,
        );
      }
    }
  };

  const lessonFluxIcon = useMemo(() => {
    if (lesson.isLocked) {
      return <AiOutlineLock size={18} />;
    } else if (
      lesson.type === 'EXAM' &&
      lesson.alreadyFinished &&
      !(lesson as LessonExam).isApproved
    ) {
      return <RiErrorWarningLine />;
    } else if (
      lesson.type !== 'CERTIFICATE' ? lesson.alreadyFinished : userIsApproved
    ) {
      return <AiOutlineCheck size={18} />;
    } else {
      return <></>;
    }
  }, [lesson]);

  const fluxClassName = useMemo(() => {
    if (lesson.isLocked) {
      return 'locked';
    } else if (
      lesson.type === 'EXAM' &&
      lesson.alreadyFinished &&
      !(lesson as LessonExam).isApproved
    ) {
      return 'exam-error';
    } else if (
      lesson.type !== 'CERTIFICATE' ? lesson.alreadyFinished : userIsApproved
    ) {
      return 'finished';
    } else {
      return 'unlocked';
    }
  }, [lesson]);

  const isSelected = useMemo(() => {
    if (selectedLesson.type === 'EXAM' && lesson.type === selectedLesson.type) {
      const localSelectedLesson = selectedLesson as LessonExam;
      const localLesson = lesson as LessonExam;
      return (
        localSelectedLesson.moduleId === localLesson.moduleId &&
        localSelectedLesson.lessonId === localLesson.lessonId &&
        localSelectedLesson.id === localLesson.id
      );
    } else if (
      selectedLesson.type === 'CERTIFICATE' &&
      lesson.type === selectedLesson.type
    ) {
      return true;
    } else if (moduleId && lessonId) {
      const localSelectedLesson = selectedLesson as Lesson;
      const localLesson = lesson as Lesson;
      return (
        localLesson.id === localSelectedLesson.id &&
        localLesson.moduleId === localSelectedLesson.moduleId &&
        localSelectedLesson.courseId === localLesson.courseId
      );
    }
  }, [lesson, selectedLesson]);

  return (
    <ModuleLessonContainer
      className={lesson.isLocked ? 'locked' : ''}
      onClick={goToLesson}
    >
      <LessonFlux>
        <LessonFluxUpperBar
          className={`${fluxClassName} ${
            (lesson as Lesson).isFirstOfModule && lesson.type !== 'EXAM'
              ? 'first'
              : ''
          }`}
        />
        <LessonFluxCircle className={fluxClassName}>
          {lessonFluxIcon}
        </LessonFluxCircle>
        <LessonFluxLowerBar
          className={`${fluxClassName} ${
            ((lesson as Lesson | LessonExam).isLastOfModule &&
              !module.isLastOfCourse &&
              (!(lesson as Lesson).exam || !(lesson as Lesson).exam!.id)) ||
            lesson.type === 'CERTIFICATE'
              ? 'last'
              : ''
          }`}
        />
      </LessonFlux>
      <LessonContent>
        <LessonTitle className={isSelected ? 'selected' : ''}>
          <p className="title">{lesson.title}</p>
          {lesson.type === 'EXAM' &&
          lesson.alreadyFinished &&
          (lesson as LessonExam).numberOfErrors > 0 &&
          !(lesson as LessonExam).isApproved ? (
            <p className="errors">
              {(lesson as LessonExam).numberOfErrors} erro
              {(lesson as LessonExam).numberOfErrors > 1 ? 's' : ''}
            </p>
          ) : (
            <></>
          )}
        </LessonTitle>
        {lesson.type === 'AUDIO' ||
        (lesson.type === 'VIDEO' &&
          !!((lesson as Lesson).extraMaterials || []).length) ? (
          <LessonTypeBlock>
            <ClipIcon />
          </LessonTypeBlock>
        ) : (
          <></>
        )}
        {lesson.type === 'AUDIO' ||
          (lesson.type === 'VIDEO' && (
            <LessonDuration>
              {secondsToTimeText((lesson as Lesson).duration)}
            </LessonDuration>
          ))}
        {lesson.type === 'EXAM' && (
          <LessonTypeBlock>
            <ExamIcon />
          </LessonTypeBlock>
        )}
        {lesson.type === 'CERTIFICATE' && (
          <LessonTypeBlock>
            <CapIcon />
          </LessonTypeBlock>
        )}
      </LessonContent>
    </ModuleLessonContainer>
  );
};

const ModuleLessons: React.FC<ModuleLessonsProps> = ({ module }) => {
  return (
    <ModuleLessonsContainer>
      {module.lessons && module.lessons.length ? (
        module.lessons.map(lesson => (
          <ModuleLesson
            key={(lesson as Lesson | LessonExam).id || 'Certificate'}
            lesson={lesson}
            module={module}
          />
        ))
      ) : (
        <></>
      )}
    </ModuleLessonsContainer>
  );
};

export default ModuleLessons;

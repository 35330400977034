import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { FiPlay } from 'react-icons/fi';
import { secondsToTimeText } from '../../../../helpers/time';
import Lesson from '../../../../models/lesson';
import LessonExam from '../../../../models/lesson-exam';
import Module from '../../../../models/module';
import CourseContext from '../../Context';
import ModuleLessons from './components/ModuleLessons';
import {
  CourseModuleContainer,
  CourseModuleLowerContent,
  CourseModulesContainer,
  CourseModuleUpperContent,
  ModuleDuration,
  ModuleTitle,
  StartCourseButton,
  StartCourseContainer,
} from './styles';

interface CourseModuleProps {
  module: Module;
}

const CourseModules: React.FC = () => {
  const { course, startCourse } = useContext(CourseContext);

  const CourseModule: React.FC<CourseModuleProps> = ({ module }) => {
    const { selectedLesson } = useContext(CourseContext);

    const [opened, setOpened] = useState(false);

    const moduleDuration = useMemo(() => {
      return module.lessons
        .filter(lesson => lesson.type === 'AUDIO' || lesson.type === 'VIDEO')
        .map(lesson => (lesson as Lesson).duration)
        .reduce(
          (prevLessonDuration, currLessonDuration) =>
            prevLessonDuration + currLessonDuration,
        );
    }, [module]);

    const toogleOpened = () => {
      setOpened(!opened);
    };

    useEffect(() => {
      if (module.lessons && module.lessons.length) {
        if (
          module.lessons.some(lesson => {
            if (lesson.type === 'EXAM') {
              const localSelectedLesson = selectedLesson as LessonExam;
              const localLesson = lesson as LessonExam;
              return (
                localLesson.id === localSelectedLesson.id &&
                localLesson.moduleId === localSelectedLesson.moduleId &&
                localLesson.courseId === localSelectedLesson.courseId
              );
            } else if (lesson.type === 'CERTIFICATE') {
              return selectedLesson.type === 'CERTIFICATE';
            } else {
              const localSelectedLesson = selectedLesson as Lesson;
              const localLesson = lesson as Lesson;
              return (
                localLesson.id === localSelectedLesson.id &&
                localLesson.moduleId === localSelectedLesson.moduleId &&
                localLesson.courseId === localSelectedLesson.courseId
              );
            }
          })
        ) {
          if(module.alreadyFinished) {
            setOpened(false);
          } else {
            setOpened(true);
          }
        } else if (
          (module.isFirstOfCourse &&
            (!selectedLesson || !selectedLesson.title)) ||
          module.lessons.includes(selectedLesson)
        ) {
          if(module.alreadyFinished) {
            setOpened(false);
          } else {
            setOpened(true);
          }
        }
      }
    }, [module, selectedLesson]);

    useEffect(() => {
      if(module.isFirstOfCourse) {
        if(!(module.alreadyFinished)) {
          setOpened(true);
        }
      } 
      if (module.alreadyStarted && !(module.alreadyFinished)) {
        setOpened(true);
      }
    }, [module]);

    return (
      <CourseModuleContainer>
        <CourseModuleUpperContent
          onClick={toogleOpened}
          className={opened ? 'opened' : 'closed'}
        >
          <ModuleTitle>
            Módulo {module.position} - {module.title}
          </ModuleTitle>
          <ModuleDuration>~ {secondsToTimeText(moduleDuration)}</ModuleDuration>
          {!opened ? <AiOutlineDown /> : <AiOutlineUp />}
        </CourseModuleUpperContent>
        {opened && (
          <CourseModuleLowerContent>
            {module.isFirstOfCourse && !course.alreadyStarted && (
              <StartCourseContainer>
                <StartCourseButton onClick={startCourse}>
                  <span>Iniciar Curso</span>
                  <FiPlay />
                </StartCourseButton>
              </StartCourseContainer>
            )}

            <ModuleLessons module={module} />
          </CourseModuleLowerContent>
        )}
      </CourseModuleContainer>
    );
  };

  return (
    <CourseModulesContainer>
      {course.modules && course.modules.length ? (
        course.modules.map(module => (
          <CourseModule key={module.id} module={module} />
        ))
      ) : (
        <></>
      )}
    </CourseModulesContainer>
  );
};

export { CourseModules };

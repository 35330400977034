import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CourseCard from '../../../../components/CourseCard';
import CourseCardSkeleton from '../../../../components/CourseCardSkeleton';
import Course from '../../../../models/course';
import { getCourses as getCoursesService } from '../../../../services/course';
import {
  AvailableCoursesContainer,
  AvailableCoursesFooterWrapper,
} from './style';


const AvailableCourses: React.FC = () => {

  const handleBeforeUnload = (e:any) => {
    localStorage.setItem('page', '1');
    e.returnValue = '';
  };

  useEffect(()=>{
    window.addEventListener('beforeunload', handleBeforeUnload);
    const windowHeight = window.innerHeight
    let yPosition = Number(localStorage.getItem('yPositionCourse'));
    if(localStorage.getItem('yPositionCourse') != null){
      window.scrollTo({top: yPosition - windowHeight/2, behavior: 'smooth'})

    }
  })

  const [availableCourses, setAvailableCourses] = useState([] as Course[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(() => {
    const storedPage = localStorage.getItem('page');    
    return storedPage ? parseInt(storedPage, 10) : 1;
  });
  
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    localStorage.setItem('page', page.toString());

    if (page >= 1) {
      (async () => {
        try {
          const localCourses = await getCoursesService({
            limit: page * 8
          });
          setCanLoadMore(localCourses.length >= 8);
          if (localCourses && localCourses.length) {
            if (page === 1) {
              setAvailableCourses(localCourses);
              return;
            }
            setAvailableCourses([...localCourses]);
          } else {
            setShouldShowSkeleton(false);
          }
        } catch (e) {
          toast.error('Erro ao carregar os cursos disponíveis.');
          setShouldShowSkeleton(false);
        }
      })();
    }
  }, [page]);

  const handleLoadMoreCourses = (): void => {
    setPage(page + 1);
    localStorage.removeItem('yPositionCourse');    
  };

  return (
    <AvailableCoursesContainer>
      <h2 className="available-courses-title">Cursos Disponíveis</h2>

      <div className="available-courses-cards">
        {availableCourses && availableCourses.length ? (
          availableCourses.map(course => (
            <CourseCard course={course} key={course.id}/>
          ))
        ) : shouldShowSkeleton ? (
          Array(12)
            .fill('')
            .map((i, index) => <CourseCardSkeleton key={index} />)
        ) : (
          <h4>Não foram encontrados cursos disponíveis.</h4>
        )}
      </div>

      <AvailableCoursesFooterWrapper>
        {canLoadMore && <h4 onClick={handleLoadMoreCourses}>Carregar Mais</h4>}
      </AvailableCoursesFooterWrapper>
    </AvailableCoursesContainer>
  );
};

export default AvailableCourses;

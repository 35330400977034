import React, { useCallback, useRef } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import getErrorMessage from '../../../../helpers/get-error-message';
import ExamFromApi from '../../../../models/from-api/exam';
import ExamAttemptFromApi from '../../../../models/from-api/exam-attempt';
import QuestionFromApi from '../../../../models/from-api/question';
import QuestionAlternativeFromApi from '../../../../models/from-api/question-alternative';
import QuestionAnswerFromApi from '../../../../models/from-api/question-answer';
import {
  getExamAttemptData,
  getExamData,
  getPreviousAnswers,
  startExam,
  getQuestion,
  submitAnswer,
  submitExam,
} from '../../../../services/exam';
import {
  CourseExamContainer,
  ExamInstructions,
  ExamTitle,
  LoadingContainer,
  ExamSubmitButton,
  ExamSubmitButtonContainer,
  QuestionAlternativeContainer,
  QuestionAlternatives,
  QuestionCardContainer,
  QuestionEnunciated,
  QuestionList,
  QuestionNumber,
  ExamResultContainer,
  ExamResultActions,
  QuestionNumberAndIsCorrectTag,
  IsCorrectTag,
} from './styles';
import { RiErrorWarningLine } from 'react-icons/ri';
import { ReactComponent as ArrowNextIcon } from '../../../../assets/icons/arrow-next.svg';
import CourseContext from '../../Context';
import LessonExam from '../../../../models/lesson-exam';
import Swal from 'sweetalert2';

interface CourseExamParams {
  lessonId: string;
  examId: string;
}

interface QuestionCardProps {
  questionNumber: number;
  question: QuestionFromApi;
  isCorrect?: boolean;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  questionNumber,
  question,
  isCorrect,
}) => {
  const { answers, addAnsweredQuestion, examIsFinished } =
    useContext(ExamContext);
  const [isAnswered, setIsAnswered] = useState(false);
  const [examAnswerUserId, setExamAnswerUserId] = useState('');

  const answerQuestion = async (altId: string) => {
    if (!examIsFinished) {
      try {
        await submitAnswer(examAnswerUserId, altId);
        setIsAnswered(true);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao responder a questão. ' + errorMessage);
      }
    }
  };

  useEffect(() => {
    if (question) {
      const foundAnswer = answers.find(
        ans => ans.question_id === question.question_id,
      );
      if (foundAnswer) {
        setExamAnswerUserId(foundAnswer.exam_answer_user_id);
      }

      if (
        answers &&
        answers.length &&
        answers.some(
          ans => ans.answer_id && ans.question_id === question.question_id,
        )
      ) {
        setIsAnswered(true);
      }
    }
  }, [question]);

  useEffect(() => {
    if (isAnswered) {
      addAnsweredQuestion(question.question_id);
    }
  }, [isAnswered]);

  return (
    <QuestionCardContainer>
      <QuestionNumberAndIsCorrectTag>
        <QuestionNumber>Questão {questionNumber}:</QuestionNumber>
        {examIsFinished && (
          <IsCorrectTag className={isCorrect ? 'correct' : 'incorrect'}>
            Resposta {isCorrect ? 'Correta' : 'Incorreta'}
          </IsCorrectTag>
        )}
      </QuestionNumberAndIsCorrectTag>
      <QuestionEnunciated>{question.enunciated}</QuestionEnunciated>
      <QuestionAlternatives>
        {question.alternative && question.alternative.length ? (
          question.alternative.map(alt => (
            <QuestionAlternative
              key={alt.alternative_id}
              questionId={question.question_id}
              alternative={alt}
              answerQuestion={answerQuestion}
              isCorrect={isCorrect}
            />
          ))
        ) : (
          <LoadingContainer>
            <div className="spinner"></div>
          </LoadingContainer>
        )}
      </QuestionAlternatives>
    </QuestionCardContainer>
  );
};

interface QuestionAlternativeProps {
  alternative: QuestionAlternativeFromApi;
  answerQuestion: (alternativeId: string) => void;
  questionId: string;
  isCorrect?: boolean;
}

const QuestionAlternative: React.FC<QuestionAlternativeProps> = ({
  answerQuestion,
  questionId,
  alternative,
  isCorrect,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const { answers, examIsFinished } = useContext(ExamContext);
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      answers &&
      answers.length &&
      answers.some(ans => ans.answer_id === alternative.alternative_id)
    ) {
      setIsSelected(true);
    }
  }, [questionId, answers]);

  useEffect(() => {
    if (input.current) {
      input.current.checked = isSelected;
    }
  }, [input, isSelected]);

  return (
    <QuestionAlternativeContainer
      className={
        isSelected && examIsFinished
          ? isCorrect !== undefined
            ? isCorrect
              ? 'correct'
              : 'incorrect'
            : ''
          : ''
      }
    >
      <input
        type="radio"
        name={`${questionId}`}
        id={`${questionId}_${alternative.alternative_id}`}
        ref={input}
        disabled={examIsFinished}
        onChange={e =>
          e.target.checked
            ? answerQuestion(alternative.alternative_id)
            : undefined
        }
      />
      <label htmlFor={`${questionId}_${alternative.alternative_id}`}>
        {alternative.value}
      </label>
    </QuestionAlternativeContainer>
  );
};

interface IExamContext {
  answers: QuestionAnswerFromApi[];
  examUserId: string;
  addAnsweredQuestion: (questionId: string) => void;
  examIsFinished: boolean;
}

const ExamContext = React.createContext<IExamContext>({
  answers: [] as QuestionAnswerFromApi[],
  examUserId: '',
  addAnsweredQuestion: () => {},
  examIsFinished: false,
});

const CourseExam: React.FC = () => {
  const { lessonId, examId } = useParams<CourseExamParams>();
  const [exam, setExam] = useState({} as ExamFromApi);
  const [attempts, setAttempts] = useState(
    undefined as ExamAttemptFromApi[] | undefined,
  );
  const [actualAttempt, setActualAttempt] = useState({} as ExamAttemptFromApi);
  const [lastAttempt, setLastAttempt] = useState(
    {} as ExamAttemptFromApi | undefined,
  );
  const [approvedExam, setApprovedExam] = useState(false);
  const [questions, setQuestions] = useState([] as QuestionFromApi[]);
  const [answers, setAnswers] = useState([] as QuestionAnswerFromApi[]);
  const [answeredQuestions, setAnsweredQuestions] = useState([] as string[]);
  const [hasAttempts, setHasAttempts] = useState(false);
  const [shouldShowLoading, showLoading] = useState(true);
  const {
    goToNextLesson,
    selectedLesson,
    setCourse,
    course,
    enableNextLesson,
    finishLesson,
    userIsApproved,
  } = useContext(CourseContext);

  const getExam = async () => {
    showLoading(true);

    const localExam = await getExamData(examId);
    setExam(localExam);

    showLoading(false);
  };

  const getAttempts = async () => {
    showLoading(true);

    const localAttempts = await getExamAttemptData(lessonId, examId);
    setAttempts(localAttempts);

    showLoading(false);
  };

  const userHasNewAttempts = (): boolean =>
    exam.attempts > (attempts?.length || 0) ||
    (exam.attempts === attempts?.length && !attempts[0].final_date);

  const getQuestions = async () => {
    showLoading(true);

    const localAnswers = await getPreviousAnswers(
      actualAttempt.exam_user_id || lastAttempt!.exam_user_id,
    );
    setAnswers(localAnswers);

    setQuestions([]);
    const localQuestions = [] as QuestionFromApi[];
    for (let answer of localAnswers) {
      const localQuestion = await getQuestion(answer.question_id);
      localQuestions.push(localQuestion);
    }
    setQuestions(localQuestions);

    showLoading(false);
  };

  const startAttempt = async (forceStart?: boolean) => {
    showLoading(true);

    const localHasAttempts = !!attempts!.length;
    setHasAttempts(localHasAttempts);
    const unfinishedAttempt = attempts!.find(att => !att.final_date);

    if (localHasAttempts) {
      const orderedAttempts = attempts!
        .filter(att => !!att.final_date)
        .sort((att1, att2) =>
          new Date(att1.final_date).getTime() >
          new Date(att2.final_date).getTime()
            ? 1
            : -1,
        );

      const localLastAttempt = orderedAttempts.pop()!;
      setLastAttempt(localLastAttempt);
    }

    if (unfinishedAttempt && unfinishedAttempt.exam_id) {
      setActualAttempt(unfinishedAttempt);
    } else if (forceStart || !localHasAttempts) {
      try {
        if (userIsApproved) {
          Swal.fire({
            title: 'Erro',
            text: 'Não é possível refazer o questionário, pois, você já foi aprovado neste curso!',
            icon: 'error',
          });
        } else {
          const localActualAttempt = await startExam(examId, lessonId);
          setActualAttempt(localActualAttempt);
          setHasAttempts(true);
        }
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar a prova. ' + errorMessage);
      }
    }

    showLoading(false);
  };

  const addAnsweredQuestion = (questionId: string) => {
    if (!answeredQuestions.includes(questionId)) {
      setAnsweredQuestions([...answeredQuestions, questionId]);
    }
  };

  const finishExam = async () => {
    showLoading(true);

    try {
      const result = await submitExam(actualAttempt.exam_user_id);

      await getAttempts();
      setActualAttempt({} as ExamAttemptFromApi);
      selectedLesson.alreadyFinished = true;
      
      if (result.approved) {
        enableNextLesson();
      }

      finishLesson(true);
      
      toast.success('Prova finalizada!', {
        onOpen: () => setCourse({ ...course }),
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(errorMessage);
    }

    showLoading(false);
  };

  const allQuestionsAnswered = useMemo(() => {
    return questions.every(question =>
      answeredQuestions.includes(question.question_id),
    );
  }, [answeredQuestions, questions]);

  useEffect(() => {
    if (examId) {
      getExam();
    }
  }, [examId]);

  useEffect(() => {
    if (exam && exam.exam_id) {
      getAttempts();
    }
  }, [exam]);

  useEffect(() => {
    if (attempts && attempts instanceof Array) {
      startAttempt();
    }
  }, [attempts]);

  useEffect(() => {
    if (
      (actualAttempt && actualAttempt.exam_user_id) ||
      (lastAttempt && lastAttempt.exam_user_id)
    ) {
      getQuestions();
    }
  }, [actualAttempt, lastAttempt]);

  useEffect(() => {
    if (answers && answers.length) {
      const localAnsweredQuestions = answers.map(ans => ans.question_id);
      setAnsweredQuestions(localAnsweredQuestions);
    }
  }, [answers]);

  useEffect(() => {
    lastAttempt &&
    questions.length &&
    lastAttempt.result / questions.length >= 0.8
      ? setApprovedExam(true)
      : setApprovedExam(false);

    if (
      lastAttempt &&
      !(selectedLesson as LessonExam).isApproved &&
      lastAttempt.result > 0 &&
      questions.length &&
      approvedExam
    ) {
      (selectedLesson as LessonExam).isApproved = true;
    }
  }, [approvedExam, lastAttempt, questions.length, selectedLesson]);

  useEffect(() => {
    if (
      lastAttempt &&
      lastAttempt.exam_user_id &&
      questions &&
      questions.length &&
      questions.length > lastAttempt.result
    ) {
      const numberOfQuestions = (exam?.amount_questions || [])
        .map(amt => amt.amount)
        .reduce((a, b) => a + b);
      (selectedLesson as LessonExam).numberOfErrors =
        numberOfQuestions - lastAttempt.result;

      setCourse({ ...course });
    }
  }, [lastAttempt, questions, exam]);

  return (
    <ExamContext.Provider
      value={{
        answers,
        examUserId: actualAttempt.exam_user_id,
        addAnsweredQuestion,
        examIsFinished: !actualAttempt || !actualAttempt.exam_user_id,
      }}
    >
      {!shouldShowLoading ? (
        <CourseExamContainer>
          <ExamTitle>{exam.title}</ExamTitle>
          <ExamInstructions>
            <strong>Instruções: </strong>
            {exam.instructions}
          </ExamInstructions>

          {hasAttempts && (!actualAttempt || !actualAttempt.exam_user_id) ? (
            <ExamResultContainer>
              <RiErrorWarningLine size={36} />
              <h4>Resultados</h4>
              <p>
                {lastAttempt!.result || 'Nenhuma'} Resposta
                {lastAttempt!.result > 1 ? 's' : ''} certa
                {lastAttempt!.result > 1 ? 's' : ''} /{' '}
                {questions.length - lastAttempt!.result || 'Nenhuma'} Resposta
                {questions.length - lastAttempt!.result > 1 ? 's' : ''} errada
                {questions.length - lastAttempt!.result > 1 ? 's' : ''}
              </p>
              <ExamResultActions>
                <button
                  className="restart"
                  onClick={() => startAttempt(true)}
                  disabled={
                    !questions.length ||userIsApproved || approvedExam || !userHasNewAttempts()
                  }
                >
                  Refazer o questionário
                </button>
                <button onClick={goToNextLesson} className="go-next">
                  <span>ou, ir para próxima aula</span> <ArrowNextIcon />
                </button>
              </ExamResultActions>
            </ExamResultContainer>
          ) : (
            <></>
          )}

          <QuestionList>
            {questions && questions.length ? (
              questions.map((question, index) => (
                <QuestionCard
                  key={question.question_id}
                  questionNumber={index + 1}
                  question={question}
                  isCorrect={
                    answers && answers.length
                      ? !!answers.find(
                          a =>
                            a.correct_answer &&
                            a.question_id === question.question_id,
                        )
                      : undefined
                  }
                />
              ))
            ) : (
              <LoadingContainer>
                <div className="spinner"></div>
              </LoadingContainer>
            )}
          </QuestionList>

          {actualAttempt && actualAttempt.exam_user_id && (
            <ExamSubmitButtonContainer>
              <ExamSubmitButton
                disabled={!allQuestionsAnswered}
                onClick={finishExam}
              >
                Enviar Respostas
              </ExamSubmitButton>
            </ExamSubmitButtonContainer>
          )}
        </CourseExamContainer>
      ) : (
        <LoadingContainer>
          <div className="spinner"></div>
        </LoadingContainer>
      )}
    </ExamContext.Provider>
  );
};

export default CourseExam;

import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import LessonCard from '../LessonCard';
import {
  TrailCardContainer,
  TrailCardLessons,
  TrailCardToogleButton,
  TrailCardContent,
  TrailCardCategory,
  TrailCardTitle,
  TrailCardUpperContent,
  TrailCardLowerContent,
} from './style';

interface TrailCardProps {
  course: any;
}

const TrailCard: React.FC<TrailCardProps> = ({ course }) => {
  const [shouldShowLessons, setShouldShowLessons] = useState(false);

  const toogleLessons = () => {
    setShouldShowLessons(!shouldShowLessons);
  };

  return (
    <TrailCardContainer>
      <TrailCardUpperContent>
        <TrailCardToogleButton
          className={`${shouldShowLessons ? 'up' : 'down'}`}
          onClick={toogleLessons}
        >
          <BsChevronDown size={18} />
        </TrailCardToogleButton>
        <TrailCardContent>
          <TrailCardCategory>{course.category_title}</TrailCardCategory>
          <TrailCardTitle>{course.trail_name}</TrailCardTitle>
        </TrailCardContent>
      </TrailCardUpperContent>

      {shouldShowLessons && (
        <TrailCardLowerContent>
          <TrailCardLessons>
            {course.contents && course.contents.length ? (
              course.contents.map((content: any) => (
                <LessonCard
                  key={content.content_id}
                  courseId={course.trail_id}
                  lesson={content}
                />
              ))
            ) : (
              <></>
            )}
          </TrailCardLessons>
        </TrailCardLowerContent>
      )}
    </TrailCardContainer>
  );
};

export default TrailCard;

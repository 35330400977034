import convertToLesson from '../../helpers/converters/convert-lesson';
import httpClient from '../../http-client';
import LessonFromApi from '../../models/from-api/lesson';
import Lesson from '../../models/lesson';

const getLibraryContent = async (params?: {
  limit?: number;
  offset?: number;
  group?: number;
  is_active?: boolean;
}) => {
  let contents = (
    await httpClient.get<LessonFromApi[]>('/contents', {
      params: {
        is_library: true,
        ...params,
      },
    })
  ).data;

  return contents;
};

const getContent = async (contentId: string) => {
  let content = (await httpClient.get<LessonFromApi>(`/contents/${contentId}`))
    .data;

  let convertedContent = {} as Lesson;
  if (content && content.content_id) {
    convertedContent = await convertToLesson('', '', content, true, true, {
      content_id: content.content_id,
      content_view: content.user && content?.user.content_view,
      start_at: content.user && content.user.start_at,
      finish_at: content.user && content.user.finish_at
    });
  }

  return convertedContent;
};

const updateVideoProgress = async (
  contentId: string,
  content_view: number | null,
) => {
  if (!contentId) {
    return {};
  }

  return (
    await httpClient.patch(`/contents-user/${contentId}`, {
      content_view,
    })
  ).data;
};

const startPreview = async (contentId: string) => {
  if (!contentId) {
    return {};
  }

  return (
    await httpClient.post(`/contents-user`, {
      content_id: contentId,
      start_at: new Date(),
    })
  ).data;
};

const finishPreview = async (contentId: string) => {
  if (!contentId) {
    return {};
  }

  return (
    await httpClient.put(`/contents-user/${contentId}/finish`, {
      finish_at: new Date(),
    })
  ).data;
};

const searchVideoLibrary = async (param: string) => {
  if (!param || param === '') {
    return {};
  }

  return (await httpClient.get(`/contents?search=${param}&is_library=true`))
    .data;
};

export {
  getContent,
  getLibraryContent,
  updateVideoProgress,
  startPreview,
  finishPreview,
  searchVideoLibrary,
};

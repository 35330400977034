import styled from 'styled-components';

const ModuleLessonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModuleLessonContainer = styled.div`
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;

  &:not(:last-child) {
    border-bottom: solid 1px var(--medium-gray);
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &.locked {
    cursor: not-allowed;
  }

  display: flex;
  align-items: stretch;
`;

const LessonFlux = styled.div`
  padding: 0 5px;
  min-height: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LessonFluxCircle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px;

  &.exam-error {
    background: #DF0028;
    color: white;
  }

  &.unlocked {
    border-color: var(--default-purple);
  }

  &.locked {
    color: var(--medium-gray);
    border: solid 2px var(--medium-gray);
  }

  &.finished {
    background: var(--default-purple);
    border-color: var(--default-purple);
    color: white;
  }
`;

const LessonFluxUpperBar = styled.span`
  border: solid 1px;
  flex-grow: 1;
  border-color: var(--medium-gray);
  
  &.exam-error {
    border-color: #DF0028;
  }

  &.unlocked {
    border-color: var(--default-purple);
  }

  &.locked {
    border-color: var(--medium-gray);
  }

  &.finished {
    border-color: var(--default-purple);
  }

  &.first {
    visibility: hidden;
  }
`;

const LessonFluxLowerBar = styled.span`
  border: solid 1px;
  flex-grow: 1;
  border-color: var(--medium-gray);

  &.exam-error {
    border-color: #DF0028;
  }

  &.locked {
    border: solid 1px var(--medium-gray);
  }

  &.finished {
    border-color: var(--default-purple);
  }

  &.last {
    visibility: hidden;
  }
`;

const LessonContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
`;

const LessonDuration = styled.div`
  color: var(--darker-gray);
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
`;

const LessonTitle = styled.p`
  flex-grow: 1;

  & > p.errors {
    color: #DF0028;
    font-size: 0.75em;
  }

  &.selected {
    font-weight: bold;
  }
`;

const LessonTypeBlock = styled.span`
  padding: 7px;
  border-radius: 5px;
  background: var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: #97a0af;
  }
`;

export {
  ModuleLessonsContainer,
  ModuleLessonContainer,
  LessonFlux,
  LessonContent,
  LessonDuration,
  LessonTitle,
  LessonFluxCircle,
  LessonFluxLowerBar,
  LessonFluxUpperBar,
  LessonTypeBlock,
};

import React, { useMemo } from 'react';
import { HeaderContainer } from './style';
import Menu from '../Menu';
import HeaderLinks from '../HeaderLinks';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';
import ProgressBar from '../ProgressBar';
import SearchInput from './components/SearchInput';
import SearchInputCourses from './components/SearchInputCourses';
import SearchInputLibrary from './components/SearchInputLibrary';
import { useContext } from 'react';
import CourseProgressContext from '../../context/CourseProgressContext';

interface Params {
  courseId: string;
}

const Header: React.FC = () => {
  const { courseId } = useParams<Params>();
  const history = useHistory();
  const location = useLocation();
  const { courseProgress, courseHasPreview } = useContext(
    CourseProgressContext,
  );

  const isWatchingAnyLesson = useMemo(() => {
    return (
      location.pathname.match(
        /course\/.[^/]+\/module\/.[^/]+\/lesson\/.[^/]+/,
      ) || !!new URLSearchParams(location.search).get('isCertificate')
    );
  }, [location]);

  const userName = localStorage.getItem('ead/user/name');

  const handleGoBack = () => {
    if (isWatchingAnyLesson && courseHasPreview) {
      const newPath = history.location.pathname.split(
        /\/module\/.[^/]+\/lesson\/.[^/]+/,
      );
      if (newPath && newPath.length) {
        history.push(newPath[0]);
      } else if (courseId) {
        history.push(`/course/${courseId}`);
      }
    } else {
      if(isWatchingAnyLesson){
        history.push('/home');
      }else{
        history.goBack();
        const regex = /^(?!\/(?:video-library|courses)$).*/;
        if(!regex.test(location.pathname)){
          localStorage.setItem('numberOfContentsToShow', '12');
          localStorage.removeItem('yPositionCourse');
          localStorage.removeItem('yPositionContent');
          localStorage.removeItem('page');
        }
      }
    }
  };

  return (
    <HeaderContainer>
      {!isWatchingAnyLesson && (
        <div className="superior-header">
          <div className="content">
            <div className="left-content-group">
              <Link to="/home" className="logo">
                <img
                  src={`${process.env.PUBLIC_URL}/header-logo.svg`}
                  alt="logo"
                />
              </Link>

              <Menu />
            </div>

            <HeaderLinks />
          </div>
        </div>
      )}

      <div className="section-header">
        <div className="content">
          {!location.pathname || location.pathname === '/home' ? (
            <span className="greetings">Olá, {userName}!</span>
          ) : (
            <button
              onClick={() => {
                handleGoBack();
              }}
              className="go-back-button"
            >
              <BsArrowLeftShort size={24} color="#FFF" />
              {isWatchingAnyLesson ? 'Sair' : 'Voltar'}
            </button>
          )}

          <div>
            {(!location.pathname || location.pathname === '/home') && <SearchInput />}
              
            {(location.pathname === '/courses' || location.pathname === '/my-courses') && <SearchInputCourses />}

            {location.pathname === '/video-library' && <SearchInputLibrary />}

            {isWatchingAnyLesson ? (
              <ProgressBar completionPercentage={courseProgress} />
            ) : (
              <span className="badges"></span>
            )}
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;

import styled from 'styled-components';

export const RecommendedCoursesContainer = styled.div`
  .recommended-courses-title {
    font-size: 24px;
    color: var(--default-font-color);
    margin: 25px 0;

    @media (max-width: 640px) {
      font-size: 16px;
    }
  }
  .recommended-courses-cards {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;

    @media (max-width: 1300px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 930px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .courses-not-found {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
    }
    & > div {
      margin: 1px;
    }
  }
  .button-show-all-courses-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    button {
      background-color: var(--default-purple);
      color: white;
      text-decoration: none;
      width: 170px;
      height: 40px;
      border-radius: 5px;
      margin: 25px 0;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .show-all.mobile {
    margin: 0;
    box-sizing: border-box;
    font-size: var(--font-medium);
    padding: 4px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px;
    background: none;
    color: var(--default-purple);
    font-weight: bold;
  }
`;

const OngoingCoursesSliderSettings = {
  speed: 500,
  arrows: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.9,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.6,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.3,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1.15,
      },
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1.05,
      },
    },
  ],
};

const RecommendedCoursesSliderSettings = {
  speed: 500,
  arrows: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.9,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.6,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.3,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1.15,
      },
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1.05,
      },
    },
  ],
};

const MostViewedCoursesSliderSettings = {
  speed: 500,
  arrows: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 3.3,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2.6,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.8,
      },
    },
  ],
};

const VideoLibrarySliderSettings = {
  speed: 500,
  arrows: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.9,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.6,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.3,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1.15,
      },
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1.05,
      },
    },
  ],
};

export {
  OngoingCoursesSliderSettings,
  RecommendedCoursesSliderSettings,
  MostViewedCoursesSliderSettings,
  VideoLibrarySliderSettings,
};

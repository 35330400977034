import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .course-progress-bar {
    flex-grow: 1;
    height: 15px;
    border: solid 1px #d3d8dd;
    background-color: #fff;
    border-radius: 2px;

    .progress {
      background-color: #ffb905;
      height: 100%;
    }
  }

  .course-progress-percentage {
    margin-left: 10px;
    font-size: var(--font-medium);
    font-weight: bold;
    color: #fff;

    &.darken-progress {
      color: #505F79
    }
  }

  padding: 5px;
`;

export { ProgressBarContainer };

import styled from 'styled-components';

export const MenuContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 930px) {
    display: grid;
    grid-template-columns: 1fr;
  }

  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7A869A;
    min-width: 120px;
    height: 50px;

    .external-link-icon {
      margin-left: 0.5rem;
    }

    @media (max-width: 400px) {
      width: 100%;
    }
  }

  .external-link{
    padding: 0 0.5rem;
  }

  a:hover,
  a.active {
    background-color: #161B22;
    color: white;
  }
`;
import React from 'react';

interface IMyCoursesContext {
  refreshOngoingCourses: () => Promise<void>;
  refreshFavoriteCourses: () => Promise<void>;
}

const MyCoursesContext = React.createContext<IMyCoursesContext>({
  refreshFavoriteCourses: async () => {},
  refreshOngoingCourses: async () => {},
});

export default MyCoursesContext;

import React, { useEffect } from 'react';
import RecommendedCourses from './components/RecommendedCourses';
import VideoLibrary from './components/VideoLibrary';
import { HomeContainer } from './style';
import OngoingCourses from './components/OngoingCourses';
import usePostHog from '../../hooks/usePosthog';

const Home: React.FC = () => {
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture('Capacitação - Home');
  }, [posthog]);

  return (
    <HomeContainer>
      <div
        style={{
          background: 'white',
          borderRadius: '5px',
          paddingTop: '5px',
          paddingBottom: '5px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
        className="content"
      >
        <OngoingCourses />

        <RecommendedCourses />

        <VideoLibrary />
      </div>
    </HomeContainer>
  );
};

export default Home;

import httpClient from "../../http-client"

const addBookMark = async (trailId: string) => {
  const addBookMarkResponse = (await httpClient.post(`/book-marks`, {
    trail_id: trailId
  })).data;

  return addBookMarkResponse;
}

const removeBookMark = async (trailId: string) => {
  const removeBookMarkResponse = (await httpClient.delete(`/book-marks/${trailId}`)).data;

  return removeBookMarkResponse
}

export {
  addBookMark,
  removeBookMark
}
import styled from 'styled-components';

export const ContentCardContainer = styled.div`
  a {
    text-decoration: none;
    width: 100%;

    .content-card {
      width: 300px;
      height: 370px;
      border-radius: 5px;
      border: 1px solid #d3d8dd;
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
    }

    .group {
      width: 38px;
      height: 24px;
      color: #FFFFFF;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin-top: 1px;
      padding-top: 4px;
      border-radius: 12px;
      margin-right: 15px;
      margin-left: auto;
      margin-top: -14px;
    }

    .trainning {
      background-color: #0F6BFF;
      width: 84px;
    }

    .lives {
      background-color: #DF0028;
    }
    
    .knowledge {
      width: 142px;
      background-color: #FFB905;
      color: #253858;
    }

  .content-img-container {
    height: 175px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    svg {
      width: 25px;
      height: 25px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;

export const ContentCardInfo = styled.div`
    flex-grow: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

  .category,
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .category {
    font-size: 14px;
    font-weight: bold;
    color: var(--default-purple);
  }

  .title {
    width: 100%;
    color: var(--default-font-color);
    font-weight: bold;
    font-size: 16px;
  }

    h6 {
      color: var(--default-grey-color);
      font-size: var(--font-small);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    h5 {
      font-size: var(--font-small);
      color: var(--default-grey-color);
    }

  }
  .author,
  .duration,
  .target_group,
  .publish {
    color: #5b6981;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    span {
      color: #5b6981;
      font-size: var(--font-small);
    }
  }
`;

import axios from 'axios';
import { toast } from 'react-toastify';
import { login } from '../services/login';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      if (!localStorage.getItem('core/auth/token')) {
        window.location.href = `${process.env.REACT_APP_LOGIN_FARMARCAS}?redirect_uri=${process.env.PUBLIC_URL}`;
      } else {
        const loginResponse = await login(
          localStorage.getItem('core/auth/token') || '',
        );

        localStorage.setItem(
          'ead/api/token',
          loginResponse.api_token,
        );
        localStorage.setItem(
          'ead/user/name',
          loginResponse.user.name,
        );

        toast.success(`Bem vindo ${loginResponse.user.name}!`);

        window.location.reload();
      }
    }

    return Promise.reject(error);
  },
);

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('ead/api/token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default httpClient;

import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -100%;
  left: -100%;
  z-index: -1;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase !important;
    font-weight: 700 !important;
    line-height: 1 !important;
    letter-spacing: 0.02em !important;
  }

  h1 {
    font-size: 3.5rem !important;
  }

  h2 {
    font-size: 2.5rem !important;
  }

  p {
    font-size: 1rem !important;
    letter-spacing: 0.08rem !important;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

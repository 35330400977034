import React, { useState, useCallback, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import { VscChromeClose } from 'react-icons/vsc';
import useDebounce from '../../../../hooks/useDebounce';
import { Container, SearchResults, SearchResultItemWrapper } from './styles';
import { searchCourses } from '../../../../services/course';
import { searchVideoLibrary } from '../../../../services/content';
import { useHistory } from 'react-router-dom';
import Course from '../../../../models/course';
import { useRef } from 'react';
import  HTMLReactParser  from 'html-react-parser';

const SearchResultItem = ({ itemData }: any) => {
  const history = useHistory();

  const { thumbnail, name, title, description, trail_id, content_id } = itemData || {};
  let is_Trail = trail_id != undefined ? true : false;
  let final_id = trail_id ?? content_id;

  const goToCourse = (id: string) => {
    if(is_Trail){
      history.push(`/course/${id}`);
    } else {
      history.push(`/video-library/${id}`);
    }
  };

  return (
    <SearchResultItemWrapper
      hasThumb={!!thumbnail}
      onClick={() => goToCourse(final_id)}
    >
      {!!thumbnail && (
        <div className="resultThumb">
          <img src={thumbnail} alt={name || title} />
        </div>
      )}
      <div className="data">
        <p className="name">{name || title}</p>
        <p className="description">{HTMLReactParser(description)}</p>
      </div>
    </SearchResultItemWrapper>
  );
};

const SearchInput = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Course[]>([]);
  const debouncedSearchParam = useDebounce(searchParam);
  const [isSearching, setSearching] = useState<boolean>(false);
  const searchInput = useRef<HTMLInputElement>(null);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value);
  };

  const searchRequest = useCallback(async () => {
    if (debouncedSearchParam !== '') {
      setSearching(true);

      const courses = searchCourses(debouncedSearchParam);
      const contents = searchVideoLibrary(debouncedSearchParam);
      Promise.all([courses, contents])
        .then(([firstResponse, secondResponse]) => {
          const response = [...firstResponse, ...secondResponse];
          setSearchResults(response);
        })
        .catch(error => {
          console.error("Erro nas requisições: ", error);
        });
    }
    
    setSearching(false);
  }, [debouncedSearchParam]);

  useEffect(() => {
    if (debouncedSearchParam !== '') {
      searchRequest();
    }
  }, [debouncedSearchParam]);

  const toggleInput = () => {
    setExpanded(!expanded);

    if (searchInput.current) {
      searchInput.current.focus();
    }
  };

  useEffect(() => {
    if (!expanded) {
      setSearchParam('');
      setSearchResults([]);
      setSearching(false);
    }
  }, [expanded]);

  return (
    <Container onClick={toggleInput} expanded={expanded}>
      <input
        type="text"
        value={searchParam}
        onChange={handleChangeSearch}
        placeholder="Buscar"
        required={true}
        ref={searchInput}
        style={{ width: '100%' }}
      />
      <button>{!expanded ? <BsSearch /> : <VscChromeClose />}</button>
      {expanded && debouncedSearchParam !== '' && (
        <SearchResults>
          <>
            {searchResults.length >= 1 ? (
              <>
                {searchResults.map((result: any) => (
                  <SearchResultItem itemData={result} />
                ))}
              </>
            ) : !isSearching && searchResults.length === 0 ? (
              <li className="noResults">
                Nenhum resultado para a busca "{searchParam}"
              </li>
            ) : (
              <li className="noResults">Buscando por "{searchParam}"</li>
            )}
          </>
        </SearchResults>
      )}
    </Container>
  );
};

export default SearchInput;

import type { PostHog } from 'posthog-js';
import { isProduction } from '../helpers/environment';

declare let posthog: PostHog | undefined;

export default function usePostHog(): PostHog | undefined {
  if(!isProduction()) {
    return undefined;
  }

  return posthog;
}

import styled from 'styled-components';

const TrailCardContainer = styled.div`
  width: 100%;
  padding: 10px;
  border: solid 1px #d3d8dd;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const TrailCardToogleButton = styled.button`
  margin-right: 15px;
  border: none;
  outline: none;
  background: none;
  padding: 5px;
  cursor: pointer;

  @keyframes turn-up {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  @keyframes turn-down {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  &.up {
    animation: turn-up 0.5s;
    transform: rotate(180deg);
  }

  &.down {
    animation: turn-down 0.5s;
    transform: rotate(0deg);
  }
`;

const TrailCardContent = styled.div`
  width: 100%;
`;

const TrailCardCategory = styled.p`
  color: var(--default-purple);
  font-size: var(--font-small);
  font-weight: bold;
`;

const TrailCardTitle = styled.h2`
  margin: 3px 0;
  font-size: var(--font-large);
`;

const TrailCardUpperContent = styled.div`
  width: 100%;
  margin-bottom: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TrailCardLowerContent = styled.div`
  width: 100%;
  margin-left: 3%;
`;

const TrailCardLessons = styled.div``;

export {
  TrailCardContainer,
  TrailCardContent,
  TrailCardToogleButton,
  TrailCardLessons,
  TrailCardCategory,
  TrailCardTitle,
  TrailCardUpperContent,
  TrailCardLowerContent,
};

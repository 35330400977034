import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import {
  AiOutlineClose,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import Lesson from '../../../../models/lesson';
import LessonExam from '../../../../models/lesson-exam';
import Module from '../../../../models/module';
import CourseContext from '../../Context';
import ModuleLessonsMobile from './components/ModuleLessonsMobile';
import {
  CourseModuleContainer,
  CourseModulesMobileContentWrapper,
  CourseModuleContent,
  CourseModulesContainer,
  CourseModulesMobileCloseButton,
  CourseModulesMobileContainer,
  CourseModuleTab,
  UserGreetings,
} from './styles';

const hideModulesSidebar = () => {
  const modulesSidebar = document.getElementById('modules-sidebar');
  if (modulesSidebar) {
    modulesSidebar.classList.replace('enter', 'leave');
  }
};

interface CourseModuleProps {
  module: Module;
}

const CourseModule: React.FC<CourseModuleProps> = ({ module }) => {
  const [opened, setOpened] = useState(false);
  const { selectedLesson } = useContext(CourseContext);

  const toogleOpened = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    if (module.lessons && module.lessons.length) {
      if (
        module.lessons.some(lesson => {
          if (lesson.type === 'EXAM') {
            const localSelectedLesson = selectedLesson as LessonExam;
            const localLesson = lesson as LessonExam;
            return (
              localLesson.id === localSelectedLesson.id &&
              localLesson.moduleId === localSelectedLesson.moduleId &&
              localLesson.courseId === localSelectedLesson.courseId &&
              localLesson.id === localSelectedLesson.id
            );
          } else if (lesson.type === 'CERTIFICATE') {
            return selectedLesson.type === 'CERTIFICATE';
          } else {
            const localSelectedLesson = selectedLesson as Lesson;
            const localLesson = lesson as Lesson;
            return (
              localLesson.id === localSelectedLesson.id &&
              localLesson.moduleId === localSelectedLesson.moduleId &&
              localLesson.courseId === localSelectedLesson.courseId
            );
          }
        })
      ) {
        setOpened(true);
      } else if (
        (module.isFirstOfCourse &&
          (!selectedLesson || !selectedLesson.title)) ||
        module.lessons.includes(selectedLesson)
      ) {
        setOpened(true);
      }
    }
  }, [module, selectedLesson]);

  return (
    <CourseModuleContainer>
      <CourseModuleTab
        onClick={toogleOpened}
        className={opened ? 'opened' : ''}
      >
        <div className="icon-container">
          {opened ? (
            <AiOutlineMinusCircle size={24} />
          ) : (
            <AiOutlinePlusCircle size={24} />
          )}
        </div>
        <span>{module.title}</span>
      </CourseModuleTab>
      {opened && (
        <CourseModuleContent>
          <ModuleLessonsMobile module={module} />
        </CourseModuleContent>
      )}
    </CourseModuleContainer>
  );
};

const CourseModulesMobile: React.FC = () => {
  const location = useLocation();
  const { course } = useContext(CourseContext);

  const userName = localStorage.getItem('ead/user/name');

  useEffect(() => {
    hideModulesSidebar();
  }, [location.pathname]);

  return (
    <CourseModulesMobileContainer id="modules-sidebar" className={`leave`}>
      <div className="backdrop enter" onClick={hideModulesSidebar} />
      <CourseModulesMobileCloseButton onClick={hideModulesSidebar}>
        <AiOutlineClose size={22} />
      </CourseModulesMobileCloseButton>
      <CourseModulesMobileContentWrapper>
        <UserGreetings>Olá, {userName}</UserGreetings>

        <CourseModulesContainer>
          {course.modules?.length &&
            course.modules.map(mod => (
              <CourseModule key={mod.id} module={mod} />
            ))}
        </CourseModulesContainer>
      </CourseModulesMobileContentWrapper>
    </CourseModulesMobileContainer>
  );
};

export default CourseModulesMobile;

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const formatTimeFromDB = (timestamp: string) => {
  let month = format(parseISO(timestamp), 'MMMM', { locale: ptBR });
  let year = format(parseISO(timestamp), 'yyyy');

  month = month.charAt(0).toUpperCase() + month.slice(1);

  return `${month} de ${year}`;
};

export { formatTimeFromDB };

import LessonFromApi from '../../models/from-api/lesson';
import LessonUserFromApi from '../../models/from-api/lesson-user';
import Lesson from '../../models/lesson';
import { getExamAttemptData, getExamData } from '../../services/exam';

const convertToLesson = async (
  courseId: string,
  moduleId: string,
  lesson: LessonFromApi,
  isFirst: boolean,
  isLast: boolean,
  lessonUser?: LessonUserFromApi,
): Promise<Lesson> => {
  let exam:
    | {
        id: string;
        numberOfQuestions: number;
        alreadyFinished: boolean;
        numberOfErrors: number;
        approved: boolean;
      }
    | undefined;
  if (lesson.exam && lesson.exam.exam_id) {
    const localExam = await getExamData(lesson.exam.exam_id);
    const localExamAttempts = await getExamAttemptData(
      lesson.content_id,
      lesson.exam.exam_id,
    );
    const numberOfQuestions = localExam.amount_questions
      .map(amount => amount.amount)
      .reduce((prev, curr) => prev + curr);
    exam = {
      id: localExam.exam_id,
      numberOfQuestions: numberOfQuestions,
      alreadyFinished: localExamAttempts.some(attempt => !!attempt.final_date),
      numberOfErrors:
        lessonUser && lessonUser.exam ? lessonUser.exam.wrong_answers || 0 : 0,
      approved:
        lessonUser &&
        lessonUser.exam &&
        lessonUser.exam.result > 0 &&
        lessonUser.exam.result >= numberOfQuestions * 0.8
          ? true
          : false,
    };
  }
  
  return {
    id: lesson.content_id,
    description: lesson.description,
    duration: lesson.duration,
    position: lesson.position,
    reference: lesson.reference,
    title: lesson.title,
    type: lesson.type,
    thumbnail: lesson.thumbnail || '',
    courseId: courseId,
    moduleId: moduleId,
    alreadyStarted: !!lessonUser && !!lessonUser.start_at,
    alreadyFinished: !!lessonUser && !!lessonUser.finish_at,
    content_view: lessonUser?.content_view || null,
    isFirstOfModule: isFirst,
    isLastOfModule: isLast,
    isLocked: false,
    extra_materials: lesson.extra_materials,
    extraMaterials:
      lesson.extra_material && lesson.extra_material.length
        ? lesson.extra_material.map(em => ({
            id: em.extra_material_id,
            name: em.name,
            reference: em.reference,
            info: {
              title: em.info.title
            }
          }))
        : [],
    exam: exam,
    categories: lesson.categories,
    authors: lesson.authors,
    createdAt: lesson.created_at,
    target_group: lesson.content_target_group,
  };
};

export default convertToLesson;

import React from 'react';
import ContentLoader from 'react-content-loader';

const CourseCardWithoutImageSkeleton = () => {
  return (
    <ContentLoader
      style={{ margin: '6px' }}
      width={300}
      height={150}
      backgroundColor="#e3e3e3"
    >
      <rect width="300" height="3" rx="5" ry="5" />
      <rect height="150" width="3" rx="5" ry="5" />
      <rect width="300" height="3" y="146" rx="5" ry="5" />
      <rect height="150" width="3" x="296" rx="5" ry="5" />

      <rect height="25" width="280" x="10" y="10" rx="5" ry="5" />
      <rect height="40" width="280" x="10" y="45" rx="5" ry="5" />
      <rect height="20" width="280" x="10" y="93" rx="5" ry="5" />
      <rect height="25" width="280" x="10" y="118" rx="5" ry="5" />
    </ContentLoader>
  );
};

export default CourseCardWithoutImageSkeleton;

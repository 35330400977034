import styled, { css } from 'styled-components';

interface ISearchInput {
  expanded: boolean;
}

interface ISearchResultItem {
  hasThumb: boolean;
}

export const Container = styled.div<ISearchInput>`
  ${({ expanded }) => css`
    position: relative;
    display: flex;
    align-items: center;
    background-color: ${expanded ? 'white' : 'rgba(0, 0, 0, .2)'};
    padding: 8px 16px;
    border: ${expanded ? `2px solid var(--default-purple)` : 'none'};
    border-radius: 16px;
    cursor: pointer;

    &:hover {
      background-color: ${expanded ? 'white' : 'rgba(0, 0, 0, .3)'};
    }

    button {
      height: 16px;
      margin: 0;
      cursor: pointer;
      border: none;
      transition: 0.5s ease;
      background-color: transparent;
      border-radius: 100%;

      &:focus {
        outline: none;
      }

      svg {
        max-height: 16px;
        max-width: 16px;
        path {
          fill: ${expanded ? `var(--default-gray)` : 'white'};
        }
        color: ${expanded ? `var(--default-gray)` : 'white'};
      }
    }

    input {
      opacity: ${expanded ? '1' : '0'};
      width: ${expanded ? 'auto' : '0'};

      transition: 0.5s ease;
      border: none;
      font-size: var(--font-medium);
      cursor: text;

      background-color: transparent;
      color: var(--default-purple);
      text-indent: 4px;
      font-weight: 500;
      transition: 0.5s ease;

      &::placeholder {
        color: var(--default-purple);
      }

      &:focus {
        outline: none;
      }
    }
  `}
`;

export const SearchResults = styled.ul`
  z-index: 15;
  position: absolute;
  top: 40px;
  right: 0;
  max-height: 360px;
  min-height: 64px;
  width: 360px;
  padding: 16px 0;
  border-radius: 16px;
  background-color: ${'white'};
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: -2px 6px 30px -10px rgba(0, 0, 0, 0.75);

  .sectionTitle {
    margin: 8px 16px;
    font-weight: bold;
    color: var(--default-purple);
  }

  li.noResults {
    height: 64px;
    width: 360px;
    padding: 8px;
    color: var(--default-purple);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const SearchResultItemWrapper = styled.li<ISearchResultItem>`
  ${({ hasThumb }) => css`
    max-height: 100px;
    min-height: 64px;
    width: 360px;
    padding: 8px 16px;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-template-columns: ${hasThumb ? '48px 1fr' : '1fr'};
    grid-column-gap: 24px;
    position: relative;
    color: var(--default-purple);
    transition: 0.5s ease;
    list-style: none;
    z-index: 1;

    &:before {
      background-color: var(--default-purple);
      left: 0;
      bottom: 0;
      content: '';
      width: 360px;
      height: 0;
      position: absolute;
      transition: 0.5s ease;
      z-index: -1;
    }

    &:hover {
      &:before {
        height: 100%;
      }
      color: white;
    }

    .resultThumb {
      display: flex;
      align-items: center;
      height: 48px;
      width: 48px;
      overflow: hidden;

      img {
        max-height: 48px;
        max-width: 48px;
      }
    }

    .data {
      overflow: hidden;
      padding-right: 16px;
      .name {
        font-size: var(--font-medium);
        font-weight: bold;
        max-height: 2.5em;
        overflow: hidden;
      }
      .description {
        max-height: 3.5em;
        overflow: hidden;
        font-size: var(--font-small);
      }
    }
  `}
`;

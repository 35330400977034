import styled from 'styled-components';

const LessonOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 15px;

  p {
    margin-bottom: 9px;
  }
`;



export { LessonOverviewContainer };

import React, { useContext, useState } from 'react';
import { BadgeTargetGroup, CourseCardContainer } from './style';
import { BsBookmark } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import {
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineClockCircle,
  AiOutlineUser,
} from 'react-icons/ai';
import { addBookMark, removeBookMark } from '../../services/bookmark';
import { toast } from 'react-toastify';
import MyCoursesContext from '../../pages/MyCourses/context';
import Course from '../../models/course';
import ProgressBar from '../ProgressBar';
import { secondsToTimeText } from '../../helpers/time';
import clampJs from 'clamp-js';
import { useEffect } from 'react';
import { useRef } from 'react';
import { FaCrosshairs } from 'react-icons/fa';
import setTargetGroup from '../../helpers/converters/convert-target-group';
import { formatTimeFromDB } from '../../helpers/formatTime';

interface CourseCardProps {
  course: Course;
  onAddToList?: () => void;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, onAddToList }) => {
  const { refreshFavoriteCourses, refreshOngoingCourses } =
    useContext(MyCoursesContext);
  const [addedToList, setAddedToList] = useState(course.addedToList);
  const courseTitleRef = useRef<HTMLDivElement>(null);

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (course) {
      try {
        const shouldAddToList = !course.addedToList;

        if (shouldAddToList) {
          await addBookMark(course.id);
        } else {
          await removeBookMark(course.id);
        }

        course.addedToList = shouldAddToList;
        setAddedToList(course.addedToList);

        if (!!onAddToList) {
          onAddToList();
        }

        if (refreshOngoingCourses) {
          refreshOngoingCourses();
        }

        if (refreshFavoriteCourses) {
          refreshFavoriteCourses();
        }
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  useEffect(() => {
    if (courseTitleRef.current) {
      clampJs(courseTitleRef.current, { clamp: 2 });
    }
  }, [courseTitleRef]);

  const handleClick = (event:any)=>{
    localStorage.setItem('yPositionCourse', event.pageY.toString())
  }

  return (
    <CourseCardContainer onClick={handleClick}>
      <Link to={course?.info?.tag === 'COMING_SOON' ? '#' : `/course/${course.id}`}>
        <div
          className={`
            course-card
            ${addedToList ? 'added-to-list' : ''}
          `}
          style={{
            cursor:
              course.info && course.info.tag && course.info.tag === 'COMING_SOON'
                ? 'inherit'
                : 'pointer',
          }}
        >
          <div className="course-img-container">
            {course.thumbnailUrl ? (
              <img src={`${course.thumbnailUrl}`} alt="course" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/play.png`} alt="course" />
            )}
          </div>

          <div className="course-overview">
            <div className="top-content">
              <span className="category">{course.categoryName}</span>

              <div style={{ marginLeft: '4px' }}>
                <button
                  type="button"
                  onClick={e => {
                    addToList(e);
                  }}
                  className={`
                  icon-add-to-list
                  ${addedToList ? 'added-to-list' : ''}
                `}
                >
                  <BsBookmark
                    size={16}
                    color={`${addedToList ? '#FFF' : '#6B778C'}`}
                  />
                </button>
              </div>
            </div>

            <div ref={courseTitleRef} className="title" title={course.title}>
              {course.title}
            </div>

            <div className="author">
              <AiOutlineUser size={14} color="var(--default-purple)" />
              <span>Autor: {course.authorName}</span>
            </div>
            {!!course.target_group && course.target_group.length > 0 ? (
              <div className="target_group">
                <FaCrosshairs size={14} color="var(--default-purple)" />
                <span> Público Alvo: {
                (setTargetGroup([course?.target_group[0]])) 
                 }
                  {course?.target_group.length > 1 ? (
                    <BadgeTargetGroup title={String(setTargetGroup(course?.target_group.slice(1,course.target_group.length)))}>
                      +{course?.target_group.length - 1}
                    </BadgeTargetGroup>
                  ) : null
                  }
                </span>
              </div>
            ) : null}

            <div className="duration">
              <AiOutlineClockCircle color={'var(--default-purple)'} size={14} />
              <span>Duração: ~ {secondsToTimeText(course.duration)} </span>
            </div>

            {!course.alreadyFinished && course.progress ? (
              <ProgressBar
                darkenProgress
                completionPercentage={Math.floor((course.progress || 0) * 100)}
              />
            ) : (
              <></>
            )}

            {!!course.deadline && (
              <div className="deadline">
                <AiOutlineCalendar size={14} color="var(--default-purple)" />
                <span>
                  Disponível até:
                  {' ' +
                    new Intl.DateTimeFormat('pt-BR').format(
                      new Date(course.deadline),
                    )}
                </span>
              </div>
            )}

            {!!course.alreadyFinished ? (
              <div className="course-finished">
                <AiOutlineCheckCircle size={18} color="var(--default-purple)" />
                <span className="course-finished-text">Curso Concluído</span>
              </div>
            ) : (
              <span></span>
            )}
          </div>

          {course.info && course.info.tag && course.info.tag === 'COMING_SOON' && (
            <div className="soon-tag ribbon-top-right">
              <span>em breve</span>
            </div>
          )}

          {course.isNew &&
          !(
            course.info &&
            course.info.tag &&
            course.info.tag === 'COMING_SOON'
          ) ? (
            <div className="is-new-badge">Novidade</div>
          ) : (
            <span></span>
          )}
        </div>
      </Link>
    </CourseCardContainer>
  );
};

export default CourseCard;

import styled from 'styled-components';

const AnnotationListMobileContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow-y: auto;

  .label-box {
    padding: 16px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: var(--default-purple);
    }
  }
`;

const AnnotationListHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;

  nav {
    padding: 12px 8px;
    color: #fff;
    background-color: var(--default-purple);

    .close-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #fff;
      padding: 4px;
      background: transparent;
      border: none;
      outline: none;
      transition: all 0.3s;

      &:focus,
      &:hover {
        outline: none;
      }
    }
  }
`;

const AnnotationCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: solid 1px #e5e8eb;
  padding-top: 6px;
  padding-bottom: 8px;
  cursor: pointer;
`;

const AnnotationListSearchContainer = styled.div`
  padding: 0 12px;

  .wrapper {
    position: relative;
    margin-bottom: 12px;

    & img {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
`;

const AnnotationListSearchInput = styled.input`
  border: solid 1px #d3d8dd;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  outline: none;
`;

const AnnotationsList = styled.div`
  padding: 0 12px;
`;

const AnnotationViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 12px;
`;

const AnnotationDescription = styled.p`
  color: #505f79;
`;

const AnnotationTitle = styled.h4``;

const AnnotationTitleAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AnnotationDate = styled.p`
  color: #505f79;
  font-size: 12px;
`;

const AnnotationDateAndDescriptionContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const AnnotationViewTitle = styled.h4`
  font-weight: bold;
  font-size: 16px;
  margin: 16px 0;
`;

const AnnotationViewDescription = styled.p`
  color: #505f79;
`;

const AnnotationViewContent = styled.div`
  flex-grow: 1;
  max-height: 77.5vh;
  overflow: auto;
`;

const AnnotationViewFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin: 16px 0;

  & button {
    border: none;
    padding: 8px;
    background: none;
    cursor: pointer;
    outline: none;
    transition: background 0.3s;
    border-radius: 5px;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;

export {
  AnnotationListMobileContainer,
  AnnotationListHeader,
  AnnotationCardContainer,
  AnnotationListSearchInput,
  AnnotationsList,
  AnnotationViewContainer,
  AnnotationDescription,
  AnnotationTitle,
  AnnotationTitleAndIconContainer,
  AnnotationListSearchContainer,
  AnnotationDateAndDescriptionContainer,
  AnnotationDate,
  AnnotationViewDescription,
  AnnotationViewTitle,
  AnnotationViewFooter,
  AnnotationViewContent,
};

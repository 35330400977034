export default async function imgUrlToDataURL(url: string): Promise<string> {
  if (url && url.includes('data:image')) return url;

  return fetch(url)
    .then(res => res.blob())
    .then(async blob => {
      return await new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });
    });
}

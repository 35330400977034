import styled from 'styled-components';

export const HeaderLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }

  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    border: none;
    outline: none;
    min-width: 120px;
    padding: 0 5px;
    text-decoration: none;
    color: #7a869a;
    height: 50px;
    cursor: pointer;
    background: none;

    * {
      margin: 5px;
    }
  }

  a {
    display: none !important;
  }

  a:hover,
  button:hover {
    background-color: #161b22;
    color: white;
  }

  .innovation-icon {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: #f35325;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

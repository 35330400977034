import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100vw;

  .superior-header {
    background-color: #212631;
    width: 100vw;

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr;
      }

      .left-content-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          display: grid;
          grid-template-columns: 1fr;
        }

        .logo {
          margin-right: 10px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 145px;
          }
        }
      }
    }
  }

  .section-header {
    background-color: var(--default-purple);

    .content {
      display: grid;
      grid-template-columns: 69% calc(31% - 15px);
      align-items: center;
      gap: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100%;

      .greetings {
        line-height: 50px;
        color: white;
        font-size: var(--font-large);
        font-weight: lighter;
      }

      .go-back-button {
        color: white;
        width: 100px;
        height: 40px;
        text-decoration: none;
        background-color: #540cc6;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        *:first-child {
          margin-right: 5px;
        }
      }
    }
  }
`;

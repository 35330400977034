import styled from 'styled-components';

export const VideoContentContainer = styled.div`
  flex-direction: column;
  align-items: stretch;
  width: 800px;
  max-width: 1260px;
  margin-top: 20px;
  background: white;
  border-radius: 5px;
  @media (max-width: 1000px) {
    width: 550px;
  }
  @media (max-width: 800px) {
    width: 535px; 
  }
  @media (max-width: 550px) {
    width: 355px;
  }
  @media (max-width: 360px) {
    width: 215px;
  }
  @media (max-width: 300px) {
    width: 220px;
  }
  .upper-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .category {
      font-size: var(--font-medium);
      font-weight: bold;
      color: var(--default-purple);
    }
    .title {
      font-size: var(--font-largest);
      word-break: break-word;
      color: var(--default-font-color);
      margin: 10px 0;
    }
  }
  .about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #505f79;
    margin-bottom: 20px;
    align-self: start;
    font-size: var(--font-medium);
    span,
    button {
      display: flex;
      align-items: center;
      *:not(*:first-child) {
        margin-left: 5px;
      }
    }
    .duration {
      margin: 0 10px;
    }
  }
  .video-content {
    .video-container {
      justify-content: start;
      align-items: center;
      margin-bottom: 20px;
      @media (max-width: 550px) {
        margin: 0 auto;
      }
      iframe {
        outline: none;
        border: none;
        height: 450px;
        @media (max-width: 1300px) {
          height: 450px;
        }
        @media (max-width: 1000px) {
          height: 310px;
        }
        @media (max-width: 800px) {
          height: 300px;
        }
        @media (max-width: 550px) {
          height: 200px;
        }
        @media (max-width: 360px) {
          height: 120px;
        }
        @media (max-width: 300px) {
          height: 125px;
        }
      }
      .player-container {

      }
      .player-icon-container {       
        .player-icon::after {
          content: '';
          top: calc(50% - 10px);
          left: 0;
          border-top: 15px solid #fff;
          width: 100%;
          transform: rotate(45deg);
        }
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .video-title {
      color: var(--default-font-color);
      font-size: var(--font-larger);
      margin: 12px 0;
    }
    .video-description {
      color: var(--default-font-color);
      font-size: var(--font-medium);
    }
  }
`;

const PlayerContainer = styled.div`
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    height: 380px;
  }
  @media (max-width: 960px) {
    height: 260px;
  }
  @media (max-width: 768px) {
    height: 260px;
  }
  @media (max-width: 550px) {
    height: 165px;
  }
`;

const VideoTabsGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px var(--border-gray);
`;

const VideoTabsGroupHeaders = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px var(--border-gray);
`;

const VideoTabHeaderGroupContainer = styled.button`
  font-weight: 600;
  font-size: 14px;
  width: auto;
  min-width: 80px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #344563;
  background: #F0F3F6;
  padding: 5px;
  border-top-left-radius: 8px 8px;
  border-top-right-radius: 8px 8px;
  border-bottom: solid 3px transparent;

  &.selected,
  &:hover {
    background-color: #7324F2;
    color: #FFFFFF;
    border-bottom: solid 3px #7324F2;
    
  }

  @media (max-width: 420px) {
    min-width: 50px;
    max-width: auto;
  }
`;


const VideoTabsGroupContent = styled.div`
  min-height: 80px;
`;

const VideoTabsAndRating = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const VideoTabsHeaders = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 4px;
  border-bottom: solid 3px #7324F2;
`;

const VideoTabsContent = styled.div`
  min-height: 80px;
  margin-top: 20px;
`;

const VideoTabHeaderContainer = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  cursor: pointer;
  padding: 5px;

  &.selected,
  &:hover {
    border-bottom: solid 3px var(--default-purple);
  }
`;

const PlayerIconContainer = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayerIcon = styled.span`
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    border-top: 15px solid #fff;
    width: 100%;
    transform: rotate(45deg);
  }
`;

const VideoOrLessonTitle = styled.h4`
  font-size: 1.2em;
  margin-bottom: 10px;
`;

const VideoBanner = styled.img`
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
`;

const StartVideoContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background: #fff;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
`;

const StartVideoButton = styled.button`
  border: none;
  background: var(--default-purple);
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  outline: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 5px;
  opacity: 0.9;
  transition: all 0.3s;
  width: 100%;

  &:hover {
    opacity: 1;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const LessonOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  div {
    margin-bottom: 20px;
  }
`;

export {
  PlayerContainer,
  VideoTabsAndRating,
  VideoTabsHeaders,
  VideoTabsContent,
  VideoTabHeaderContainer,
  PlayerIconContainer,
  PlayerIcon,
  VideoOrLessonTitle,
  VideoBanner,
  StartVideoContainer,
  StartVideoButton,
  VideoTabsGroup,
  VideoTabsGroupHeaders,
  VideoTabsGroupContent,
  VideoTabHeaderGroupContainer,
  LessonOverviewContainer
};


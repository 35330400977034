const target_group_option = [
    { value: 1, label: 'Associados e Gestores' },
    { value: 2, label: 'Atendentes e Farmacêuticos' },
    { value: 3, label: 'Colaboradores Farmarcas' },
    { value: 4, label: 'Parceiros Comerciais' },
    { value: 5, label: 'Compradores' }
];

const setTargetGroup = (target_Groups: Array<number> | undefined ) : String =>{
    if(!!target_Groups && target_Groups.length > 0){
      const stringOfTargetGroups = []
        for (let i = 0; i < target_Groups.length; i++) {
            let tgo  = target_group_option.find(tgo => tgo.value === target_Groups[i]) 
            stringOfTargetGroups.push(tgo?.label);            
          }
          return stringOfTargetGroups.join(', ')
    }
      return 'Não Definido';
} 

export default setTargetGroup
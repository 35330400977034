import styled from 'styled-components';

const CourseContainer = styled.div`
  flex-grow: 1;
  margin: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 54px;
  }
`;

const CourseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: white;
  padding: 8px 16px;
  border-radius: 5px;
`;

const CourseLowerContent = styled.div`
  display: grid;
  grid-template-columns: calc(70% - 15px) 30%;
  gap: 15px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const StyleOfDurationTargetGpAuthor = styled.div`
  width: 69%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const CourseInfoAndProgress = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const CourseAuthorDurationAndBookmarkContainer = styled.div`
  display: flex;  
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

const CourseTitle = styled.h3`
  font-size: 2em;
  flex-grow: 1;

  @media (max-width: 768px) {
    font-size: 1.4em;
  }
`;

const CourseAuthor = styled.span`
  span {
    max-width: 100%;

    @media (max-width: 300px) {
      max-width: 96px;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

const CourseDuration = styled.span`
  span {
    max-width: 160px;

    @media (max-width: 768px) {
      max-width: 96px;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

const CourseTargetGroup = styled.div`
span {
  max-width: 100%;
  overflow: overflow-wrap;
  @media (max-width: 300px) {
    max-width: 96px;
  }
  line-break: auto;
  white-space: nowrap;
  @media (max-width: 1200px) {
    white-space: normal;
    padding-right: 5%;
  }
}

  display: flex;
  align-items: center;
  gap: 5px;
`;

const BookmarkButton = styled.button`
  background: var(--light-gray);
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: #555555;
  cursor: pointer;
  

  &.added {
    background: var(--default-purple);
    color: white;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

const CourseProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CourseProgress = styled.div`
  height: 15px;
  flex-grow: 1;
  border: solid 1px var(--medium-gray);
  border-radius: 3px;
  overflow: hidden;

  & > span {
    background: var(--progress-color);
  }

  display: flex;
  align-items: stretch;
`;

const CourseProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const CourseCategory = styled.h4`
  color: var(--default-purple);
  font-size: 1em;
`;

const AuthorAndDurationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
`;

const CourseDeadLine = styled.p`
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const CourseTitleAndCategory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

const MobileAuthorAndDurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export {
  CourseDeadLine,
  CourseContainer,
  CourseContentContainer,
  CourseLowerContent,
  CourseAuthorDurationAndBookmarkContainer,
  CourseTitle,
  CourseAuthor,
  CourseDuration,
  BookmarkButton,
  CourseInfoAndProgress,
  CourseProgressContainer,
  CourseProgress,
  CourseProgressBarContainer,
  CourseCategory,
  CourseTitleAndCategory,
  AuthorAndDurationContainer,
  MobileAuthorAndDurationContainer,
  CourseTargetGroup,
  StyleOfDurationTargetGpAuthor,
};

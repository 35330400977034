import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  AnnotationActions,
  AnnotationCardContainer,
  AnnotationDate,
  AnnotationDescription,
  AnnotationTitle,
  AnnotationTitleAndActions,
  LessonAnnotationsContainer,
  LessonAnnotationsForm,
  LessonAnnotationsList,
} from './styles';
import {
  getAnnotations as getAnnotationsService,
  createAnnotation as createAnnotationService,
  updateAnnotation as updateAnnotationService,
  deleteAnnotation as deleteAnnotationService,
} from '../../../../../services/annotations'
import { FiEdit } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import Swal, { SweetAlertResult } from 'sweetalert2';
import AnnotationListMobile from './components/AnnotationListMobile';
import GlobalContext from '../../../../../GlobalContext';
import checkEmptyString from '../../../../../helpers/check-empty-string';
import getErrorMessage from '../../../../../helpers/get-error-message';
import { showInRawModal } from '../../../../../helpers/rawModal';
import { useParams } from 'react-router-dom';
import Annotation from '../../../../../models/annotation';

interface AnnotationCardProps {
  annotation: Annotation;
  onDelete: (annotationId: string) => Promise<void>;
  onEdit: (annotation: Annotation) => void;
}

const AnnotationCard: React.FC<AnnotationCardProps> = ({
  annotation,
  onDelete,
  onEdit,
}) => {
  return (
    <AnnotationCardContainer>
      <AnnotationTitleAndActions>
        <AnnotationTitle>{annotation.title}</AnnotationTitle>
        <AnnotationActions>
          <button onClick={() => onEdit(annotation)} type="button">
            <FiEdit size={18} color="var(--dark-gray)" />
          </button>
          <button onClick={() => onDelete(annotation.id)} type="button">
            <BsTrash size={18} color="var(--dark-gray)" />
          </button>
        </AnnotationActions>
      </AnnotationTitleAndActions>
      <AnnotationDate>
        {new Intl.DateTimeFormat('pt-BR').format(annotation.date)}
      </AnnotationDate>
      <AnnotationDescription>{annotation.description}</AnnotationDescription>
    </AnnotationCardContainer>
  );
};

interface VideoContentParams {
  contentId: string;
}


const LessonAnnotations: React.FC = () => {
  const { contentId } = useParams<VideoContentParams>();
  const { isMobile } = useContext(GlobalContext);
  const [idOfEdittingAnnotation, setIdOfEdittingAnnotation] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [annotations, setAnnotations] = useState([] as Annotation[]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (idOfEdittingAnnotation) {
      updateAnnotation();
    } else {
      createAnnotation();
    }
  };

  const getAnnotations = async () => {    
    const localAnnotations = await getAnnotationsService(
      contentId,
      contentId,
    );
    setAnnotations(localAnnotations);

    return localAnnotations;
  };

  const validate = () => {
    if (checkEmptyString(title)) {
      throw new Error('Informe um título valido para a anotação!');
    }

    if (checkEmptyString(description)) {
      throw new Error('Informe uma descrição valida para a anotação!');
    }
  };

  const createAnnotation = async () => {
    try {
      validate();
      await createAnnotationService(
        contentId,
        contentId,
        title,
        description,
      );
      toast.success('Anotação criada com sucesso!');
      setTitle('');
      setDescription('');
      await getAnnotations();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error('Houve um erro ao criar a anotação. ' + errorMessage);
    }
  };

  const editAnnotation = (annotation: Annotation) => {
    setIdOfEdittingAnnotation(annotation.id);
    setTitle(annotation.title);
    setDescription(annotation.description);
  };

  const cancelEdit = () => {
    setIdOfEdittingAnnotation('');
    setTitle('');
    setDescription('');
  };

  const updateAnnotation = async () => {
    try {
      validate();
      await updateAnnotationService(idOfEdittingAnnotation, title, description);
      cancelEdit();
      toast.success('Anotação editada com sucesso!');
      await getAnnotations();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error('Houve um erro ao editar a anotação. ' + errorMessage);
    }
  };

  const deleteAnnotation = async (annotationId: string) => {
    return Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja remover esta anotação?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteAnnotationService(annotationId);
          await getAnnotations();
          toast.success('Anotação removida com sucesso!');
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          toast.error('Houve um erro ao excluir a anotação. ' + errorMessage);
        }
      }
    });
  };

  const showAll = () => {
    showInRawModal(
      <AnnotationListMobile
        annotations={annotations}
        deleteAnnotation={deleteAnnotation}
        editAnnotation={editAnnotation}
        reloadAnnotations={getAnnotations}
      />,
    );
  };

  useEffect(() => {
    getAnnotations();
  }, []);

  return (
    <LessonAnnotationsContainer>
      <LessonAnnotationsForm onSubmit={handleSubmit}>
        <p>Crie uma anotação:</p>

        <input
          type="text"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Titulo"
        />
        <textarea
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder="Escreva Aqui..."
        ></textarea>

        <div className="actions">
          {idOfEdittingAnnotation && (
            <button type="button" onClick={cancelEdit}>
              Cancelar
            </button>
          )}
          {isMobile && !idOfEdittingAnnotation && annotations.length > 0 && (
            <button type="button" className="show-all" onClick={showAll}>
              Ver todas
            </button>
          )}
          <button type="submit">
            {idOfEdittingAnnotation ? 'Editar' : 'Criar'} Nota
          </button>
        </div>
      </LessonAnnotationsForm>
      {!isMobile && (
        <LessonAnnotationsList>
          {!idOfEdittingAnnotation && annotations && annotations.length ? (
            annotations.map(ann => (
              <AnnotationCard
                key={ann.id}
                annotation={ann}
                onDelete={deleteAnnotation}
                onEdit={editAnnotation}
              />
            ))
          ) : (
            <></>
          )}
        </LessonAnnotationsList>
      )}
    </LessonAnnotationsContainer>
  );
};

export default LessonAnnotations;

import React from 'react';
import * as Style from './styled';

interface DraggableImageProps {
  imageObject: any;
}

const DraggableImage = ({ imageObject }: DraggableImageProps) => {
  return (
    <Style.DraggableImageWrapper
      src={imageObject?.src || ''}
      style={{
        maxWidth: `${
          imageObject?.scale && `calc(100% * ${imageObject?.scale})`
        }`,
        position: 'absolute',
        top: `${imageObject?.top || 'unset'}`,
        left: `${imageObject?.left || 'unset'}`,
      }}
    />
  );
};

export default DraggableImage;

import React, { useContext, useMemo } from 'react';
import usePostHog from '../../../../../../hooks/usePosthog';
import ExtraMaterial from '../../../../../../models/extra-material';
import Lesson from '../../../../../../models/lesson';
import CourseContext from '../../../../Context';
import { LessonExtraMaterialContainer } from './styles';
import { ExtraMaterialCardContainer } from '../../../../../VideoLibrary/components/VideoContent/VideoExtraMaterial/components/ExtraMaterialCard/style';
import getIconByFileType  from '../../../../../../helpers/iconExtension';

interface ExtraMaterialCardProps {
  extraMaterial: ExtraMaterial;
}

const ExtraMaterialCard: React.FC<ExtraMaterialCardProps> = ({
  extraMaterial,
}) => {
  const { course, selectedLesson } = useContext(CourseContext);
  const posthog = usePostHog();

  const download = () => {
    posthog?.capture(
      `Capacitação - Material de apoio`,
      {
        course: selectedLesson.title,
        material: extraMaterial.name,
        materialId: extraMaterial.id,
      },
    );
    window.open(extraMaterial.reference);
  };

  return (
    <ExtraMaterialCardContainer onClick={download}>
      <span className="name">
        { extraMaterial.info.title || extraMaterial.name }
      </span>
      <span>{getIconByFileType(extraMaterial.name)}</span>
    </ExtraMaterialCardContainer>
  );
};

const LessonExtraMaterial: React.FC = () => {
  const { selectedLesson } = useContext(CourseContext);

  const actualSelectedLesson = useMemo(() => {
    return selectedLesson as Lesson;
  }, [selectedLesson]);

  return (
    <LessonExtraMaterialContainer>
      {actualSelectedLesson &&
      actualSelectedLesson.extraMaterials &&
      actualSelectedLesson.extraMaterials.length ? (
        actualSelectedLesson.extraMaterials.map(em => (
          <ExtraMaterialCard key={em.id} extraMaterial={em} />
        ))
      ) : (
        <>Esta aula não possui materiais de apoio.</>
      )}
    </LessonExtraMaterialContainer>
  );
};

export default LessonExtraMaterial;

import styled from 'styled-components';

export const VideoLibraryPageContainer = styled.div`
  width: 100vw;

`;

export const VideoLibraryPageTitle = styled.h1`
  color: var(--default-font-color);
  font-size: var(--font-largest);
  margin: 25px 0;
  width: 100%;
  font-weight: bold;
`;

export const VideoLibraryPageCard = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 930px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 460px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .skeleton {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 28px;
    column-gap: 22px;
    border: 1px solid red;
  }
`;

export const VideoLibraryFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 38px 0;
  h4 {
    color: var(--default-purple);
    cursor: pointer;
    font-size: var(--font-large);
    &:hover {
      color: var(--default-dark-gray);
    }
  }
`;

import styled from 'styled-components';

export const VideoExtraMaterialContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;

  .name {
    font-size: 14px;
    font-weight: 600;
    color: var(--default-font-color);
  }
`;
import httpClient from '../../http-client';
import { generateCertificate } from '../../pdf/generateCertificate';

export interface CertificateData {
  duration: number;
  finish_at: Date;
  trailName: string;
  userName: string;
}

export interface Certificate {
  certificate_id: string;
  user_id: string;
  user_name: string;
  trail_id: string;
  trail_name: string;
  trail_duration: number;
  trail_finish_at: Date;
  emitted_at: Date;
  author?: string;
  category?: string;
  imageUrl?: string;
}

const getCertificateById = async (certificateId: string) => {
  const certificate = (
    await httpClient.get(`/certificate-templates/replace/${certificateId}`)
  ).data;

  return certificate;
};

const getCertificates = async (): Promise<Certificate[]> => {
  const certificates: Certificate[] = (await httpClient.get('/certificates'))
    .data;

  return certificates;
};

const getCertificateData = async (trailId: string) => {
  let certificateData = {} as CertificateData;
  try {
    await httpClient.get(`/certificates/${trailId}`).catch(async err => {
      await httpClient.post(`/certificates/${trailId}`);
    });

    certificateData = (
      await httpClient.get<CertificateData>(
        `/trail-users/certificate/${trailId}`,
      )
    ).data;
  } catch (error) {}

  return certificateData;
};

const getGeneratedCertificate = async (trailId: string) => {
  const certificate = await generateCertificate(trailId);

  return certificate;
};


const createCertificate = async (course_id: string) => {
  const { data } = await httpClient.post(`/certificates/${course_id}`);

  return data;
};

export {
  getCertificates,
  getCertificateData,
  createCertificate,
  getGeneratedCertificate,
  getCertificateById,
};

import React, { useEffect, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { BsChevronDown } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import AnnotationCard from '../AnnotationCard';
import {
  LessonCardAnnotations,
  LessonCardContainer,
  LessonCardContent,
  LessonCardContentsDivider,
  LessonCardLinkContainer,
  LessonCardLowerContent,
  LessonCardTitle,
  LessonCardToogleButton,
  LessonCardUpperContent,
} from './style';

interface LessonCardProdps {
  lesson: any;
  courseId: string;  
}

const LessonCard: React.FC<LessonCardProdps> = ({ lesson, courseId }) => {
  const [annotations, setAnnotations] = useState<any[]>([]);
  const [shouldShowAnnotations, setShouldShowAnnotations] = useState(false);

  const removeAnnotation = (annotationId: string) => {
    const annotationToBeRemoved = lesson.annotations.find(
      (annotation: any) => annotation.annotation_id === annotationId,
    );

    if (annotationToBeRemoved) {
      const annotationToBeRemovedIndex = lesson.annotations.indexOf(
        annotationToBeRemoved,
      );

      lesson.annotations.splice(annotationToBeRemovedIndex, 1);
      setAnnotations([...lesson.annotations]);
    }
  };

  const toogleAnnotations = () => {
    setShouldShowAnnotations(!shouldShowAnnotations);
  };

  useEffect(() => {
    setAnnotations([...lesson.annotations]);
  }, [lesson.annotations]);

  return (
    <LessonCardContainer>
      <LessonCardUpperContent>
        <LessonCardToogleButton
          className={`${shouldShowAnnotations ? 'up' : 'down'}`}
          onClick={toogleAnnotations}
        >
          <BsChevronDown />
        </LessonCardToogleButton>
        <LessonCardContent>
          <LessonCardTitle>{lesson.title}</LessonCardTitle>
          <LessonCardLinkContainer>
            <Link to={`/course/${courseId}/lesson/${lesson.content_id}`}>
              <span>Ir pra aula</span>
              <BiLinkExternal />
            </Link>
          </LessonCardLinkContainer>
        </LessonCardContent>
      </LessonCardUpperContent>

      {shouldShowAnnotations && (
        <>
          <LessonCardContentsDivider />

          <LessonCardLowerContent>
            <LessonCardAnnotations>
              {annotations && annotations.length ? (
                annotations.map(annotation => (
                  <AnnotationCard
                    key={annotation.annotation_id}
                    onDeleteAnnotation={removeAnnotation}
                    annotation={annotation}
                  />
                ))
              ) : (
                <></>
              )}
            </LessonCardAnnotations>
          </LessonCardLowerContent>
        </>
      )}
    </LessonCardContainer>
  );
};

export default LessonCard;

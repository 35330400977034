import styled from 'styled-components';

const CourseCardWithoutImageContainer = styled.div`
  width: 300px;
  border: solid 1px #d8dce1;
  border-radius: 5px;
  cursor: pointer;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;

  &.added-to-list {
    border-color: var(--default-purple);
  }
`;

const CourseCardWithoutImageUpperContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CourseCardWithoutImageCategory = styled.p`
  font-size: 14px;
  color: var(--default-purple);
  font-weight: bold;
`;

const CourseCardWithoutImageBookmarkButton = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background: #f0f3f6;
  color: var(--default-font-color);
  padding: 3px;
  border-radius: 3px;
  height: 30px;
  width: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  &.added-to-list {
    background-color: var(--default-purple);
    color: white;
  }
`;

const CourseCardWithoutImageTitle = styled.h3`
  font-size: var(--font-large);
  line-height: 24px;
  width: 100%;
  position: relative;
  padding-right: 15px;
`;

const CourseCardWithoutImageRemainingTime = styled.p`
  font-size: 14px;
  color: #505f79;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

export {
  CourseCardWithoutImageContainer,
  CourseCardWithoutImageUpperContentContainer,
  CourseCardWithoutImageCategory,
  CourseCardWithoutImageBookmarkButton,
  CourseCardWithoutImageTitle,
  CourseCardWithoutImageRemainingTime,
};

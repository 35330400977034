import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import {
  CourseInstructionsContainer,
  FooterContainer,
  Header,
  SubHeader,
  CourseInstructionsContent,
} from './styles';
import CertificateImage from '../../../../../assets/certificate.png';
import CameraIcon from '../../../../../assets/icons/camera.svg';
import ExamIcon from '../../../../../assets/icons/exam.svg';
import CapIcon from '../../../../../assets/icons/cap.svg';
import { ReactComponent as ArrowNextIcon } from '../../../../../assets/icons/arrow-next.svg';
import { AiOutlineCheck, AiFillCheckSquare } from 'react-icons/ai';
import { useContext } from 'react';
import { hideRawModal } from '../../../../../helpers/rawModal';

import { IoIosClose } from 'react-icons/io';

enum InstructionSteps {
  'ACESSO_CERTIFICADO' = 1,
  'ASSISTIR_VIDEOS' = 2,
  'RESPONDER_QUESTIONARIOS' = 3,
  'ACERTO_QUESTOES' = 4,
  'CONFIRMACAO' = 5,
}

interface InstructionControllerProps {
  isLastStep: boolean;
  isFirstStep: boolean;
  agreeWithInstructions?: boolean;
}

const AltHeader: React.FC<Partial<InstructionControllerProps>> = ({
  isLastStep,
}) => {
  return (
    <SubHeader>
      {isLastStep
        ? 'Entenda quais são suas obrigações nesse curso:'
        : 'Para receber o Certificado é necessário:'}
    </SubHeader>
  );
};

const DefaultFooter: React.FC<InstructionControllerProps> = ({
  isLastStep,
  isFirstStep,
  agreeWithInstructions,
}) => {
  const { handleNextStep, cancelInstructions } = useContext(
    CourseInstructionsContext,
  );

  return (
    <FooterContainer>
      <button onClick={cancelInstructions} className="cancel">
        {isLastStep || isFirstStep ? 'Cancelar' : 'Voltar'}
      </button>
      <button
        className="next"
        disabled={isLastStep && !agreeWithInstructions}
        onClick={() =>
          (!isLastStep || agreeWithInstructions) && handleNextStep()
        }
      >
        {isLastStep ? (
          'Ok, entendi'
        ) : (
          <>
            <span>Próximo</span>
            <ArrowNextIcon />
          </>
        )}
      </button>
    </FooterContainer>
  );
};

const CertificationAccess: React.FC = () => {
  return (
    <div className="certificate-access between">
      <img src={CertificateImage} alt="certificate" />
      <div className="content-block">
        <p>
          <strong>
            Para ter acesso ao Certificado emitido na conclusão deste curso
          </strong>
          , você deverá completar alguns requisitos para garantirmos o seu
          aprendizado e absorção de conteúdo. <strong>Vamos lá?</strong>
        </p>
      </div>
    </div>
  );
};

const WatchAllVideos: React.FC = () => {
  return (
    <div className="watch-videos between">
      <img src={CameraIcon} alt="camera icon" />
      <ul className="content-block">
        <li>
          <div className="icon">
            <AiOutlineCheck size={24} />
          </div>
          <span className="label">Assistir todos os vídeos</span>
        </li>
      </ul>
    </div>
  );
};

const TakeTest: React.FC = () => {
  return (
    <div className="take-test between">
      <img src={ExamIcon} alt="exam icon" />

      <ul className="content-block">
        <li>
          <div className="icon">
            <AiOutlineCheck size={24} />
          </div>
          <span className="label">Responder todos os questionários</span>
        </li>
      </ul>
    </div>
  );
};

const TestResult: React.FC = () => {
  return (
    <div className="test-result between">
      <img src={CapIcon} alt="cap icon" />

      <ul className="content-block">
        <li>
          <div className="icon">
            <AiOutlineCheck size={24} />
          </div>
          <span className="label">Ter 80% de acerto no total de questões</span>
        </li>
      </ul>
    </div>
  );
};

interface ConfirmationProps {
  agree: boolean;
  setAgree: (val: boolean) => void;
}

const Confirmation: React.FC<ConfirmationProps> = ({ agree, setAgree }) => {
  return (
    <div className="confirmation between">
      <ul className="last">
        <li>
          <div className="icon">
            <AiOutlineCheck size={24} />
          </div>
          <span className="label">Responder todos os questionários</span>
        </li>
        <li>
          <div className="icon">
            <AiOutlineCheck size={24} />
          </div>
          <span className="label">Assistir todos os vídeos</span>
        </li>
        <li>
          <div className="icon">
            <AiOutlineCheck size={24} />
          </div>
          <span className="label">Ter 80% de acerto no total de questões</span>
        </li>
      </ul>

      <p className="agree-container content-block">
        <input
          id="agree"
          className="sr-only"
          type="checkbox"
          checked={agree}
          onChange={e => setAgree(e.target.checked)}
        />
        <label htmlFor="agree">
          <AiFillCheckSquare
            size={32}
            color={agree ? 'var(--default-purple)' : 'white'}
            style={{
              border: `${!agree ? '1px solid var(--medium-gray)' : 0}`,
            }}
          />
          <span>Concordo com os termos da Meta de Aprendizado</span>
        </label>
      </p>
    </div>
  );
};

interface ICourseInstructionsContext {
  cancelInstructions: () => void;
  handleNextStep: () => void;
}

const CourseInstructionsContext =
  React.createContext<ICourseInstructionsContext>({
    cancelInstructions: () => {},
    handleNextStep: () => {},
  });

interface CourseInstructionsProps {
  finishInstructions: () => void;
}

const CourseInstructions: React.FC<CourseInstructionsProps> = ({
  finishInstructions,
}) => {
  const [actualStep, setActualStep] = useState(1 as InstructionSteps);
  const [agree, setAgree] = useState(false);

  const cancelInstructions = () => {
    hideRawModal();
  };

  const handleNextStep = () => {
    if (actualStep !== InstructionSteps.CONFIRMACAO) {
      setActualStep(actualStep + 1);
    } else {
      finishInstructions();
    }
  };

  const stepToDisplay = useMemo(() => {
    switch (actualStep) {
      case 1:
        return <CertificationAccess />;
      case 2:
        return <WatchAllVideos />;
      case 3:
        return <TakeTest />;
      case 4:
        return <TestResult />;
      case 5:
        return <Confirmation agree={agree} setAgree={setAgree} />;
    }
  }, [actualStep, agree, setAgree]);

  return (
    <CourseInstructionsContext.Provider
      value={{
        cancelInstructions,
        handleNextStep,
      }}
    >
      <CourseInstructionsContainer>
        <Header>
          <div className="header-top">
            <h4 className="title">Meta de Aprendizado</h4>
            <button
              type="button"
              className="btn-close"
              onClick={cancelInstructions}
            >
              <IoIosClose size={32} className="icon" />
            </button>
          </div>
          {actualStep > 1 && <AltHeader isLastStep={actualStep === 5} />}
        </Header>
        <CourseInstructionsContent>{stepToDisplay}</CourseInstructionsContent>
        <DefaultFooter
          isLastStep={actualStep === 5}
          isFirstStep={actualStep === 1}
          agreeWithInstructions={agree}
        />
      </CourseInstructionsContainer>
    </CourseInstructionsContext.Provider>
  );
};

export default CourseInstructions;

import React from 'react';
import ReactDOM from 'react-dom';
import { ModalContainer, ModalBackDrop } from './style';

const showInRawModal = (content: React.ReactElement) => {
  const modal = document.createElement('div');
  modal.id = 'global-raw-modal';

  document.body.appendChild(modal);

  // desativa scrolling no body
  document.body.style.height = '100%';
  document.body.style.overflowY = 'hidden';

  ReactDOM.render(
    <ModalContainer>
      <ModalBackDrop onClick={hideRawModal} />
      {content}
    </ModalContainer>,
    document.getElementById('global-raw-modal'),
  );
};

const hideRawModal = () => {
  const modal = document.getElementById('global-raw-modal');
  if (modal) {
    modal.remove();

    // ativa scrolling no body
    document.body.style.height = '100%';
    document.body.style.overflowY = 'auto';
  }
};

export { showInRawModal, hideRawModal };

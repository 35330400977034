import styled from 'styled-components';

const LessonCardContainer = styled.div`
  width: 97%;
  padding: 10px;
  border: solid 1px #d3d8dd;
  border-radius: 5px;
  background-color: #fafbfc;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const LessonCardToogleButton = styled.button`
  margin-right: 15px;
  border: none;
  outline: none;
  background: none;
  padding: 5px;
  cursor: pointer;

  @keyframes turn-up {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  @keyframes turn-down {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  &.up {
    animation: turn-up 0.5s;
    transform: rotate(180deg);
  }

  &.down {
    animation: turn-down 0.5s;
    transform: rotate(0deg);
  }
`;

const LessonCardContent = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LessonCardTitle = styled.h2``;

const LessonCardLinkContainer = styled.div`
  a {
    text-decoration: none;
    color: var(--default-purple);
    font-size: var(--font-medium);
    font-weight: bold;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }
`;

const LessonCardAnnotations = styled.div``;

const LessonCardUpperContent = styled.div`
  width: 100%;
  margin-bottom: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LessonCardContentsDivider = styled.hr`
  border-color: #d3d8dd;
  width: 100%;
`;

const LessonCardLowerContent = styled.div`
  width: 100%;
  margin-left: 4%;
  margin-top: 10px;
`;

export {
  LessonCardContainer,
  LessonCardToogleButton,
  LessonCardContent,
  LessonCardTitle,
  LessonCardLinkContainer,
  LessonCardAnnotations,
  LessonCardUpperContent,
  LessonCardLowerContent,
  LessonCardContentsDivider,
};

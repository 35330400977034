import React from 'react';
import { MenuContainer } from './style';
import { Link, useLocation } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { isProduction } from '../../helpers/environment';
import dayjs from 'dayjs';

const Menu: React.FC = () => {
  const location = useLocation();

  const reportLink: string | undefined = process.env.REACT_APP_REPORT_URL;

  const removeLocalStorageYPosition = ()=>{
      localStorage.setItem('numberOfContentsToShow', '12');
      localStorage.removeItem('yPositionCourse');
      localStorage.removeItem('yPositionContent');
      localStorage.removeItem('page');
  }

  return (
    <MenuContainer>
      <Link
        to="/home"
        className={`
          ${!location.pathname || location.pathname === '/home' ? 'active' : ''}
        `}
        onClick={removeLocalStorageYPosition}
      >
        Home
      </Link>

      <Link
        to="/courses"
        className={`
          ${location.pathname === '/courses' ? 'active' : ''}
        `}
        onClick={removeLocalStorageYPosition}
      >
        Cursos
      </Link>

      <Link
        to="/my-courses"
        className={`
            ${location.pathname === '/my-courses' ? 'active' : ''}
          `}
        onClick={removeLocalStorageYPosition}
      >
        Meus Cursos
      </Link>

      <Link
        to="/certificates"
        className={`
          ${location.pathname === '/certificates' ? 'active' : ''}
        `}
        onClick={removeLocalStorageYPosition}
      >
        Certificados
      </Link>

      <Link
        to="/annotations"
        className={`
          ${location.pathname === '/annotations' ? 'active' : ''}
        `}
        onClick={removeLocalStorageYPosition}
      >
        Anotações
      </Link>

      <Link
        to="/video-library"
        className={`
          ${location.pathname === '/video-library' ? 'active' : ''}
        `}
        onClick={removeLocalStorageYPosition}
      >
        Assista agora
      </Link>
      {isProduction() && dayjs().isBefore(dayjs('2023-10-30')) ? <></> :
      <a className="external-link" href={reportLink} target='_blank'>
        Relatório de Capacitação <FaExternalLinkAlt className='external-link-icon'/>
      </a>}
    </MenuContainer>
  );
};

export default Menu;

import Course from '../models/course';
import Lesson from '../models/lesson';

const checkIfContentsIsLocked = (course: Course) => {

  const allLessons = course.modules
    .map(module => module.lessons || [])
    .flat();

  for (let lesson of allLessons) {
    lesson.isLocked = true;
  }

  if (course.modules && course.modules.length && course.alreadyStarted) {
    for (let lesson of allLessons) {
      if (lesson.alreadyFinished) {
        lesson.isLocked = false;
      }
      else { 
        lesson.isLocked = false;
        break;
      }
    }
  }
}

export default checkIfContentsIsLocked;

import styled from "styled-components";

const CourseSkeletonContainer = styled.div`
  .hd-skeleton,
  .semi-hd-skeleton,
  .medium-skeleton,
  .tablet-skeleton,
  .mobile-skeleton {
    display: none;
  }

  @media (min-width: 1300px) {
    .hd-skeleton {
      display: block;
    }
  }

  @media (min-width: 1200px) and (max-width: 1300px) {
    .semi-hd-skeleton {
      display: block;
    }
  }

  @media (min-width: 800px) and (max-width: 1200px) {
    .medium-skeleton {
      display: block;
    }
  }

  @media (min-width: 550px) and (max-width: 800px) {
    .tablet-skeleton {
      display: block;
    }
  }

  @media (min-width: 350px) and (max-width: 550px) {
    .mobile-skeleton {
      display: block;
    }
  }
`;

export { CourseSkeletonContainer };

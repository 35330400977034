import styled from 'styled-components';

const OngoingCoursesContainer = styled.div``;

const OngoingCoursesSubTitle = styled.h2`
  font-size: 24px;
  color: var(--default-font-color);
  margin: 25px 0;

  @media (max-width: 640px) {
    font-size: 16px;
  }
`;

const OngoingCardsContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 930px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ViewMoreButtonContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  text-align: center;
`;

const ViewMoreButton = styled.button`
  width: 170px;
  padding: 10px 15px;
  color: #fff;
  background-color: var(--default-purple);
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;

  &.mobile {
    background: none;
    color: var(--default-purple);
    font-weight: bold;
  }
`;

export {
  OngoingCoursesContainer,
  OngoingCoursesSubTitle,
  OngoingCardsContainer,
  ViewMoreButtonContainer,
  ViewMoreButton,
};

import axios from 'axios';

const login = async (token: string) => {
  const loginResponse = (
    await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/sessions`
      , {
        params: {
          token: token
        }
      }
    )).data;

  return loginResponse;
}

export {
  login
}
import styled from 'styled-components';

export const AvailableCoursesContainer = styled.div`
  background: white;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 5px 10px;
  border-radius: 5px;

  .available-courses-title {
    color: var(--default-font-color);
    font-size: var(--font-largest);
    margin: 16px 0;
  }

  .available-courses-cards {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 930px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .courses-not-found {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
    }
  }
`;

export const AvailableCoursesFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 38px 0;

  h4 {
    color: var(--default-purple);
    cursor: pointer;
    font-size: var(--font-large);

    &:hover {
      color: var(--default-dark-gray);
    }
  }
`;

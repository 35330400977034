import styled from 'styled-components';

const AnnotationCardContainer = styled.form`
  width: 96%;
  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: solid 1px #d3d8dd;
  }

  &:not(:first-child) {
    padding-top: 10px;
  }
`;

const AnnotationCardUpperContent = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnnotationCardTitle = styled.h2`
  margin: 3px 0;
  font-size: var(--font-large);
  flex-grow: 1;

  input[type='text'] {
    padding: 5px;
    border: solid 1px #d7dce1;
    border-radius: 5px;
    width: 100%;
    height: 35px;
    outline: none;
  }
`;

const AnnotationCardButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AnnotationCardEditButton = styled.button`
  margin-right: 5px;
  padding: 5px;
  background-color: #f0f3f6;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnnotationCardRemoveButton = styled.button`
  padding: 5px;
  background-color: #f0f3f6;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
`;

const AnnotationCardCreationDate = styled.div`
  margin-top: 5px;
  color: #505f79;
  font-size: var(--font-small);
`;

const AnnotationCardDescription = styled.div`
  margin-top: 10px;
  color: #505f79;

  textarea {
    padding: 5px;
    border: solid 1px #d7dce1;
    border-radius: 5px;
    width: 100%;
    outline: none;
    resize: vertical;
  }
`;

const AnnotationCardActionButtons = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const AnnotationCardCancelButton = styled.button`
  margin-right: 5px;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--default-font-color);

  span {
    margin-left: 5px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnnotationCardSaveButton = styled.button`
  margin-right: 5px;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  background-color: var(--default-purple);

  span {
    margin-left: 5px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  AnnotationCardContainer,
  AnnotationCardButtons,
  AnnotationCardEditButton,
  AnnotationCardCreationDate,
  AnnotationCardRemoveButton,
  AnnotationCardTitle,
  AnnotationCardUpperContent,
  AnnotationCardDescription,
  AnnotationCardActionButtons,
  AnnotationCardCancelButton,
  AnnotationCardSaveButton,
};

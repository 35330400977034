import clampJs from 'clamp-js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Course from '../../models/course';
import MyCoursesContext from '../../pages/MyCourses/context';
import { addBookMark, removeBookMark } from '../../services/bookmark';
import ProgressBar from '../ProgressBar';
import {
  CourseCardWithoutImageBookmarkButton,
  CourseCardWithoutImageCategory,
  CourseCardWithoutImageContainer,
  CourseCardWithoutImageRemainingTime,
  CourseCardWithoutImageTitle,
  CourseCardWithoutImageUpperContentContainer,
} from './style';

interface CourseCardWithoutImageProps {
  course: Course;
}

const CourseCardWithoutImage: React.FC<CourseCardWithoutImageProps> = ({
  course,
}) => {
  const { refreshFavoriteCourses, refreshOngoingCourses } =
    useContext(MyCoursesContext);
  const courseTitleRef = useRef<HTMLDivElement>(null);

  const history = useHistory();

  const [addedToList, setAddedToList] = useState(course.addedToList);

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (course) {
      try {
        const shouldAddToList = !course.addedToList;

        if (shouldAddToList) {
          await addBookMark(course.id);
        } else {
          await removeBookMark(course.id);
        }

        course.addedToList = shouldAddToList;
        setAddedToList(course.addedToList);

        if (refreshOngoingCourses) {
          refreshOngoingCourses();
        }

        if (refreshFavoriteCourses) {
          refreshFavoriteCourses();
        }
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  const goToCourse = () => {
    history.push(`/course/${course.id}`);
  };

  const convertToTimeText = (remainingTime: number) => {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);

    return `${hours > 0 ? `${hours}h` : ''} ${minutes}min`;
  };

  useEffect(() => {
    if (courseTitleRef.current) {
      clampJs(courseTitleRef.current, { clamp: 2 });
    }
  }, [courseTitleRef]);

  return (
    <CourseCardWithoutImageContainer
      className={`${course.addedToList ? 'added-to-list' : ''}`}
      onClick={goToCourse}
    >
      <CourseCardWithoutImageUpperContentContainer>
        <CourseCardWithoutImageCategory
          className={`${addedToList ? 'added-to-list' : ''}`}
        >
          {course.categoryName}
        </CourseCardWithoutImageCategory>

        <CourseCardWithoutImageBookmarkButton
          className={`${course.addedToList ? 'added-to-list' : ''}`}
          onClick={e => addToList(e)}
        >
          <BsBookmark
            size={20}
            color={`${course.addedToList ? '#FFF' : '#6B778C'}`}
          />
        </CourseCardWithoutImageBookmarkButton>
      </CourseCardWithoutImageUpperContentContainer>

      <CourseCardWithoutImageTitle ref={courseTitleRef} title={course.title}>
        {course.title}
      </CourseCardWithoutImageTitle>

      <CourseCardWithoutImageRemainingTime>
        <AiOutlineClockCircle color={'var(--default-font-color)'} size={14} />
        <span>
          Tempo restante: ~ {convertToTimeText(course.timeRemaining || 0)}
        </span>
      </CourseCardWithoutImageRemainingTime>

      <ProgressBar
        darkenProgress
        completionPercentage={Math.floor((course.progress || 0) * 100)}
      />
    </CourseCardWithoutImageContainer>
  );
};

export default CourseCardWithoutImage;

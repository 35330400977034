import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import usePostHog from '../hooks/usePosthog';

type Props = {
  children: React.ReactNode;
};
const PosthogContext: React.FC<Props> = props => {
  let posthog = usePostHog();
  let location = useLocation();

  useEffect(() => {
    posthog?.capture('$pageview');
  }, [location, posthog]);

  return <>{props.children}</>;
};

export default PosthogContext;

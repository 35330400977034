import React, { useContext } from "react";
import usePostHog from "../../../../../../../hooks/usePosthog";
import ExtraMaterial from "../../../../../../../models/extra-material";
import CourseContext from '../../../../../../Course/Context';
import { ExtraMaterialCardContainer } from "./style";
import getIconByFileType  from '../../../../../../../helpers/iconExtension';

interface ExtraMaterialCardProps {
    extraMaterial: ExtraMaterial;
}

export const ExtraMaterialCard: React.FC<ExtraMaterialCardProps> = ({
    extraMaterial,
  }) => {
    const { course } = useContext(CourseContext);
    const posthog = usePostHog();
  
    const download = () => {
      posthog?.capture(
        `Capacitação - Material de apoio`,
        {
          course: course.title,
          material: extraMaterial.name,
          materialId: extraMaterial.id,
        },
      );
      window.open(extraMaterial.reference);
    };

    return (
      <ExtraMaterialCardContainer onClick={download}>
        <span className="name">{extraMaterial.info.title || extraMaterial.name}</span>
        <span>{getIconByFileType(extraMaterial.name)}</span>
      </ExtraMaterialCardContainer>
    );
  };
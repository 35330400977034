import React from 'react';
import Course from '../../../models/course';
import Lesson from '../../../models/lesson';
import LessonCertificate from '../../../models/lesson-certificate';
import LessonExam from '../../../models/lesson-exam';
import LessonType from '../../../models/lesson-type';
import Module from '../../../models/module';

interface CourseContextProps {
  course: Course;
  setCourse: (course: Course) => void;
  selectedLessonModule: Module;
  selectedLesson: Lesson | LessonExam | LessonCertificate;
  startCourse: () => Promise<void>;
  startLesson: (isPreview?: boolean) => Promise<void>;
  finishLesson: (
    justModuleAndLesson?: boolean,
    isPreview?: boolean,
  ) => Promise<void>;
  goToNextLesson: () => void;
  enableNextLesson: () => void;
  userIsApproved: boolean | undefined;
  pushLessonUrl: (
    type: LessonType,
    courseId: string,
    moduleId?: string,
    lessonId?: string,
    examId?: string,
  ) => void;
  updateContentProgress: (time: number | null, isPreview?: boolean) => void;
}

const CourseContext = React.createContext<CourseContextProps>({
  course: {} as Course,
  setCourse: () => {},
  selectedLessonModule: {} as Module,
  selectedLesson: {} as Lesson | LessonExam | LessonCertificate,
  startCourse: async () => {},
  startLesson: async () => {},
  finishLesson: async () => {},
  goToNextLesson: () => {},
  enableNextLesson: () => {},
  userIsApproved: undefined,
  pushLessonUrl: (
    type: LessonType,
    courseId: string,
    moduleId?: string,
    lessonId?: string,
    examId?: string,
  ) => {},
  updateContentProgress: (time: number | null, isPreview?: boolean) => {},
});

export default CourseContext;

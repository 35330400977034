import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getLibraryContent as getLibraryContentService } from '../../../../services/content';
import ContentCardSkeleton from '../../../../components/ContentCardSkeleton';
import ContentCard from '../../../../components/ContentCard';
import {
  VideoLibraryContainer,

  VideoCard,
  SeeAllButton,
} from './style';
import LessonFromApi from '../../../../models/from-api/lesson';
import GlobalContext from '../../../../GlobalContext';
import Slider from 'react-slick';
import { VideoLibrarySliderSettings } from '../../../../config/slider';
import { CourseTabsHeaders, CourseTabsContent, CourseTabHeaderGroupContainer } from '../../../Course/Components/CourseContent/styles';

const VideoLibrary: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>();
  const [contents, setContents] = useState<Array<LessonFromApi>>([]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState<boolean>(true);
  const [shouldShowViewMoreButton, setShouldShowViewMoreButton] =
    useState(false);
  const history = useHistory();
  const { isMobile } = useContext(GlobalContext);

  interface CourseTabGroupHeader {
    id: number;
    name: string;
  }

  const courseTabGroupHeaders = [
    { id: undefined, name: 'Todos', shouldShow: true },
    {
      id: 1,
      name: 'Lives',
    },
    {
      id: 2,
      name: 'Treinamentos',
    },
    {
      id: 3,
      name: 'Pílulas de conhecimento',
    },
  ] as CourseTabGroupHeader[];

  interface CourseTabHeaderProps {
    header: CourseTabGroupHeader;
    selected: boolean;
  }

  const changeTab = (headerId: number) => {
    setSelectedTab(headerId);
  };

  const CourseTabHeader: React.FC<CourseTabHeaderProps> = ({
    header,
    selected,
  }) => {
    return (
      <CourseTabHeaderGroupContainer
        className={selected ? 'selected' : ''}
        onClick={() => changeTab(header.id)}
      >
        {header.name}
      </CourseTabHeaderGroupContainer>
    );
  };

  const handleCoursesRender = useCallback(() => {
    const insideRender =
      contents && contents.length ? (
        contents.slice(0, 6).map(content => (
          <ContentCard
            key={content.content_id}
            content={content}
          />
        ))
      ) : shouldShowSkeleton ? (
        Array(3)
          .fill('')
          .map((_, index) => (
            <ContentCardSkeleton key={index} width={240} height={230} />
          ))
      ) : (
        <h3>Não foram encontrados vídeos disponíveis</h3>
      );

    if (isMobile && contents.length > 1) {
      return <Slider {...VideoLibrarySliderSettings}>{insideRender}</Slider>;
    }

    return <VideoCard>{insideRender}</VideoCard>;
  }, [isMobile, contents, shouldShowSkeleton]);

  useEffect(() => {
    (async () => {
      const groupId = selectedTab;
      let localContents = await getLibraryContentService({
        limit: 4,
        is_active: true,
        group: groupId
      });
      setContents(localContents);

      setShouldShowSkeleton(false);
    })();
  }, [selectedTab]);

  useEffect(() => {
    (async () => {
      setShouldShowViewMoreButton(
        !!(
          await getLibraryContentService({
            limit: 1,
            offset: 5,
            is_active: true,
          })
        ).length,
      );
    })();
  }, []);

  return (
    <VideoLibraryContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2 className="video-library-title">Assista agora</h2>
        {isMobile && shouldShowViewMoreButton && (
          <button className="show-all mobile" onClick={() => history.push(`/video-library`)}>
            Ver todos
          </button>
        )}
      </div>

        <CourseTabsHeaders>
          {courseTabGroupHeaders && courseTabGroupHeaders.length ? (
            courseTabGroupHeaders.map(header => (
              <CourseTabHeader
                key={header.id}
                header={header}
                selected={selectedTab === header.id}
              />
            ))
          ) : (
            <></>
          )}
        </CourseTabsHeaders>
      <CourseTabsContent>
        
        {handleCoursesRender()}
      </CourseTabsContent>

      {!isMobile && shouldShowViewMoreButton ? (
        <SeeAllButton>
          <button onClick={() => history.push(`/video-library`)}>
            Ver todos
          </button>
        </SeeAllButton>
      ) : null}
    </VideoLibraryContainer>
  );
};

export default VideoLibrary;

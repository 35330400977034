import styled from 'styled-components';

const CourseCertificateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px -4px;
  padding: 15px;

  h3 {
    font-size: 1.5em;
  }

  div.cert-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding: 30px 50px;

    h4 {
      font-size: 2em;
    }

    p {
      font-size: 1.2em;
    }

    button {
      padding: 10px 15px;
      border-radius: 5px;
      background: var(--default-purple);
      color: white;
      display: flex;
      align-items: center;
      gap: 10px;
      border: none;
      outline: none;
      cursor: pointer;
    }

    img { 
      @media screen and (max-width: 425px) {
         width: 100%; 
      }
    }
  }
`;

const BlockedCertContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #ab7a00;
  background: #fff3d2;
  color: #ab7a00;
  gap: 10px;
  min-width: 360px;
`;

export { CourseCertificateContainer, BlockedCertContainer };

import httpClient from '../../http-client';

const getTrailsAndLessonsWithAnnotations = async () => {
  const trailsAndLessonsWithAnnotations = (
    await httpClient.get(`/annotations`)
  ).data;

  return trailsAndLessonsWithAnnotations;
};

const getAllAnnotations = async (courseId: string, lessonId: string) => {
  if (!courseId) {
    throw new Error('id do curso não informado.');
  }

  if (!lessonId) {
    throw new Error('id da aula não informado.');
  }

  const annotations = (
    await httpClient.get(`/annotations/${courseId}/${lessonId}`)
  ).data;

  return annotations;
};

const createAnnotation = async (
  newAnnotation: any,
  courseId: string,
  lessonId: string,
) => {
  if (!newAnnotation) {
    throw new Error('Os dados para criação da anotação são obrigatórios');
  }

  const response = await httpClient({
    method: 'post',
    url: '/annotations',
    data: {
      trail_id: courseId,
      content_id: lessonId,
      title: newAnnotation.title,
      description: newAnnotation.description,
    },
  });

  return response;
};

const deleteAnnotation = async (annotationId: string) => {
  if (!annotationId) {
    throw new Error('ID obrigatório para exclusão!');
  }

  const response = await httpClient({
    method: 'delete',
    url: `/annotations/${annotationId}`,
  });

  return response;
};

const updateAnnotation = async (
  annotationId: string,
  newAnnotation: any,
) => {
  if (!annotationId) {
    throw new Error('ID obrigatório para edição de anotação!');
  }

  if (!newAnnotation) {
    throw new Error('Dados para edição não encontrados!');
  }

  const response = await httpClient({
    method: 'put',
    url: `/annotations/${annotationId}`,
    data: {
      title: newAnnotation.title,
      description: newAnnotation.description,
    },
  });

  return response;
};

export {
  getAllAnnotations,
  createAnnotation,
  deleteAnnotation,
  updateAnnotation,
  getTrailsAndLessonsWithAnnotations,
};

import React, { useMemo, useRef } from 'react';
import { useContext } from 'react';
import GlobalContext from '../../GlobalContext';
import {
  HeaderMobileContainer,
  SidebarContainer,
  SidebarItems,
  HeaderContainer,
  OpenCloseSidebarButton,
  HeaderLogo,
  HeaderDivider,
  OptionsButton,
  OptionsContextMenu,
  OptionsContextMenuItemLabel,
  OptionsContextMenuItem,
  SidebarItem,
  SectionHeaderContainer,
  GoBackButton,
  SearchContainer,
  UserGreetings,
  ProgressBarContainer,
  ShowModulesSidebarButton,
  OptionsContextMenuWrapper,
  SidebarItemExternal,
} from './style';
import { GiHamburgerMenu } from 'react-icons/gi';
import {
  BsArrowLeftShort,
  BsPencil,
  BsThreeDotsVertical,
} from 'react-icons/bs';
import { useState } from 'react';
import {
  AiOutlineHome,
  AiOutlineLogout,
} from 'react-icons/ai';
import { BiBookOpen } from 'react-icons/bi';
import { FaExternalLinkAlt, FaGraduationCap } from 'react-icons/fa';
import { IoMdRibbon } from 'react-icons/io';
import { ImBooks, ImStatsBars } from 'react-icons/im';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CourseProgressContext from '../../context/CourseProgressContext';
import SearchInput from '../Header/components/SearchInput';
import SearchInputLibrary from '../Header/components/SearchInputLibrary';
import ProgressBar from '../ProgressBar';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { isProduction } from '../../helpers/environment';

interface Params {
  courseId: string;
}

const HeaderMobile: React.FC = () => {
  const reportLink: string | undefined = process.env.REACT_APP_REPORT_URL;
  const { courseId } = useParams<Params>();
  const { showSidebar, setShowSidebar } = useContext(GlobalContext);
  const [showOptionsContextMenu, setShowOptionsContextMenu] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { courseProgress, courseHasPreview } = useContext(
    CourseProgressContext,
  );

  const sectionHeaderContainerRef = useRef<HTMLDivElement>(null);
  const headerContainerRef = useRef<HTMLDivElement>(null);

  const userName = localStorage.getItem('ead/user/name');

  const logout = () => {
    Object.keys(localStorage).filter(key => key.toLowerCase().startsWith("ead/")).forEach(key => localStorage.removeItem(key));

    window.location.assign(`${process.env.REACT_APP_LOGIN_FARMARCAS}/logout`);
  };

  const goTo = (path: string) => {
    history.push(path);
  };

  const menus = [
    {
      path: '/home',
      label: (
        <>
          <AiOutlineHome size={24} />
          <span>Home</span>
        </>
      ),
    },
    {
      path: '/courses',
      label: (
        <>
          <BiBookOpen size={24} />
          <span>Cursos</span>
        </>
      ),
    },
    {
      path: '/my-courses',
      label: (
        <>
          <FaGraduationCap size={24} />
          <span>Meus Cursos</span>
        </>
      ),
    },
    {
      path: '/certificates',
      label: (
        <>
          <IoMdRibbon size={24} />
          <span>Certificados</span>
        </>
      ),
    },
    {
      path: '/annotations',
      label: (
        <>
          <BsPencil size={24} />
          <span>Anotações</span>
        </>
      ),
    },
    {
      path: '/video-library',
      label: (
        <>
          <ImBooks size={24} />
          <span>Assista agora</span>
        </>
      ),
    },
    {
      path: reportLink ?? '',
      label: (
        <>
          <ImStatsBars size={24} />
          <span>Relatório de Capacitação</span>
          <FaExternalLinkAlt />
        </>
      ),
      externalLink: true,
    },
  ];

  const handleGoBack = () => {
    if (isWatchingAnyLesson && courseHasPreview) {
      const newPath = history.location.pathname.split(
        /\/module\/.[^/]+\/lesson\/.[^/]+/,
      );
      if (newPath && newPath.length) {
        history.push(newPath[0]);
      } else if (courseId) {
        history.push(`/course/${courseId}`);
      }
    } else {
      history.push('/home');
    }
  };

  const showModulesSidebar = () => {
    const modulesSidebar = document.getElementById('modules-sidebar');
    if (modulesSidebar) {
      modulesSidebar.classList.replace('leave', 'enter');
    }
  };

  const toggleOptionContextMenu = () => {
    setShowOptionsContextMenu(!showOptionsContextMenu);
  };

  const isWatchingAnyLesson = useMemo(() => {
    return (
      location.pathname.match(
        /course\/.[^/]+\/module\/.[^/]+\/lesson\/.[^/]+/,
      ) || !!new URLSearchParams(location.search).get('isCertificate')
    );
  }, [location]);

  const isOnCoursePage = useMemo(() => {
    return location.pathname.match(/course\/.[^/]+/);
  }, [location]);

  useEffect(() => {
    if (showSidebar) {
      setShowSidebar(false);
    }
  }, [location]);

  useEffect(() => {
    if (sectionHeaderContainerRef.current && headerContainerRef.current) {
      sectionHeaderContainerRef.current.style.display = 'flex';
      headerContainerRef.current.style.filter = 'unset';
    }
  }, [location]);

  return (
    <HeaderMobileContainer
      className={!isWatchingAnyLesson ? 'with-header' : ''}
    >
      {!isWatchingAnyLesson && (
        <HeaderContainer ref={headerContainerRef}>
          <OpenCloseSidebarButton
            type="button"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <GiHamburgerMenu size={18} />
          </OpenCloseSidebarButton>
          <HeaderLogo src={`${process.env.PUBLIC_URL}/logo-black.svg`} />
          <HeaderDivider />
          <OptionsButton type="button" onClick={toggleOptionContextMenu}>
            <BsThreeDotsVertical size={18} />
          </OptionsButton>
        </HeaderContainer>
      )}

      <SectionHeaderContainer
        ref={sectionHeaderContainerRef}
        className="section"
      >
        {!location.pathname || location.pathname === '/home' ? (
          <UserGreetings>Olá, {userName}!</UserGreetings>
        ) : (
          <GoBackButton
            onClick={() => {
              handleGoBack();
            }}
            className="go-back-button"
          >
            <BsArrowLeftShort size={24} color="#FFF" />
            {isWatchingAnyLesson ? 'Sair' : 'Voltar'}
          </GoBackButton>
        )}

        <SearchContainer>
          {(
            location.pathname === '/courses' ||
            location.pathname === '/my-courses') && <SearchInput />}

          {
            (!location.pathname || location.pathname === '/home' ||
              location.pathname === '/video-library') && <SearchInputLibrary />}
        </SearchContainer>

        {isWatchingAnyLesson && (
          <ProgressBarContainer>
            <ProgressBar completionPercentage={courseProgress} />
          </ProgressBarContainer>
        )}

        {isOnCoursePage && (
          <ShowModulesSidebarButton onClick={showModulesSidebar}>
            <GiHamburgerMenu size={18} />
          </ShowModulesSidebarButton>
        )}
      </SectionHeaderContainer>

      {showOptionsContextMenu && (
        <OptionsContextMenuWrapper>
          <div className="backdrop" onClick={toggleOptionContextMenu}></div>
          <OptionsContextMenu>
            {/* <OptionsContextMenuItemLabel>Navegação</OptionsContextMenuItemLabel>
            <OptionsContextMenuItem
              onClick={() => {
                goTo('/home');
                toggleOptionContextMenu();
              }}
            >
              <AiOutlineHome size={24} />
              <span>Home</span>
            </OptionsContextMenuItem>
            <OptionsContextMenuItem
              onClick={() => {
                goTo('#');
                toggleOptionContextMenu();
              }}
            >
              <AiOutlineAppstore size={24} />
              <span>Aplicativos</span>
            </OptionsContextMenuItem>
            <OptionsContextMenuItem
              onClick={() => {
                goTo('#');
                toggleOptionContextMenu();
              }}
            >
              <FaRegUserCircle size={24} />
              <span>Meu Perfil</span>
            </OptionsContextMenuItem>
            <OptionsContextMenuItemDivisor /> */}
            <OptionsContextMenuItemLabel>Sistema</OptionsContextMenuItemLabel>

            <OptionsContextMenuItem onClick={logout}>
              <AiOutlineLogout size={24} />
              <span>Sair</span>
            </OptionsContextMenuItem>
          </OptionsContextMenu>
        </OptionsContextMenuWrapper>
      )}
      <SidebarContainer
        className={'sidebar ' + (showSidebar ? 'enter' : 'leave')}
      >
        <div className="backdrop" onClick={() => setShowSidebar(false)}></div>
        <SidebarItems>
          {menus.map(menu => {
            if (menu.path === reportLink && isProduction() && dayjs().isBefore(dayjs('2023-10-30'))) {
              return null;
            }
            if (menu.externalLink) {
              return (
                <SidebarItemExternal
                  key={menu.path}
                  href={menu.path}
                  target='_blank'
                >
                  {menu.label}
                </SidebarItemExternal>
              );
            }
            return (
              <SidebarItem
                key={menu.path}
                onClick={() => {
                  goTo(menu.path);
                }}
                className={menu.path === location.pathname ? 'active' : ''}
              >
                {menu.label}
              </SidebarItem>
            );
          })}
        </SidebarItems>
      </SidebarContainer>
    </HeaderMobileContainer>
  );
};

export default HeaderMobile;

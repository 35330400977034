import React, { useContext, useMemo } from 'react';
import { AiOutlineCheck, AiOutlineLock } from 'react-icons/ai';
import Lesson from '../../../../../../models/lesson';
import LessonCertificate from '../../../../../../models/lesson-certificate';
import LessonExam from '../../../../../../models/lesson-exam';
import CourseContext from '../../../../Context';
import {
  LessonContent,
  LessonFluxCircle,
  LessonFluxContainer,
  LessonFluxLowerBar,
  LessonFluxUpperBar,
  ModuleLessonContainer,
  ModuleLessonsMobileContainer,
} from './style';
import { RiErrorWarningLine } from 'react-icons/ri';
import Module from '../../../../../../models/module';
import { useParams } from 'react-router-dom';

interface ModuleLessonProps {
  lesson: Lesson | LessonExam | LessonCertificate;
  module: Module;
}

interface ModuleLessonParams {
  lessonId: string;
  moduleId: string;
}

const ModuleLesson: React.FC<ModuleLessonProps> = ({ lesson, module }) => {
  const { moduleId, lessonId } = useParams<ModuleLessonParams>();
  const { pushLessonUrl, course, selectedLesson, userIsApproved } =
    useContext(CourseContext);

  const goToLesson = () => {
    if (!lesson.isLocked) {
      if (lesson.type === 'EXAM') {
        const localLesson = lesson as LessonExam;
        pushLessonUrl(
          localLesson.type,
          localLesson.courseId,
          localLesson.moduleId,
          localLesson.lessonId,
          localLesson.id,
        );
      } else if (lesson.type === 'CERTIFICATE') {
        const localLesson = lesson as LessonCertificate;
        pushLessonUrl(localLesson.type, course.id);
      } else {
        const localLesson = lesson as Lesson;
        pushLessonUrl(
          localLesson.type,
          localLesson.courseId,
          localLesson.moduleId,
          localLesson.id,
        );
      }
    }
  };

  const fluxClassName = useMemo(() => {
    if (lesson.isLocked) {
      return 'locked';
    } else if (
      lesson.type === 'EXAM' &&
      lesson.alreadyFinished &&
      (lesson as LessonExam).numberOfErrors > 0
    ) {
      return 'exam-error';
    } else if (
      lesson.type !== 'CERTIFICATE' ? lesson.alreadyFinished : userIsApproved
    ) {
      return 'finished';
    } else {
      return 'unlocked';
    }
  }, [lesson]);

  const lessonFluxIcon = useMemo(() => {
    if (lesson.isLocked) {
      return <AiOutlineLock size={12} />;
    } else if (
      lesson.type === 'EXAM' &&
      lesson.alreadyFinished &&
      (lesson as LessonExam).numberOfErrors > 0
    ) {
      return <RiErrorWarningLine size={12} />;
    } else if (
      lesson.type !== 'CERTIFICATE' ? lesson.alreadyFinished : userIsApproved
    ) {
      return <AiOutlineCheck size={12} />;
    } else {
      return <></>;
    }
  }, [lesson]);

  const isSelected = useMemo(() => {
    if (selectedLesson.type === 'EXAM' && lesson.type === selectedLesson.type) {
      const localSelectedLesson = selectedLesson as LessonExam;
      const localLesson = lesson as LessonExam;
      return (
        localSelectedLesson.moduleId === localLesson.moduleId &&
        localSelectedLesson.lessonId === localLesson.lessonId &&
        localSelectedLesson.id === localLesson.id
      );
    } else if (
      selectedLesson.type === 'CERTIFICATE' &&
      lesson.type === selectedLesson.type
    ) {
      return true;
    } else if (moduleId && lessonId) {
      const localSelectedLesson = selectedLesson as Lesson;
      const localLesson = lesson as Lesson;
      return (
        localLesson.id === localSelectedLesson.id &&
        localLesson.moduleId === localSelectedLesson.moduleId &&
        localSelectedLesson.courseId === localLesson.courseId
      );
    }
  }, [lesson, selectedLesson]);

  return (
    <ModuleLessonContainer
      className={isSelected ? 'selected' : ''}
      onClick={goToLesson}
    >
      <LessonFluxContainer>
        <LessonFluxUpperBar
          className={`${fluxClassName} ${
            (lesson as Lesson).isFirstOfModule && lesson.type !== 'EXAM'
              ? 'first'
              : ''
          }`}
        />
        <LessonFluxCircle className={fluxClassName}>
          {lessonFluxIcon}
        </LessonFluxCircle>
        <LessonFluxLowerBar
          className={`${fluxClassName} ${
            ((lesson as Lesson | LessonExam).isLastOfModule &&
              !module.isLastOfCourse &&
              (!(lesson as Lesson).exam || !(lesson as Lesson).exam!.id)) ||
            lesson.type === 'CERTIFICATE'
              ? 'last'
              : ''
          }`}
        />
      </LessonFluxContainer>
      <LessonContent>
        <span className="lesson-title">{lesson.title}</span>
        {lesson.type === 'EXAM' && (lesson as LessonExam).numberOfErrors > 0 && (
          <span className="exam-errors">
            {(lesson as LessonExam).numberOfErrors} Erro
            {(lesson as LessonExam).numberOfErrors > 1 ? 's' : ''}
          </span>
        )}
      </LessonContent>
    </ModuleLessonContainer>
  );
};

interface ModuleLessonsProps {
  module: Module;
}

const ModuleLessonsMobile: React.FC<ModuleLessonsProps> = ({ module }) => {
  return (
    <ModuleLessonsMobileContainer>
      {module.lessons && module.lessons.length ? (
        module.lessons.map((les, index) => (
          <ModuleLesson key={index} module={module} lesson={les} />
        ))
      ) : (
        <></>
      )}
    </ModuleLessonsMobileContainer>
  );
};

export default ModuleLessonsMobile;
